import { useSnackbar } from "notistack";
import { useContext, useState } from "react";
import { AppContext } from "../../pages/AppContainer";
import { reqHeaders } from "../../utils";
import { AppButton } from "../AppButton";
import {DayItem} from "../DayItem";
import {DayTimeSelector} from "../DayTimeSelector";

export function UserSchedule() {
  const { currentUser } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [sunShifts, setSunShifts] = useState(
    currentUser?.schedule?.sunShifts ?? []
  );
  const [monShifts, setMonShifts] = useState(
    currentUser?.schedule?.monShifts ?? []
  );
  const [tueShifts, setTueShifts] = useState(
    currentUser?.schedule?.tueShifts ?? []
  );
  const [wedShifts, setWedShifts] = useState(
    currentUser?.schedule?.wedShifts ?? []
  );
  const [thuShifts, setThuShifts] = useState(
    currentUser?.schedule?.thuShifts ?? []
  );
  const [friShifts, setFriShifts] = useState(
    currentUser?.schedule?.friShifts ?? []
  );
  const [satShifts, setSatShifts] = useState(
    currentUser?.schedule?.satShifts ?? []
  );

  const [sunSelected, setSunSelected] = useState(sunShifts.length > 0);
  const [monSelected, setMonSelected] = useState(monShifts.length > 0);
  const [tueSelected, setTueSelected] = useState(tueShifts.length > 0);
  const [wedSelected, setWedSelected] = useState(wedShifts.length > 0);
  const [thuSelected, setThuSelected] = useState(thuShifts.length > 0);
  const [friSelected, setFriSelected] = useState(friShifts.length > 0);
  const [satSelected, setSatSelected] = useState(satShifts.length > 0);

  async function updateSchedule() {
    try {
      setLoading(true);
      const scheduleReq = await fetch(
        `${process.env.REACT_APP_API_URL}/users/instructors/schedule`,
        {
          method: "PUT",
          headers: reqHeaders,
          body: JSON.stringify({
            userId: currentUser._id,
            schedule: {
              sunShifts: sunShifts,
              monShifts: monShifts,
              tueShifts: tueShifts,
              wedShifts: wedShifts,
              thuShifts: thuShifts,
              friShifts: friShifts,
              satShifts: satShifts,
            },
          }),
        }
      );
      if (scheduleReq.status === 200) {
        displayMessage("Your schedule has been updated", "success");
        setLoading(false);
      } else {
        displayMessage("Something went wrong. Please try again.", "error");
        setLoading(false);
      }
    } catch (err) {
      console.debug(err);
      displayMessage("Something went wrong. Please try again.", "error");
      setLoading(false);
    }
  }

  const displayMessage = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
    });
  };

  return (
    <div
      style={{
        background: "white",
        padding: "40px",
        boxShadow: "0px 4px 15px #00000014",
        borderRadius: "23px",
        margin: "10px 0",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            width: "40%",
          }}
        >
          <p
            style={{
              margin: "0",
              padding: "0",
              fontSize: "18px",
              fontWeight: "600",
              marginBottom: "15px",
            }}
          >
            Your Schedule
          </p>
          <p
            style={{
              margin: "0",
              padding: "0 20px 0 0",
              fontSize: "14px",
              color: "#7E84A3",
            }}
          >
            To edit your schedule, click a day, change any of the information in
            the fields and click “save”.
          </p>
        </div>
        <div className="col" style={{ width: "60%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <DayItem
              day={0}
              isSelected={sunSelected}
              onDayPressed={() => {
                if (!sunSelected) {
                  setSunShifts([{ startMinute: 540, endMinute: 1020 }]);
                } else {
                  setSunShifts([]);
                }
                setSunSelected(() => !sunSelected);
              }}
            />
            <DayItem
              day={1}
              isSelected={monSelected}
              onDayPressed={() => {
                if (!monSelected) {
                  setMonShifts([{ startMinute: 540, endMinute: 1020 }]);
                } else {
                  setMonShifts([]);
                }
                setMonSelected(() => !monSelected);
              }}
            />
            <DayItem
              day={2}
              isSelected={tueSelected}
              onDayPressed={() => {
                if (!tueSelected) {
                  setTueShifts([{ startMinute: 540, endMinute: 1020 }]);
                } else {
                  setTueShifts([]);
                }
                setTueSelected(() => !tueSelected);
              }}
            />
            <DayItem
              day={3}
              isSelected={wedSelected}
              onDayPressed={() => {
                if (!wedSelected) {
                  setWedShifts([{ startMinute: 540, endMinute: 1020 }]);
                } else {
                  setWedShifts([]);
                }
                setWedSelected(() => !wedSelected);
              }}
            />
            <DayItem
              day={4}
              isSelected={thuSelected}
              onDayPressed={() => {
                if (!thuSelected) {
                  setThuShifts([{ startMinute: 540, endMinute: 1020 }]);
                } else {
                  setThuShifts([]);
                }
                setThuSelected(() => !thuSelected);
              }}
            />
            <DayItem
              day={5}
              isSelected={friSelected}
              onDayPressed={() => {
                if (!friSelected) {
                  setFriShifts([{ startMinute: 540, endMinute: 1020 }]);
                } else {
                  setFriShifts([]);
                }
                setFriSelected(() => !friSelected);
              }}
            />
            <DayItem
              day={6}
              isSelected={satSelected}
              onDayPressed={() => {
                if (!satSelected) {
                  setSatShifts([{ startMinute: 540, endMinute: 1020 }]);
                } else {
                  setSatShifts([]);
                }
                setSatSelected(() => !satSelected);
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "10px",
            }}
          >
            {sunSelected && (
              <DayTimeSelector
                day={0}
                shifts={sunShifts}
                onShiftsUpdated={(newShifts) => {
                  setSunShifts(() => [...newShifts]);
                }}
                onCloseDay={() => {
                  setSunSelected(false);
                }}
              />
            )}
            {monSelected && (
              <DayTimeSelector
                day={1}
                shifts={monShifts}
                onShiftsUpdated={(newShifts) => {
                  setMonShifts(() => [...newShifts]);
                }}
                onCloseDay={() => {
                  setMonSelected(false);
                }}
              />
            )}
            {tueSelected && (
              <DayTimeSelector
                day={2}
                shifts={tueShifts}
                onShiftsUpdated={(newShifts) => {
                  setTueShifts(() => [...newShifts]);
                }}
                onCloseDay={() => {
                  setTueSelected(false);
                }}
              />
            )}
            {wedSelected && (
              <DayTimeSelector
                day={3}
                shifts={wedShifts}
                onShiftsUpdated={(newShifts) => {
                  setWedShifts(() => [...newShifts]);
                }}
                onCloseDay={() => {
                  setWedSelected(false);
                }}
              />
            )}
            {thuSelected && (
              <DayTimeSelector
                day={4}
                shifts={thuShifts}
                onShiftsUpdated={(newShifts) => {
                  setThuShifts(() => [...newShifts]);
                }}
                onCloseDay={() => {
                  setThuSelected(false);
                }}
              />
            )}
            {friSelected && (
              <DayTimeSelector
                day={5}
                shifts={friShifts}
                onShiftsUpdated={(newShifts) => {
                  setFriShifts(() => [...newShifts]);
                }}
                onCloseDay={() => {
                  setFriSelected(false);
                }}
              />
            )}
            {satSelected && (
              <DayTimeSelector
                day={6}
                shifts={satShifts}
                onShiftsUpdated={(newShifts) => {
                  setSatShifts(() => [...newShifts]);
                }}
                onCloseDay={() => {
                  setSatSelected(false);
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          textAlign: "end",
          marginTop: "20px",
        }}
      >
        <AppButton
          onClick={updateSchedule}
          title="Save"
          loading={loading}
          fontSize={"14px"}
          width="120px"
          margin="10px 0 10px 0"
          padding="7px"
        />
      </div>
    </div>
  );
}
