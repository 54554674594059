import {useSnackbar} from "notistack";
import {getFullDayString, getTimeStringFromSeconds, timeArray} from "../utils";
import {Dropdown} from "semantic-ui-react";
import {ImCross} from "react-icons/im";
import {AiOutlinePlusCircle} from "react-icons/ai";

export function DayTimeSelector({day, shifts, onShiftsUpdated, onCloseDay}) {
  const { enqueueSnackbar } = useSnackbar();

  const displayMessage = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
    });
  };

  function updateStartTime(newVal, i) {
    if (newVal >= shifts[i].endMinute) {
      displayMessage("The start time must be before the end time", "error");
      return;
    }
    if (i !== 0 && shifts.length > 1) {
      if (newVal <= shifts[i - 1].endMinute) {
        displayMessage(
          "This time can't be before the previous shift's end time",
          "error"
        );
        return;
      }
    }
    shifts[i].startMinute = newVal;
    onShiftsUpdated(shifts);
  }

  function updateEndTime(newVal, i) {
    if (newVal <= shifts[i].startMinute) {
      displayMessage("The end time must be after the start time", "error");
      return;
    }
    if (i !== shifts.length - 1 && shifts.length > 1) {
      if (newVal >= shifts[i + 1].startMinute) {
        displayMessage(
          "This time can't be after the next shift's start time",
          "error"
        );
        return;
      }
    }
    shifts[i].endMinute = newVal;
    onShiftsUpdated(shifts);
  }

  function addShift() {
    const latestEndingTime = shifts[shifts.length - 1].endMinute;
    if (latestEndingTime >= timeArray[timeArray.length - 2]) {
      displayMessage(
        "Can't add another shift for this day. Check the end time for the latest shift.",
        "error"
      );
      return;
    }

    const startingTime = timeArray[timeArray.indexOf(latestEndingTime) + 1];
    let newShift = {
      dayOfWeek: day,
      startMinute: startingTime,
      endMinute: timeArray[timeArray.length - 1],
    }
    onShiftsUpdated([...shifts, newShift]);
  }

  function removeTime(i) {
    if (i > -1) {
      shifts.splice(i, 1);
      onShiftsUpdated(shifts);
    }
  }

  return (
    <div>
      {shifts &&
        shifts.map((shift, i) => (
          <div
            key={`${shift.startMinute}${i}`}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "70%",
              marginBottom: "5px",
            }}
          >
            {
              <p
                style={{
                  padding: "0",
                  margin: "0 10px 0 0",
                  color: i !== 0 ? "transparent" : "black",
                  width: "160px",
                }}
              >
                {getFullDayString(day)}
              </p>
            }
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  border: "2px solid #D7DBEC",
                  borderRadius: "25px",
                  width: "fit-content",
                  padding: "0 5px",
                  fontSize: "10px",
                }}
              >
                <div
                  style={{
                    padding: "0px 5px",
                    fontSize: "10px",
                    fontFamily: "poppins, sans-serif",
                    width: "50px",
                    color: "#6F6F6F",
                  }}
                >
                  <Dropdown
                    text={getTimeStringFromSeconds(shift.startMinute ?? 360)}
                    scrolling
                    className="state-dropdown"
                  >
                    <Dropdown.Menu>
                      {timeArray &&
                        timeArray.map((val) => (
                          <Dropdown.Item
                            key={val}
                            text={getTimeStringFromSeconds(val)}
                            value={val}
                            onClick={() => {
                              updateStartTime(val, i);
                            }}
                          />
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <p style={{ padding: "0", margin: "0 10px", color: "#6F6F6F" }}>
                  to
                </p>
                <div
                  style={{
                    padding: "5px",
                    fontSize: "10px",
                    fontFamily: "poppins, sans-serif",
                    width: "50px",
                    color: "#6F6F6F",
                  }}
                >
                  <Dropdown
                    text={getTimeStringFromSeconds(shift.endMinute ?? 360)}
                    scrolling
                    className="state-dropdown"
                  >
                    <Dropdown.Menu>
                      {timeArray &&
                        timeArray.map((val) => (
                          <Dropdown.Item
                            key={val}
                            text={getTimeStringFromSeconds(val)}
                            value={val}
                            onClick={() => {
                              updateEndTime(val, i);
                            }}
                          />
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ImCross
                  size={10}
                  style={{
                    color:
                      Object.keys(shift).length > 1 && i !== 0
                        ? "#F0142F"
                        : "transparent",
                    marginLeft: "10px",
                    cursor:
                      Object.keys(shift).length > 1 && i !== 0
                        ? "pointer"
                        : "unset",
                  }}
                  onClick={() => {
                    if (Object.keys(shift).length > 1 && i !== 0) {
                      if (shifts.length === 1) {
                        onCloseDay();
                      }
                      removeTime(i);
                    }
                  }}
                />
                <AiOutlinePlusCircle
                  style={{
                    color: i !== shifts.length - 1 ? "transparent" : "#6F6F6F",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                  onClick={addShift}
                  size={18}
                />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}