import Modal from "react-modal";
import { ImCross } from "react-icons/im";
import {useEffect, useState} from "react";
import { useSnackbar } from "notistack";
import { AppButton } from "../../components/AppButton";
import {reqHeaders} from "../../utils";
import {DeleteUserConfirmModal} from "./DeleteUserConfirmModal";

export function EditUserModal(props) {
  const { user = null, isOpen = false, onRequestClose } = props
  const isNew = !user;

  const [saveLoading, setSaveLoading] = useState(false);
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [role, setRole] = useState(0);
  const [password, setPassword] = useState("");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    setFirstName(user?.firstName || "");
    setLastName(user?.lastName || "");
    setEmail(user?.email || "");
    setPhone(user?.phone || "");
    setRole(user?.role || 0);
    setPassword("");
  }, [user]);

  const { enqueueSnackbar } = useSnackbar();

  const displayMessage = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
    });
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "20px",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      backdropFilter: "blur(2px)",
    },
  };

  const createUser = async () => {
    if (firstName.length === 0) {
      displayMessage("Please enter a first name", "error");
      return;
    }
    if (lastName.length === 0) {
      displayMessage("Please enter a last name", "error");
      return;
    }
    if (email.length === 0) {
      displayMessage("Please enter an email", "error");
      return;
    }
    if (password.length < 6) {
      displayMessage("Please enter a password with at least 6 characters", "error");
      return;
    }

    setSaveLoading(true);

    try {
        const req = await fetch(`${process.env.REACT_APP_API_URL}/users/users`, {
          method: "POST",
          headers: reqHeaders,
          body: JSON.stringify({
            firstName,
            lastName,
            email,
            phone,
            role,
            password
          })
        });
        if (req.status === 200) {
          displayMessage("The user has been created", "success");
          onRequestClose();
        } else {
          displayMessage("Something went wrong. Please try again.", "error");
        }
      } catch (err) {
        console.debug(err);
        displayMessage("Something went wrong. Please try again.", "error");
      }
      setSaveLoading(false);
  }

  const updateUser = async () => {
    if (firstName.length === 0) {
      displayMessage("Please enter a first name", "error");
      return;
    }
    if (lastName.length === 0) {
      displayMessage("Please enter a last name", "error");
      return;
    }
    if (email.length === 0) {
      displayMessage("Please enter an email", "error");
      return;
    }
    if (password.length > 0 && password.length < 6) {
      displayMessage("Please enter a password with at least 6 characters", "error");
      return;
    }

    setSaveLoading(true);
    try {
      const req = await fetch(`${process.env.REACT_APP_API_URL}/users/users/${user._id}`, {
        method: "PUT",
        headers: reqHeaders,
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          phone,
          role,
          password
        })
      });
      if (req.status === 200) {
        displayMessage("The user has been updated", "success");
        onRequestClose();
      } else {
        displayMessage("Something went wrong. Please try again.", "error");
      }
    } catch (err) {
      console.debug(err);
      displayMessage("Something went wrong. Please try again.", "error");
    }
    setSaveLoading(false);
  }

  const deleteUser = async () => {
    setConfirmModalOpen(true);
  }

  const confirmDelete = async () => {
    setSaveLoading(true);
    try {
      const req = await fetch(`${process.env.REACT_APP_API_URL}/users/users/${user._id}`, {
        method: "DELETE",
        headers: reqHeaders
      });
      if (req.status === 200) {
        displayMessage("The user has been deleted", "success");
        onRequestClose();
      } else {
        displayMessage("Something went wrong. Please try again.", "error");
      }
    } catch (err) {
      console.debug(err);
      displayMessage("Something went wrong. Please try again.", "error");
    }
    setSaveLoading(false);
  }

  return (
    <>
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      ariaHideApp={false}
    >
      <div style={{ width: "40vw", minWidth: "400px", padding: "10px" }}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}>
          <div>
            <h1>{isNew ? 'Create' : 'Edit'} User</h1>
          </div>
          <div>
            <button onClick={onRequestClose}>
              <ImCross size={12} style={{ color: "#B2AEAE" }} />
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            padding: "20px 15% 40px 15%",
            gap: "5px"
          }}
        >
          <p className="edit-label">First Name</p>
          <input
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            type="text"
            placeholder="First Name"
            className="login-input"
            id="first-name"
          />
          <p className="edit-label">Last Name</p>
          <input
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            type="text"
            placeholder="Last Name"
            className="login-input"
            id="last-name"
          />
          <p className="edit-label">Email</p>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            placeholder="Email"
            className="login-input"
            id="email"
          />
          <p className="edit-label">Phone</p>
          <input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            type="text"
            placeholder="Phone"
            className="login-input"
            id="phone"
          />
          <p className="edit-label">Role</p>
          <select
            value={role}
            className="login-input"
            id="password"
            onChange={(e) => setRole(e.target.value)}
          >
            <option value={0}>User</option>
            <option value={1}>Instructor</option>
            <option value={2}>Admin</option>
            <option value={3}>Super Admin</option>
          </select>
          <p className="edit-label">{isNew ? '' : 'Update '}Password</p>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Change Password"
            className="login-input"
            id="password"
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <AppButton
            onClick={onRequestClose}
            outline={true}
            title="Cancel"
            fontSize={"14px"}
            width="20%"
            margin="0 10px 0 10px"
          />

          <AppButton
            onClick={isNew ? createUser : updateUser}
            title="Save"
            loading={saveLoading}
            fontSize={"14px"}
            width="20%"
            margin="0 10px 0 10px"
          />

          {!isNew && (
            <AppButton
              onClick={deleteUser}
              title="Delete"
              loading={saveLoading}
              fontSize={"14px"}
              width="20%"
              margin="0 10px 0 10px"
              color="#DD3539"
            />
          )}
        </div>
      </div>
    </Modal>
    <DeleteUserConfirmModal
      isOpen={confirmModalOpen}
      onRequestClose={() => {
        setConfirmModalOpen(false);
      }}
      onConfirmPressed={() => {
        confirmDelete();
        setConfirmModalOpen(false);
      }}
    />
    </>
  );
}
