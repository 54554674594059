import Modal from "react-modal";
import { ImCross } from "react-icons/im";
import { useEffect, useState } from "react";
import { AppButton } from "../AppButton";
import { useSnackbar } from "notistack";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isNil } from "../../utils";
import {TiDelete} from "react-icons/all";

export function EditAnnouncementModal(props) {
  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [date, setDate] = useState(new Date());
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [attachmentsToDelete, setAttachmentsToDelete] = useState([]);
  const [attachments, setAttachments] = useState(props.announcement?.attachments ?? []);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setTitle(props.announcement?.title ?? "");
    setContent(props.announcement?.content ?? "");
    if (props.announcement?.updatedAt) {
      setDate(new Date(props.announcement.updatedAt));
    } else {
      setDate(new Date());
    }
    setAttachments(props.announcement?.attachments ?? []);
  }, [props.announcement]);

  const isEditing = !isNil(props.announcement);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "20px",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      backdropFilter: "blur(2px)",
    },
  };

  const displayMessage = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
    });
  };

  function dateChanged() {
    const ogDate = new Date(props.announcement.updatedAt);
    return (
      ogDate.getMonth() !== date.getMonth() ||
      ogDate.getDay() !== date.getDay() ||
      ogDate.getFullYear() !== date.getFullYear()
    );
  }

  async function uploadFiles() {
    return Promise.all(
      filesToUpload.map(async (file) => {
        // console.log(file);

        const formData = new FormData();
        formData.append('file', file);

        const resp = await fetch(`${process.env.REACT_APP_API_URL}/files/upload`, {
          method: "POST",
          headers: {
            "x-access-token": localStorage.getItem("token"),
          },
          body: formData,
        });

        if (resp.status === 200) {
          return resp.json();
        } else {
          displayMessage("Something went wrong. Please try again.", "error");
          return null;
        }
      })
    );
  }

  function addPendingAttachmentToDelete(attachment) {
    setAttachmentsToDelete([...attachmentsToDelete, attachment])
    setAttachments(attachments.filter((a) => a.id !== attachment.id))
  }

  async function deletePendingAttachments() {
    if (attachmentsToDelete.length === 0) {
      return;
    }

    for (const attachment of attachmentsToDelete) {
      const resp = await fetch(`${process.env.REACT_APP_API_URL}/files/${attachment.id}`, {
        method: "DELETE",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });

      if (resp.status !== 200) {
        displayMessage("Something went wrong. Please try again.", "error");
        break;
      }
    }
  }

  async function editAnnouncement() {
    setSaveLoading(true);
    if (!titleOrContentChanged) {
      displayMessage(
        "Nothing has been changed. Edit the title, date or content.",
        "error"
      );
      setSaveLoading(false);
      return;
    }
    if (!title || title.length < 1) {
      displayMessage("The title can't be empty", "error");
      setSaveLoading(false);
      return;
    }
    if (!content || content.length < 1) {
      displayMessage("The content can't be empty", "error");
      setSaveLoading(false);
      return;
    }
    if (!date) {
      displayMessage("The date can't be empty", "error");
      setSaveLoading(false);
      return;
    }

    await deletePendingAttachments();
    let attachmentsToSave = attachments;
    if (filesToUpload.length > 0) {
      let newAttachments = await uploadFiles();
      attachmentsToSave = [...attachmentsToSave, ...newAttachments];
      setAttachments(attachmentsToSave)
    }

    const req = await fetch(`${process.env.REACT_APP_API_URL}/announcements`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        updatedAnnouncement: {
          _id: props.announcement._id,
          title: title,
          content: content,
          date: date,
          attachments: attachmentsToSave
        },
      }),
    });
    if (req.status === 200) {
      props.onAnnouncementUpdated();
      displayMessage("The announcement has been updated", "success");
      props.onRequestClose();
      setFilesToUpload([]);
    } else {
      displayMessage("Something went wrong. Please try again.", "error");
    }
    setSaveLoading(false);
  }

  async function removeAnnouncement() {
    setDeleteLoading(true);
    const req = await fetch(`${process.env.REACT_APP_API_URL}/announcements`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        objectId: props.announcement._id,
      }),
    });
    if (req.status === 200) {
      props.onAnnouncementUpdated();
      displayMessage("The announcement has been removed", "success");
      props.onRequestClose();
      setTitle("");
      setContent("");
    } else {
      displayMessage("Something went wrong. Please try again.", "error");
    }
    setDeleteLoading(false);
  }

  async function createAnnouncement() {
    setSaveLoading(true);

    if (filesToUpload.length > 0) {
      setAttachments(await uploadFiles());
    }

    const req = await fetch(`${process.env.REACT_APP_API_URL}/announcements`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        title: title,
        content: content,
        date: date,
        attachments: attachments
      }),
    });
    if (req.status === 200) {
      props.onAnnouncementUpdated();
      displayMessage("The announcement has been created", "success");
      props.onRequestClose();
      setTitle("");
      setContent("");
      setFilesToUpload([]);
    } else {
      displayMessage("Something went wrong. Please try again.", "error");
    }
    setSaveLoading(false);
  }

  let titleOrContentChanged =
    props.announcement &&
    (title !== props.announcement.title ||
      content !== props.announcement.content ||
      dateChanged() ||
      filesToUpload.length > 0 ||
      attachments.length !== (props.announcement?.attachments?.length ?? 0)
    );

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      style={customStyles}
      ariaHideApp={false}
      onAfterClose={props.onAfterClose}
    >
      <div style={{ width: "50vw", minWidth: "400px", padding: "10px" }}>
        <div style={{ textAlign: "end" }}>
          <button onClick={props.onRequestClose}>
            <ImCross size={12} style={{ color: "#B2AEAE" }} />
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "20px",
            marginTop: "-10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "60%",
              marginRight: "5px",
            }}
          >
            <p className="edit-label">Title</p>
            <input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              type="text"
              placeholder="Title"
              className="edit-input-title"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <p className="edit-label">Date</p>
            {date !== null && date !== undefined && (
              <DatePicker
                selected={date}
                onChange={(newDate) => setDate(newDate)}
                style={{
                  border: "2px solid red",
                }}
              />
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <p className="edit-label">Content</p>
          <textarea
            value={content}
            rows={15}
            onChange={(e) => setContent(e.target.value)}
            type="text"
            placeholder="Type your content here..."
            className="edit-input"
            style={{ borderRadius: "10px", lineHeight: "1.4" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <p className="edit-label" style={{marginBottom: 0}}>Attachments</p>

          <ul style={{fontSize: "20px", marginTop: 0}}>
            {attachments.map((a, idx) =>
              <li key={"attachment_" + idx}>{a.name}
                <TiDelete color='red'
                          style={{cursor: "pointer"}}
                          size={25}
                          onClick={() => addPendingAttachmentToDelete(a)} />
              </li>
            )}
          </ul>

          <p className="edit-label" style={{marginBottom: "10px"}}>Add Attachments</p>

          <input
            type="file"
            accept="image/png,image/gif,image/jpeg,.pdf"
            onChange={(event) => {
              const file = event.target.files[0];
              setFilesToUpload([...filesToUpload, file]);
              event.target.value = null;
            }}
            style={{display: "inline"}}
          />
          <div>
            <ul>
              {filesToUpload.map((f) =>
                <li key={f.name}>{f.name}</li>
              )}
            </ul>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {isEditing && (
            <AppButton
              onClick={removeAnnouncement}
              color="#DD3539"
              title="Delete"
              loading={deleteLoading}
              fontSize={"14px"}
              width="20%"
              margin="0 10px 0 10px"
            />
          )}
          <AppButton
            onClick={props.onRequestClose}
            outline={true}
            title="Cancel"
            fontSize={"14px"}
            width="20%"
            margin="0 10px 0 10px"
          />

          <AppButton
            onClick={() => {
              if (isEditing) {
                editAnnouncement();
              } else {
                createAnnouncement();
              }
            }}
            title="Save"
            loading={saveLoading}
            fontSize={"14px"}
            width="20%"
            margin="0 10px 0 10px"
          />
        </div>
      </div>
    </Modal>
  );
}
