import Modal from "react-modal";
import {ImCross} from "react-icons/im";
import {useState} from "react";
import {AppButton} from "../AppButton";
import {useSnackbar} from "notistack";
import "react-datepicker/dist/react-datepicker.css";
import {BsFillCameraFill, BsPersonCircle} from "react-icons/bs";
import {reqHeaders,} from "../../utils";
import {Divider} from "@mui/material";
import Switch from "react-switch";
import {ConfirmDisableInstructorModal} from "./ConfirmDeleteInstructorModal";
import ScheduleEdit from "../ScheduleEdit";
import {AiOutlinePlusCircle} from "react-icons/ai";
import ScheduleItem from "../ScheduleItem";

export function CreateInstructorModal(props) {
  const instructor = props.instructor ?? null;
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [email, setEmail] = useState(props.isEditing ? instructor?.email : "");
  const [name, setName] = useState(getInitialName());
  const [instructorOrderBy, setInstructorOrderBy] = useState(instructor?.instructorOrderBy ?? 0);
  const [profileImage, setProfileImage] = useState(instructor?.profilePhoto);
  const [isSuperAdmin, setIsSuperAdmin] = useState(instructor?.role === 3);
  const [schedules, setSchedules] = useState(instructor?.scheduleV2 || []);
  const [showAddSchedule, setShowAddSchedule] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [minimumChildAge, setMinimumChildAge] = useState(instructor?.minimumChildAge?.toString());

  const { enqueueSnackbar } = useSnackbar();

  const displayMessage = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
    });
  };

  function getInitialName() {
    if (instructor?.firstName && instructor?.lastName) {
      return `${instructor?.firstName} ${instructor?.lastName}`;
    }
    return "";
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "20px",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      backdropFilter: "blur(2px)",
    },
  };

  async function createInstructor() {
    setSaveLoading(true);
    if (!name || name.length < 1) {
      setSaveLoading(false);
      displayMessage("Please enter a name", "error");
      return;
    }

    try {
      const names = name.split(" ");
      if (names.length < 2) {
        setSaveLoading(false);
        displayMessage("Please enter a first and last name", "error");
        return;
      }
      const firstName = names[0];
      // Remove first item of array
      const index = names.indexOf(firstName);
      if (index > -1) {
        names.splice(index, 1);
      }
      const lastName = names?.toString().replaceAll(",", " ") ?? "";
      const minAge = (!!minimumChildAge) ? parseInt(minimumChildAge) : null;

      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/users/instructors`,
        {
          method: "POST",
          headers: reqHeaders,
          body: JSON.stringify({
            user: {
              email: email,
              password: "mountainwaveswimming",
              firstName: firstName,
              lastName: lastName ?? "",
              profilePhoto: profileImage,
              role: isSuperAdmin ? 3 : 1,
              scheduleV2: schedules,
              instructorOrderBy: instructorOrderBy,
              minimumChildAge: minAge,
            },
          }),
        }
      );
      if (req.status === 200) {
        setEmail("");
        setName("");
        setInstructorOrderBy(0);
        setSchedules([]);
        setIsSuperAdmin(false);
        props.onInstructorCreated();
        displayMessage("The instructor has been created", "success");
        props.onRequestClose();
      } else {
        const data = await req.json();
        if (data.message.includes("The provided email has already be used")) {
          return displayMessage("This email is already is use.", "error");
        }
        displayMessage("Something went wrong. Please try again.", "error");
      }
    } catch (err) {
      console.debug(err);
      displayMessage("Something went wrong. Please try again.", "error");
    }
    setSaveLoading(false);
  }

  async function updateInstructor() {
    try {
      setSaveLoading(true);
      const names = name.split(" ");
      if (names.length < 2) {
        setSaveLoading(false);
        displayMessage("Please enter a first and last name", "error");
        return;
      }
      const firstName = names[0];
      // Remove first item of array
      const index = names.indexOf(firstName);
      if (index > -1) {
        names.splice(index, 1);
      }
      const lastName = names?.toString().replaceAll(",", " ") ?? "";
      const minAge = (!!minimumChildAge) ? parseInt(minimumChildAge) : null;

      const basicInfoReq = await fetch(
        `${process.env.REACT_APP_API_URL}/users/instructors`,
        {
          method: "PUT",
          headers: reqHeaders,
          body: JSON.stringify({
            user: {
              userId: instructor?._id,
              firstName: firstName,
              lastName: lastName,
              profilePhoto: profileImage,
              instructorOrderBy: instructorOrderBy,
              minimumChildAge: minAge,
            },
          }),
        }
      );
      const privilegeReq = await fetch(
        `${process.env.REACT_APP_API_URL}/users/privileges`,
        {
          method: "PUT",
          headers: reqHeaders,
          body: JSON.stringify({
            userId: instructor?._id,
            role: isSuperAdmin ? 3 : 1,
          }),
        }
      );
      const scheduleReq = await fetch(
        `${process.env.REACT_APP_API_URL}/users/instructors/schedule`,
        {
          method: "PUT",
          headers: reqHeaders,
          body: JSON.stringify({
            userId: instructor._id,
            scheduleV2: schedules,
          }),
        }
      );
      if (
        basicInfoReq.status === 200 &&
        privilegeReq.status === 200 &&
        scheduleReq.status === 200
      ) {
        setEmail("");
        setName("");
        setInstructorOrderBy(0);
        props.onInstructorUpdated();
        displayMessage("The instructor has been updated", "success");
        props.onRequestClose();
      } else {
        displayMessage("Something went wrong. Please try again.", "error");
      }
    } catch (err) {
      console.debug(err);
      displayMessage("Something went wrong. Please try again.", "error");
    }
    setSaveLoading(false);
  }

  async function disableInstructor() {
    try {
      setSaveLoading(true);
      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/users/instructors/disable`,
        {
          method: "PUT",
          headers: reqHeaders,
          body: JSON.stringify({
            userId: instructor?._id,
          }),
        }
      );
      if (req.status === 200) {
        props.onInstructorDisabled();
        displayMessage("The instructor has been removed", "success");
        props.onRequestClose();
      } else {
        displayMessage("Something went wrong. Please try again.", "error");
      }
    } catch (err) {
      console.debug(err);
      displayMessage("Something went wrong. Please try again.", "error");
    }
    setSaveLoading(false);
  }

  const saveSchedule = (schedule, idToUpdate) => {
    const isUpdating = !!idToUpdate;

    for (const existingSchedule of schedules) {
      if (
        (!isUpdating || existingSchedule?._id !== idToUpdate)
        && ((schedule.startDate.getTime() >= existingSchedule.startDate.getTime()
            && schedule.startDate.getTime() <= existingSchedule.endDate.getTime())
          || (schedule.endDate.getTime() >= existingSchedule.startDate.getTime()
            && schedule.endDate.getTime() <= existingSchedule.endDate.getTime()))
      ) {
        displayMessage("New schedule overlaps with existing schedule, cannot add", "error");
        return;
      }
    }

    if (isUpdating) {
      let toUpdate = schedules.find(s => s._id === idToUpdate);
      toUpdate.startDate = schedule.startDate;
      toUpdate.endDate = schedule.endDate;
      toUpdate.daysOff = schedule.daysOff;
      toUpdate.shifts = schedule.shifts;
    } else {
      setSchedules([...schedules, schedule]);
    }
    setSelectedSchedule(null);
    setShowAddSchedule(false);
  }

  const removeSchedule = (schedule) => {
    let idx = schedules.indexOf(schedule);
    schedules.splice(idx, 1);
    setSchedules([...schedules]);
    setShowAddSchedule(false);
  }

  const editSchedule = (schedule) => {
    setSelectedSchedule(schedule);
    setShowAddSchedule(true);
  }

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        style={customStyles}
        ariaHideApp={false}
        onAfterClose={props.onAfterClose}
      >
        <div style={{ width: "40vw", minWidth: "400px", padding: "10px" }}>
          <div style={{ textAlign: "end" }}>
            <button onClick={props.onRequestClose}>
              <ImCross size={12} style={{ color: "#B2AEAE" }} />
            </button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "start",
              justifyContent: "start",
              alignItems: "start",
            }}
          >
            <label className="modal-image-container">
              <input
                type="file"
                name="instructor-image"
                accept="image/png, image/gif, image/jpeg"
                onChange={(event) => {
                  let files = event.target.files;
                  let reader = new FileReader();
                  reader.readAsDataURL(files[0]);
                  reader.onload = (e) => {
                    setProfileImage(e.target.result);
                  };
                }}
              />
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    background: "white",
                    position: "absolute",
                    right: "0px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    borderRadius: "50px",
                    boxShadow: "0px 1px 4px rgba(0,0,0,0.4)",
                  }}
                >
                  <BsFillCameraFill
                    size={16}
                    style={{
                      color: "rgb(215, 219, 236)",
                    }}
                  />
                </div>

                {!profileImage ? (
                  <BsPersonCircle size={120} style={{ color: "#DEDEDE" }} />
                ) : (
                  <img
                    src={profileImage}
                    alt=""
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "100px",
                      objectFit: "cover",
                    }}
                  />
                )}
              </div>
            </label>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
                padding: "0px 0 40px 30px",
              }}
            >
              <p className="edit-label">Email</p>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                placeholder="Type Email"
                className="login-input"
                disabled={props.isEditing}
                id="email"
              />
              <p className="edit-label" style={{marginTop: 10}}>
                First & Last Name
              </p>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                placeholder="Type Name"
                className="login-input"
                id="first-name"
              />
              <p className="edit-label" style={{marginTop: 10}}>
                Minimum Child Age
              </p>
              <input
                value={minimumChildAge}
                onChange={(e) => setMinimumChildAge(e.target.value)}
                type="text"
                placeholder="Minimum Child Age (or leave blank for none)"
                className="login-input"
                id="minimum-child-age"
              />
              <p className="edit-label" style={{marginTop: 10}}>
                Schedule Order By
              </p>
              <input
                value={instructorOrderBy}
                onChange={(e) => setInstructorOrderBy(e.target.value)}
                type="text"
                placeholder="Schedule Order By"
                className="login-input"
                id="sort"
              />
              <div className='row' style={{fontSize: '15px', alignItems: 'center'}}>
                <div>
                  Schedules
                </div>
                <div>
                  <AiOutlinePlusCircle
                    style={{
                      color: "#6F6F6F",
                      cursor: "pointer",
                      marginLeft: "5px",
                      marginTop: "5px"
                    }}
                    onClick={() => {
                      setSelectedSchedule(null);
                      setShowAddSchedule(true);
                    }}
                    size={24}
                  />
                </div>
              </div>

              <Divider/>

              {schedules.map((schedule, idx) =>
                <ScheduleItem key={`schedule_item_${idx}`}
                              schedule={schedule}
                              removeSchedule={removeSchedule}
                              editSchedule={editSchedule}
                />
              )}

              {showAddSchedule &&
                <ScheduleEdit onSave={saveSchedule} schedule={selectedSchedule}/>
              }

              <p className="edit-label" style={{marginTop: 30}}>
                Privileges
              </p>
              <Divider/>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "10px",
                }}
              >
                <Switch
                  onChange={() => setIsSuperAdmin(!isSuperAdmin)}
                  checked={isSuperAdmin}
                  handleDiameter={20}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.3)"
                  height={19}
                  width={35}
                  onHandleColor="#45CDCF"
                  offHandleColor="#D7DBEC"
                  onColor="#e3e3e3"
                  offColor="#e3e3e3"
                />
                <p
                  style={{
                    color: "#7E84A3",
                    marginLeft: "10px",
                    marginRight: "20px",
                  }}
                >
                  Admin
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{width: "140px"}}>
              <button onClick={() => {
              }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: "20px",
                    marginBottom: "10px",
                  }}
                >
                  {/* <FiTrash2
                  size={16}
                  style={{ color: "#F0142F", marginRight: "5px" }}
                />
                <p style={{ color: "#F0142F", fontSize: "12px" }}>
                  Remove Instructor
                </p> */}
                </div>
              </button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {props.isEditing && (
                <AppButton
                  onClick={() => {
                    setConfirmModalOpen(true);
                  }}
                  color="#DD3539"
                  title="Delete Instructor"
                  loading={saveLoading}
                  fontSize={"14px"}
                  margin="0 10px 0 10px"
                />
              )}

              <AppButton
                onClick={props.onRequestClose}
                outline={true}
                loading={saveLoading}
                title="Cancel"
                fontSize={"12px"}
                fontWeight="600"
                margin="0 10px 0 10px"
                padding="10px 30px"
              />

              <AppButton
                onClick={props.isEditing ? updateInstructor : createInstructor}
                title="Save"
                loading={saveLoading}
                fontSize={"12px"}
                fontWeight="600"
                margin="0 10px 0 5px"
                padding="10px 30px"
              />
            </div>
          </div>
        </div>
      </Modal>
      <ConfirmDisableInstructorModal
        isOpen={confirmModalOpen}
        instructor={instructor}
        onRequestClose={() => {
          setConfirmModalOpen(false);
        }}
        onConfirmPressed={disableInstructor}
      />
    </>
  );
}
