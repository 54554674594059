/* eslint-disable react-hooks/exhaustive-deps */

import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useState } from "react";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { BsPersonCircle } from "react-icons/bs";
import { HiSearchCircle } from "react-icons/hi";
import { reqHeaders } from "../utils";
import { AppButton } from "./AppButton";
import TotalLessonsRemaining from "./TotalLessonsRemaining";
import ExpiringLessonsRemaining from "./ExpiringLessonsRemaining";
import DatePicker from "react-datepicker";

export default function BookLessonModalAdmin(props) {
  const [searchText, setSearchText] = useState("");
  const [expireDays, setExpireDays] = useState("");
  const [expireDate, setExpireDate] = useState(null);
  const [fetchParentsLoading, setFetchParentsLoading] = useState(false);
  const [giftLessonsLoading, setGiftLessonsLoading] = useState(false);
  const [students, setParents] = useState([]);
  const [selectedParent, setSelectedParent] = useState();
  const [lessonCount, setLessonCount] = useState(1);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const searchDelay = setTimeout(() => {
      setSelectedParent(null);
      fetchParents();
    }, 500);
    return () => clearTimeout(searchDelay);
  }, [searchText]);

  async function fetchParents() {
    setFetchParentsLoading(true);
    const text = searchText.trim();
    if (!text || text.length < 1) {
      setFetchParentsLoading(false);
      return;
    }
    try {
      const req = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }/users/parents/search/${encodeURIComponent(text)}`,
        {
          method: "GET",
          headers: reqHeaders,
        }
      );
      const res = await req.json();
      // Order by last name
      if (res.data) {
        res.data.sort(function (a, b) {
          if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) return -1;
          if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) return 1;
          return 0;
        });
        setParents(res.data);
      }
      setFetchParentsLoading(false);
    } catch (err) {
      console.debug("Error: ", err);
      setFetchParentsLoading(false);
    }
  }

  async function giftLessons() {
    setGiftLessonsLoading(true);
    try {
      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/users/parents/gift`,
        {
          method: "PUT",
          headers: reqHeaders,
          body: JSON.stringify({
            parentId: selectedParent._id,
            quantity: lessonCount,
            expireDays: expireDays !== "" ? parseInt(expireDays) : 0,
            expireDate: expireDate
          }),
        }
      );
      setGiftLessonsLoading(false);
      if (req.status === 200) {
        displayMessage(
          `${lessonCount} ${
            lessonCount !== 1 ? "lessons" : "lesson"
          } have been added to ${selectedParent.firstName} ${
            selectedParent.lastName
          }'s account`,
          "success"
        );
        setSearchText("");
        setSelectedParent(null);
        setExpireDays("");
        setLessonCount(1);
      } else {
        displayMessage(
          `Something went wrong. Please refresh the page and try again`,
          "error"
        );
      }
    } catch (err) {
      console.debug("Error: ", err);
      setGiftLessonsLoading(false);
      displayMessage(
        `Something went wrong. Please refresh the page and try again`,
        "error"
      );
    }
  }

  function studentSelected(student) {
    setParents([]);
    setSelectedParent(student);
  }

  const displayMessage = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
    });
  };

  function selectExpirationDate(d) {
    setExpireDays("");
    setExpireDate(d);
  }

  function handleExpireDaysChange(e) {
    try {
      setExpireDays(parseInt(e.target.value));
      setExpireDate(null);
    } catch (err) {
      console.debug(err);
    }
  }

  return (
    <div
      style={{
        minWidth: "400px",
        height: "55vh",
        padding: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          marginTop: "70px",
        }}
      >
        <p style={{ fontWeight: "600", fontSize: "30px", margin: "0" }}>
          Which parent would you like give lessons?
        </p>
        <div style={{ width: "70%", marginBottom: "50px", marginTop: 10 }}>
          {
            <>
              {!fetchParentsLoading ? (
                <div
                  className="col"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "45px",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "30px",
                        border: "2px solid #d7dbec",
                        width: "70%",
                        background: "white",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <input
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        type="text"
                        autoFocus
                        placeholder="Search..."
                        className="modal-search"
                        style={{ marginLeft: "30px", width: "50%" }}
                      />
                      <HiSearchCircle color="#45CDCF" size={40} />
                    </div>
                  </div>
                  {students.length > 0 && (
                    <div
                      className="col"
                      style={{
                        width: "50%",
                        minHeight: "0px",
                        maxHeight: "200px",
                        marginTop: "-2px",
                        borderLeft: "2px solid #d7dbec",
                        borderRight: "2px solid #d7dbec",
                        borderBottom: "2px solid #d7dbec",
                        overflow: "scroll",
                        overflowX: "hidden",
                      }}
                    >
                      {students.map((student) => (
                        <p
                          onClick={() => studentSelected(student)}
                          className="student-name-result"
                          key={student._id}
                        >
                          {`${student.firstName} ${student.lastName}`}
                        </p>
                      ))}
                    </div>
                  )}

                  {selectedParent && (
                    <div style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "end",
                      width: "70%"
                    }}>
                      <div style={{
                          borderRadius: "30px",
                          border: "2px solid #d7dbec",
                          width: "50%",
                          background: "white",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "10px",
                          maxWidth: "300px"
                        }}
                      >
                        <input
                          value={expireDays}
                          onChange={(e) => handleExpireDaysChange(e)}
                          type="number"
                          placeholder="Expire After Days"
                          className="modal-search"
                          style={{ marginLeft: "30px", width: "50%" }}
                        />
                        <span style={{marginRight: "20px"}}>{" Days"}</span>
                      </div>
                      <div style={{width: "10px"}}/>
                      <div>
                        <DatePicker
                          selected={expireDate}
                          onChange={(newDate) => selectExpirationDate(newDate)}
                        />
                      </div>
                    </div>
                  )}

                  {selectedParent && (
                    <div
                      className="row"
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "70px",
                      }}
                    >
                      <div
                        className="row"
                        style={{
                          alignItems: "center",
                        }}
                      >
                        {!selectedParent?.profilePhoto ? (
                          <BsPersonCircle
                            size={80}
                            style={{ color: "#DEDEDE" }}
                          />
                        ) : (
                          <img
                            src={selectedParent?.profilePhoto ?? ""}
                            alt=""
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "100px",
                              objectFit: "cover",
                            }}
                          />
                        )}
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: "600",
                            padding: "0",
                            marginLeft: "10px",
                            marginRight: "10px"
                          }}
                        >
                          {selectedParent.firstName} {selectedParent.lastName}
                        </p>
                        <TotalLessonsRemaining user={selectedParent} />
                        <span style={{marginRight: "10px"}}>
                          <ExpiringLessonsRemaining user={selectedParent} />
                        </span>

                      </div>
                      <div
                        className="row"
                        style={{
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="row"
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: 20,
                          }}
                        >
                          <AiOutlineMinusCircle
                            size={16}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setLessonCount(lessonCount - 1);
                            }}
                          />
                          <p
                            style={{
                              padding: 0,
                              margin: "0 10px",
                              fontSize: 20,
                            }}
                          >
                            {lessonCount}
                          </p>
                          <AiOutlinePlusCircle
                            size={16}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setLessonCount(lessonCount + 1);
                            }}
                          />
                        </div>
                        <AppButton
                          onClick={giftLessons}
                          loading={giftLessonsLoading}
                          title={`Gift ${lessonCount} ${
                            lessonCount !== 1 ? "Lessons" : "Lesson"
                          } ${(expireDays === "" && expireDate === null) ? "" : " (Expires) "}`}
                          fontSize={"14px"}
                          padding="12px 30px"
                        />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div style={{ textAlign: "center" }}>
                  <CircularProgress
                    size={26}
                    style={{ color: "black", marginTop: "100px" }}
                  />
                </div>
              )}
            </>
          }
        </div>
      </div>
    </div>
  );
}
