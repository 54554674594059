/* eslint-disable jsx-a11y/alt-text */
import { useContext, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { AppContext } from "../../pages/AppContainer";
import { AppButton } from "../AppButton";
import { ConfirmPurchaseModal } from "./ConfirmPurchaseModal";
import {format} from "date-fns";

export function PurchaseLessonsPanel(props) {
  const { currentUser } = useContext(AppContext);
  const [quantity, setQuantity] = useState(1);
  const [confirmPurchaseOpen, setConfirmPurchaseOpen] = useState(false);
  const product = props.product;
  const subtotal = ((product.price * quantity) / 100).toFixed(2);
  function incrementQty() {
    if (quantity + 1 > 5) {
      return;
    }
    setQuantity(quantity + 1);
  }

  function decrementQty() {
    if (quantity - 1 < 1) {
      return;
    }
    setQuantity(quantity - 1);
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div style={{ display: "flex", justifyContent: "end" }}>
          <button onClick={props.onRequestClose}>
            <GrFormClose size={28} />
          </button>
        </div>
        <p style={{ fontSize: "26px", fontWeight: "600", marginTop: "8vh" }}>
          Shopping Cart
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
            marginTop: "3vh",
            width: "80%",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <button onClick={props.onRequestClose}>
              <GrFormClose size={22} style={{ marginRight: "10px" }} />
            </button>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
              }}
            >
              <p
                style={{
                  padding: "0",
                  margin: "0",
                  fontSize: "18px",
                  lineHeight: "30px",
                  fontWeight: "600",
                }}
              >
                {product.numLessons}{" "}
                {product.numLessons !== 1 ? "Lessons" : "Lesson"}
              </p>
              <p
                style={{
                  fontSize: "16px",
                  color: "#45CDCF",
                  padding: "0",
                  margin: "0",
                }}
              >
                ${(product.price / 100).toFixed(0)}
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              border: "2px solid #D7DBEC",
              borderRadius: "25px",
              padding: "4px 10px",
              justifyContent: "center",
              alignItems: "center",
              width: "80px",
            }}
          >
            <div style={{ width: "60px" }}>
              <p
                style={{ padding: "0", fontSize: "12px", textAlign: "center" }}
              >
                {quantity}
              </p>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <button
                onClick={incrementQty}
                style={{ margin: "0", padding: "0", lineHeight: "0" }}
              >
                <img
                  src="/icons/up-arrow.png"
                  style={{
                    padding: "0",
                    margin: "0",
                    width: "12px",
                    marginBottom: "1.5px",
                  }}
                />
              </button>
              <button
                onClick={decrementQty}
                style={{ margin: "0", padding: "0", lineHeight: "0" }}
              >
                <img
                  src="/icons/down-arrow.png"
                  style={{
                    padding: "0",
                    margin: "0",
                    width: "12px",
                    marginTop: "1.5px",
                  }}
                />
              </button>
            </div>
          </div>
        </div>
        {product.expireDays > 0 && (
          <div style={{ color: "#F0142F", marginTop: "10px", fontSize: "1.2em"}}>
            * Lesson(s) will expire in {product.expireDays} Days
          </div>
        )}
        {product.expireDate !== "" && (
          <div style={{ color: "#F0142F", marginTop: "10px", fontSize: "1.2em"}}>
            * Lesson(s) will expire on {format(new Date(product.expireDate), "MMM dd, yyyy")}
          </div>
        )}
        <p style={{ fontSize: "22px", fontWeight: "600", marginTop: "10vh" }}>
          Order Summary
        </p>
        <div style={{ marginLeft: "30px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                fontWeight: "600",
                marginRight: "30px",
              }}
            >
              Subtotal
            </p>
            <p style={{ fontSize: "14px", color: "#45CDCF" }}>${subtotal}</p>
          </div>

          {/* <p
            style={{
              fontSize: "12px",
              color: "#45CDCF",
              padding: "0",
              margin: "0",
            }}
          >
            Apply a coupon code
          </p> */}
        </div>
        <div style={{ textAlign: "center", marginTop: "10vh" }}>
          <AppButton
            onClick={props.onRequestClose}
            title="Back"
            outline={true}
            fontSize="12px"
            margin="0 10px 0 0"
            padding="5px 20px 5px 20px"
          />
          <AppButton
            onClick={() => setConfirmPurchaseOpen(true)}
            title="Proceed to Checkout"
            fontSize="12px"
            margin="0 0 0 10px"
            padding="5px 20px 5px 20px"
          />
        </div>
        <p style={{ color: "#F0142F", marginTop: "10vh", textAlign: "center" }}>
          * Please note that{" "}
          <span style={{ fontWeight: "600" }}>ALL SALES ARE FINAL.</span>{" "}
          Refunds are not an option.
        </p>
      </div>
      <ConfirmPurchaseModal
        isOpen={confirmPurchaseOpen}
        currentUser={currentUser}
        onRequestClose={() => {
          setConfirmPurchaseOpen(false);
        }}
        onPurchaseSuccess={() => {
          props.onRequestClose();
          props.onPurchaseSuccess();
        }}
        product={product}
        subtotal={subtotal}
        quantity={quantity}
      />
    </>
  );
}
