/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from "react";
import jwt from "jsonwebtoken";
import { useHistory } from "react-router-dom";
import { TopHeader } from "../components/TopHeader";
import { SideMenu } from "../components/SideMenu";
import { Announcements } from "./Announcements";
import { Students } from "./Students";
import { AccountSettings } from "./AccountSettings";
import { ListedLessons } from "./ListedLessons";
import { PurchaseLessons } from "./PurchaseLessons";
import AdminDashboard from "./AdminDashboard";
import { Instructors } from "./Instructors";
import { AdminCalendar } from "./AdminCalendar";
import { BookLesson } from "./BookLesson";
import { Calendar } from "./Calendar";
import { MyStudents } from "./MyStudents";
import { reqHeaders } from "../utils";
import Dashboard from "./Dashboard";
import { UserInfoSidebar } from "../components/UserInfoSidebar";
import GiftLessons from "../components/GiftLessons";
import ExpiringLessonReport from "./expiring_lessons/ExpiringLessonReport";
import UserAdminList from "./user_admin/UserAdminList";

export const AppContext = createContext(null);

const AppContainer = () => {
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState();
  const [currentTab, setCurrentTab] = useState(1);
  const [parents, setParents] = useState([]);
  const [totalParentPages, setTotalParentPages] = useState(1);
  const [sidebarLessons, setSidebarLessons] = useState([]);
  const [sidebarUpcomingLessons, setSidebarUpcomingLessons] = useState([]);
  const [sidebarSelectedDate, setSidebarSelectedDate] = useState(new Date());
  const [sidebarLoading, setSidebarLoading] = useState(false);
  const [daysInMonth, setDaysInMonth] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const user = jwt.decode(token);
      if (!user) {
        localStorage.removeItem("token");
        history.push("/login");
      } else {
        getCurrentUser();
      }
    } else {
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    getDaysInMonth(sidebarSelectedDate);
    fetchMonthCalendar();
  }, [sidebarSelectedDate]);

  useEffect(() => {
    fetchUpcomingLessons();
  }, []);

  async function getCurrentUser() {
    const req = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
      method: "GET",
      headers: reqHeaders,
    });
    const res = await req.json();
    if (req.status === 403) {
      localStorage.removeItem("token");
      history.push("/login");
    }
    if (res.user) {
      setCurrentUser(res.user);
    }
  }

  async function fetchParents(newCurrentPage) {
    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/users/parents?page=${
        newCurrentPage ?? 0
      }`,
      {
        method: "GET",
        headers: reqHeaders,
      }
    );
    const res = await req.json();
    setTotalParentPages(res.pageCount);
    // Order by last name
    if (res.users) {
      res.users.sort(function (a, b) {
        if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) return -1;
        if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) return 1;
        return 0;
      });
      setParents(res.users);
    }
  }

  function getDaysInMonth(date) {
    let daysArray = [];
    var lastDay = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();
    for (let i = 0; i < lastDay; i++) {
      daysArray.push(i + 1);
    }
    var firstDayOfWeek = new Date(
      date.getFullYear(),
      date.getMonth(),
      1
    ).getDay();
    var lastDayPreviousMonth = new Date(
      date.getFullYear(),
      date.getMonth(),
      0
    ).getDate();
    for (let i = 0; i < firstDayOfWeek; i++) {
      if (daysArray.length < 42) {
        daysArray.unshift(lastDayPreviousMonth - i);
      }
    }
    const leftOver = 42 - daysArray.length;
    for (let i = 0; i < leftOver; i++) {
      daysArray.push(i + 1);
    }
    if (daysArray[daysArray.length - 1] > 6) {
      for (let i = 0; i < 7; i++) {
        daysArray.pop();
      }
    }
    setDaysInMonth(daysArray);
  }

  async function fetchMonthCalendar() {
    if (!isAdmin && !isSuperAdmin) {
      const month = sidebarSelectedDate.getMonth();
      const year = sidebarSelectedDate.getFullYear();

      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/calendar/month?month=${
          month + 1
        }&year=${year}`,
        {
          method: "GET",
          headers: reqHeaders,
        }
      );
      const res = await req.json();
      if (res.data) {
        setSidebarLessons(JSON.parse(res.data));
      }
      setSidebarLoading(false);
    }
  }

  async function fetchUpcomingLessons() {
    setSidebarLoading(true);
    try {
      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/lessons?limit=5`,
        {
          method: "GET",
          headers: reqHeaders,
        }
      );
      const res = await req.json();
      if (req.status === 200) {
        setSidebarUpcomingLessons(res.data);
      }
    } catch (err) {
      setSidebarLoading(false);
    }
    setSidebarLoading(false);
  }

  const isAdmin = currentUser?.role === 1;
  const isSuperAdmin = currentUser?.role > 1;

  return (
    <AppContext.Provider
      value={{
        currentUser,
        getCurrentUser,
        parents,
        fetchParents,
        totalParentPages,
        sidebarLoading,
        sidebarUpcomingLessons,
        fetchUpcomingLessons,
        fetchMonthCalendar,
        setSidebarSelectedDate,
        sidebarSelectedDate,
        sidebarLessons,
        daysInMonth,
      }}
    >
      <div style={{ width: "100vw", background: "#F5F4F4" }}>
        <TopHeader
          onNavToAccount={() => {
            if (currentUser?.role === 0) {
              setCurrentTab(6);
            } else {
              setCurrentTab(7);
            }
          }}
        />
        <div style={{ display: "flex", flexDirection: "row", height: "100vh" }}>
          <SideMenu
            currentTab={currentTab}
            onTabChange={(val) => setCurrentTab(val)}
          />
          <div style={{ paddingTop: "85px", width: "-webkit-fill-available" }}>
            {currentUser?.role === 0 && (
              // Normal user
              <>
                {currentTab === 0 && (
                  <BookLesson
                    changeTab={(val) => {
                      setCurrentTab(val);
                    }}
                    onLessonBooked={() => fetchMonthCalendar()}
                  />
                )}
                {currentTab === 1 && (
                  <Dashboard
                    changeTab={(val) => {
                      setCurrentTab(val);
                    }}
                  />
                )}
                {currentTab === 2 && <Calendar />}
                {currentTab === 3 && <Announcements />}
                {currentTab === 4 && <MyStudents />}
                {currentTab === 5 && <PurchaseLessons />}
                {currentTab === 6 && <AccountSettings />}
              </>
            )}
            {(isAdmin || isSuperAdmin) && (
              // Admin
              <>
                {currentTab === 0 && <BookLesson />}
                {currentTab === 1 && (
                  <AdminDashboard
                    changeTab={(val) => {
                      setCurrentTab(val);
                    }}
                  />
                )}
                {currentTab === 2 && <AdminCalendar />}
                {currentTab === 3 && <Announcements />}
                {currentTab === 4 && <Students />}
                {currentTab === 5 && isSuperAdmin && (
                  <Instructors onViewCalendarPressed={() => setCurrentTab(2)} />
                )}
                {currentTab === 6 && isSuperAdmin && <ListedLessons />}
                {currentTab === 7 && <AccountSettings isAdmin={true} />}
                {currentTab === 8 && <GiftLessons />}
                {currentTab === 9 && <ExpiringLessonReport />}
                {currentTab === 10 && <UserAdminList />}
              </>
            )}
          </div>
          {!isAdmin &&
            !isSuperAdmin &&
            (currentTab === 1 ||
              currentTab === 3 ||
              currentTab === 4 ||
              currentTab === 5 ||
              currentTab === 6) && (
              <UserInfoSidebar onSeeMoreClicked={() => setCurrentTab(2)} />
            )}
        </div>
      </div>
    </AppContext.Provider>
  );
};

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    backdropFilter: "blur(2px)",
  },
};

export default AppContainer;
