import Modal from "react-modal";
import { ImCross } from "react-icons/im";
import { useContext, useEffect, useState } from "react";
import { AppButton } from "../AppButton";
import { useSnackbar } from "notistack";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reqHeaders } from "../../utils";
import { Button } from "@mui/material";
import { AppContext } from "../../pages/AppContainer";

export function ConfirmPurchaseModal(props) {
  const product = props.product;
  const subtotal = props.subtotal;
  const quantity = props.quantity;
  const { getCurrentUser } = useContext(AppContext);

  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [existingCard, setExistingCard] = useState();
  const [useExistingCard, setUseExistingCard] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [cvc, setCvc] = useState("");
  const [expDate, setExpDate] = useState(new Date());

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchCardInformation();
  }, []);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "20px",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      backdropFilter: "blur(2px)",
    },
  };

  const displayMessage = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
    });
  };

  async function fetchCardInformation() {
    const req = await fetch(`${process.env.REACT_APP_API_URL}/users/payment`, {
      method: "GET",
      headers: reqHeaders,
    });
    const res = await req.json();
    if (res.card && res.card.last4 && res.card.id) {
      setExistingCard(res.card);
    }
  }

  async function purchaseLessons() {
    if (!useExistingCard) {
      const card = cardNumber.replaceAll(" ", "");
      if (!card || card.length !== 16) {
        displayMessage("Card length must be 16 characters", "error");
        return;
      }
      if (!cvc || cvc.length < 3 || cvc.length > 4) {
        displayMessage("Invalid CVC", "error");
        return;
      }
      if (expDate < new Date()) {
        displayMessage("Card is expired", "error");
        return;
      }
    }
    setPurchaseLoading(true);
    try {
      // Check the price with qty
      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/products/purchase`,
        {
          method: "POST",
          headers: reqHeaders,
          body: JSON.stringify({
            paymentMethodId: useExistingCard ? existingCard.id : null,
            cardNumber: cardNumber,
            expMonth: expDate.getMonth() + 1, // getMonth is by index
            expYear: expDate.getFullYear(),
            cvc: cvc,
            quantity: quantity,
            subtotal: parseInt(subtotal * 100),
            product: product,
          }),
        }
      );
      const data = await req.json();
      setPurchaseLoading(false);
      if (req.status === 400) {
        if (data.message === "Contact customer service") {
          displayMessage(
            "You payment went through, but the lessons were not added to your account. Contact customer service if you were not refunded.",
            "error"
          );
        } else {
          displayMessage(
            "Purchase failed. Please check your card information.",
            "error"
          );
        }
        return;
      }
      if (req.status === 200) {
        displayMessage(
          `${quantity * product.numLessons} lesson${
            quantity * product.numLessons !== 1 ? "s" : ""
          } successfully added to your account!`,
          "success"
        );
        getCurrentUser();
        props.onRequestClose();
        props.onPurchaseSuccess();
      }
    } catch (err) {
      console.debug("Error: ", err);
      setPurchaseLoading(false);
      displayMessage(
        "You payment went through, but the lessons were not added to your account. Contact customer service if you were not refunded.",
        "error"
      );
    }
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      style={customStyles}
      ariaHideApp={false}
      onAfterClose={props.onAfterClose}
    >
      <div
        style={{
          width: "40vw",
          minWidth: "200px",
          maxWidth: "600px",
          padding: "10px",
        }}
      >
        <div style={{ textAlign: "end" }}>
          <button onClick={props.onRequestClose}>
            <ImCross size={12} style={{ color: "#B2AEAE" }} />
          </button>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontSize: "26px",
                fontWeight: "600",
                textAlign: "start",
                padding: "0",
                margin: "0 0 10px 0",
              }}
            >
              Confirm Order
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "baseline",
                width: "80%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                }}
              >
                <p
                  style={{
                    padding: "0",
                    margin: "0",
                    fontSize: "14px",
                    lineHeight: "30px",
                    fontWeight: "600",
                  }}
                >
                  {product.numLessons}{" "}
                  {product.numLessons !== 1 ? "Lessons" : "Lesson"}
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      marginLeft: "15px",
                    }}
                  >
                    X
                  </span>{" "}
                  {quantity}
                </p>
              </div>
              <p
                style={{
                  fontSize: "16px",
                  color: "#45CDCF",
                  padding: "0",
                  margin: "0",
                }}
              >
                ${(product.price / 100).toFixed(0)}
              </p>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "baseline",
                width: "80%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                }}
              >
                <p
                  style={{
                    padding: "0",
                    margin: "0",
                    fontSize: "14px",
                    lineHeight: "30px",
                    fontWeight: "600",
                  }}
                >
                  Subtotal
                </p>
              </div>
              <p
                style={{
                  fontSize: "16px",
                  color: "#45CDCF",
                  padding: "0",
                  margin: "0",
                }}
              >
                ${subtotal}
              </p>
            </div>

            {existingCard && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "center",
                  marginTop: "7px",
                }}
              >
                <input
                  type="checkbox"
                  checked={useExistingCard}
                  className="check"
                  onChange={() => setUseExistingCard(!useExistingCard)}
                />
                <Button
                  style={{
                    color: "#454545",
                    textTransform: "none",
                    fontWeight: "200",
                  }}
                  onClick={() => setUseExistingCard(!useExistingCard)}
                >
                  Use my card ending in {existingCard.last4}
                </Button>
              </div>
            )}

            {!useExistingCard && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "60%",
                  marginTop: "15px",
                }}
              >
                <p className="inputLabel">Card Number</p>
                <input
                  value={cardNumber}
                  autoComplete="cc-number"
                  onChange={(e) =>
                    setCardNumber(
                      e.target.value
                        .replace(/\W/gi, "")
                        .replace(/(.{4})/g, "$1 ")
                        .trim()
                    )
                  }
                  type="text"
                  placeholder="**** **** **** 1234"
                  className="login-input no-icon"
                  style={{ color: "#406c8c" }}
                  inputMode="numeric"
                  pattern="[0-9\s]{13,19}"
                  maxLength="19"
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "48%",
                    }}
                  >
                    <p className="inputLabel">CVC</p>
                    <input
                      value={cvc}
                      onChange={(e) => {
                        setCvc(e.target.value);
                      }}
                      type="text"
                      placeholder="XXX"
                      className="login-input no-icon"
                      style={{ color: "#406c8c" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "48%",
                    }}
                  >
                    <p className="inputLabel">Expiration</p>
                    <DatePicker
                      showMonthYearPicker
                      selected={expDate}
                      onChange={(newDate) => setExpDate(newDate)}
                      placeholderText="04/2028"
                      dateFormat={"MM/yyyy"}
                      style={{
                        border: "2px solid red",
                      }}
                      id="card-exp-picker"
                    />
                  </div>
                </div>
              </div>
            )}

            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <AppButton
                onClick={props.onRequestClose}
                title="Back"
                disabled={purchaseLoading}
                outline={true}
                fontSize="12px"
                margin="0 10px 0 0"
                padding="5px 20px 5px 20px"
              />
              <AppButton
                onClick={purchaseLessons}
                loading={purchaseLoading}
                title="Purchase Lessons"
                fontSize="12px"
                margin="0 0 0 10px"
                padding="5px 20px 5px 20px"
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
