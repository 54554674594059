import {getShortDayString} from "../utils";

export function DayItem({isSelected, onDayPressed, day}) {
  const handleClick = (currentVal) => {
    onDayPressed(currentVal);
  };

  return (
    <button onClick={() => handleClick(isSelected)}>
      <div
        className="scheduler-weekday"
        style={{
          background: isSelected ? "#F8C28A" : "transparent",
        }}
      >
        <p>{getShortDayString(day)}</p>
      </div>
    </button>
  );
}