import { useState } from "react";
import { BsPersonCircle } from "react-icons/bs";
import { getShortDayString } from "../utils";
import { AppButton } from "./AppButton";
import { CreateInstructorModal } from "./modals/CreateInstructorModal";
import { InstructorCalendarModal } from "./modals/InstructorCalendarModal";

export function InstructorItem(props) {
  const instructor = props.instructor;
  const [editModalIsOpen, setEditModalOpen] = useState(false);
  const [calendarModalOpen, setCalendarModalOpen] = useState(false);
  const days = [0, 1, 2, 3, 4, 5, 6];

  const haveShiftsForDay = (day) => {
    let schedules = instructor.scheduleV2
    if (schedules.length === 0) {
      return false;
    }
    schedules.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());
    return schedules[0].shifts.filter(shift => shift.dayOfWeek === day).length > 0
  }

  return (
    <>
      <div
        style={{
          background: "white",
          padding: "20px 25px",
          boxShadow: "0px 8px 20px #00000014",
          borderRadius: "22px",
          width: "28vw",
          minWidth: "300px",
          margin: "10px 0",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
              }}
            >
              {!instructor?.profilePhoto ? (
                <BsPersonCircle size={90} style={{ color: "#DEDEDE" }} />
              ) : (
                <img
                  src={instructor.profilePhoto}
                  style={{
                    width: "90px",
                    height: "90px",
                    objectFit: "cover",
                    borderRadius: "100px",
                  }}
                  alt=""
                />
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "start",
                  marginLeft: "25px",
                }}
              >
                <p
                  style={{
                    padding: "0",
                    margin: "0",
                    color: "#171725",
                    fontSize: "22px",
                    fontWeight: "600",
                  }}
                >{`${instructor?.firstName ?? ""} ${
                  instructor?.lastName ?? ""
                }`}</p>
                <p
                  style={{
                    padding: "0",
                    margin: "0",
                    color: "#7E84A3",
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                >
                  Schedule:
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "5px",
                  }}
                >
                  {days.map(day =>
                    <InstructorDayItem
                      key={`idi_${day}`}
                      day={day}
                      enabled={haveShiftsForDay(day)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: "20px",
              marginBottom: "5px",
            }}
          >
            {instructor.minimumChildAge && (
              <p
                style={{
                  padding: "10px 10px 0px 0px",
                  marginRight: "10px",
                  color: "#cf4555",
                  fontSize: "12px",
                  fontWeight: "600",
                }}
              > Ages >= {instructor.minimumChildAge}</p>
            )}
            <AppButton
              onClick={() => {
                setEditModalOpen(true);
              }}
              outline={true}
              title="Edit Instructor"
              fontSize={"12px"}
              width="120px"
              margin="0 7px 0 7px"
              padding="7px 0 7px 0"
            />
            <AppButton
              onClick={() => {
                setCalendarModalOpen(true);
              }}
              title="View Calendar"
              fontSize={"12px"}
              width="120px"
              margin="0 7px 0 7px"
              padding="7px 0 7px 0"
            />
          </div>
        </div>
      </div>
      <CreateInstructorModal
        isOpen={editModalIsOpen}
        instructor={instructor}
        isEditing={true}
        onRequestClose={() => {
          setEditModalOpen(false);
        }}
        onInstructorUpdated={props.onInstructorUpdated}
        onInstructorDisabled={props.onInstructorDisabled}
      />
      <InstructorCalendarModal
        isOpen={calendarModalOpen}
        onRequestClose={() => {
          setCalendarModalOpen(false);
        }}
        instructorId={instructor._id}
      />
    </>
  );
}

export function InstructorDayItem({enabled, day}) {
  return (
    <div
      className="scheduler-weekday-sm"
      style={{
        background: enabled ? "#F8C28A" : "transparent",
      }}
    >
      <p>{getShortDayString(day)}</p>
    </div>
  );
}
