import Modal from "react-modal";
import { ImCross } from "react-icons/im";
import { useState } from "react";
import { AppButton } from "../AppButton";
import { useSnackbar } from "notistack";
import "react-datepicker/dist/react-datepicker.css";
import { isNil, reqHeaders } from "../../utils";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { FiTrash2 } from "react-icons/fi";
import Switch from "react-switch";
import DatePicker from "react-datepicker";

export function EditProductModal(props) {
  const product = props.product;
  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [numLessons, setNumLessons] = useState(product?.numLessons ?? 1);
  const [price, setPrice] = useState(
    ((product?.price ?? 2000) / 100).toFixed(0)
  );
  const [isLive, setIsLive] = useState(product?.live ?? true);
  const [expireDays, setExpireDays] = useState(product?.expireDays ?? 0);
  const [expireDate, setExpireDate] = useState((!!product?.expireDate) ? new Date(product.expireDate) : null);
  const { enqueueSnackbar } = useSnackbar();

  const isEditing = !isNil(product);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "20px",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      backdropFilter: "blur(2px)",
    },
  };

  const displayMessage = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
    });
  };

  async function editLessonGroup() {
    setSaveLoading(true);
    if (!contentChanged) {
      displayMessage(
        "Nothing has been changed. Edit the title, date or content.",
        "error"
      );
      setSaveLoading(false);
      return;
    }
    if (!numLessons || numLessons.length < 1) {
      displayMessage("The title can't be empty", "error");
      setSaveLoading(false);
      return;
    }
    if (!price || price < 1) {
      displayMessage("The price must be greater than 0", "error");
      setSaveLoading(false);
      return;
    }
    const req = await fetch(`${process.env.REACT_APP_API_URL}/products`, {
      method: "PUT",
      headers: reqHeaders,
      body: JSON.stringify({
        updatedProduct: {
          productId: product.productId,
          priceId: product.priceId,
          numLessons: numLessons,
          price: price * 100,
          live: isLive,
          expireDays: expireDays,
          expireDate: expireDate
        },
      }),
    });
    if (req.status === 200) {
      props.onProductUpdated();
      displayMessage("The product has been updated", "success");
      props.onRequestClose();
    } else {
      displayMessage("Something went wrong. Please try again.", "error");
    }
    setSaveLoading(false);
  }

  async function createLessonGroup() {
    setSaveLoading(true);
    if (!numLessons || numLessons.length < 1) {
      displayMessage("The title can't be empty", "error");
      setSaveLoading(false);
      return;
    }
    if (!price || price < 1) {
      displayMessage("The price must be greater than 0", "error");
      setSaveLoading(false);
      return;
    }
    const res = await fetch(`${process.env.REACT_APP_API_URL}/products`, {
      method: "POST",
      headers: reqHeaders,
      body: JSON.stringify({
        product: {
          numLessons: numLessons,
          price: price * 100,
          live: isLive,
          expireDays: expireDays
        },
      }),
    });
    if (res.status === 200) {
      props.onProductCreated();
      displayMessage("The product has been created", "success");
      props.onRequestClose();
    } else {
      displayMessage("Something went wrong. Please try again.", "error");
    }
    setSaveLoading(false);
  }

  async function archiveGroup() {
    setDeleteLoading(true);
    const req = await fetch(`${process.env.REACT_APP_API_URL}/products`, {
      method: "DELETE",
      headers: reqHeaders,
      body: JSON.stringify({
        productId: product.productId,
      }),
    });
    if (req.status === 200) {
      props.onProductArchived();
      displayMessage("The product has been removed", "success");
      props.onRequestClose();
    } else {
      displayMessage("Something went wrong. Please try again.", "error");
    }
    setDeleteLoading(false);
  }

  let contentChanged =
    product &&
    (numLessons !== product.numLessons ||
      price !== (product.price / 100).toFixed(0) ||
      isLive !== product.live ||
      expireDays !== product.expireDays
    );

  function decrementNumLessons() {
    const currentVal = parseInt(numLessons);
    if (currentVal - 1 < 1) {
      setNumLessons("1");
      return;
    }
    setNumLessons(currentVal - 1);
  }

  function incrementNumLessons() {
    const currentVal = parseInt(numLessons);
    setNumLessons(currentVal + 1);
  }

  function handlePriceChange(e) {
    try {
      setPrice(parseInt(e.target.value));
    } catch (err) {
      console.debug(err);
    }
  }

  function handleExpireDaysChange(e) {
    try {
      setExpireDays(parseInt(e.target.value));
      setExpireDate(null);
    } catch (err) {
      console.debug(err);
    }
  }

  function selectExpirationDate(d) {
    setExpireDays(0);
    setExpireDate(d);
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      style={customStyles}
      ariaHideApp={false}
      onAfterClose={props.onAfterClose}
    >
      <div
        style={{
          width: "30vw",
          minWidth: "400px",
          padding: "0px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignSelf: "end",
          }}
        >
          <button onClick={props.onRequestClose}>
            <ImCross size={12} style={{ color: "#B2AEAE" }} />
          </button>
        </div>

        <p
          style={{
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "600",
            marginTop: "-10px",
          }}
        >
          {isEditing ? "Edit" : "New"} Lesson Group
        </p>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignContent: "start",
            alignItems: "start",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              color: "#454545",
              padding: "0",
              margin: "0 0 2px 0",
            }}
          >
            Number of lessons
          </p>
          <div
            style={{
              width: "140px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              alignContent: "center",
              border: "2px solid #D7DBEC",
              padding: "7px 20px",
              borderRadius: "30px",
            }}
          >
            <button onClick={decrementNumLessons}>
              <TiArrowSortedDown size={20} style={{ color: "#45CDCF" }} />
            </button>
            <p
              style={{
                padding: "0",
                margin: "0",
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              {numLessons}
            </p>
            <button onClick={incrementNumLessons}>
              <TiArrowSortedUp size={20} style={{ color: "#45CDCF" }} />
            </button>
          </div>
          <p
            style={{
              fontSize: "12px",
              color: "#454545",
              padding: "0",
              margin: "15px 0 5px 0",
            }}
          >
            Expiration Days (0 for no expiration) or Select Expiration Date
          </p>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}>
            <div>
              <input
                value={expireDays}
                onChange={handleExpireDaysChange}
                type="number"
                min="0"
                max="1000"
                step="1"
                placeholder="0"
                className="login-input"
                id="expireDays"
              />
            </div>
            <div style={{width: "20px"}}> </div>
            <div>
              <DatePicker
                selected={expireDate}
                onChange={(newDate) => selectExpirationDate(newDate)}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignContent: "start",
              alignItems: "start",
              marginTop: "15px",
            }}
          >
            <p
              style={{
                fontSize: "12px",
                color: "#454545",
                padding: "0",
                margin: "0 0 2px 0",
              }}
            >
              Price
            </p>
            <input
              value={price}
              onChange={handlePriceChange}
              type="number"
              min="0"
              max="1000"
              step="1"
              placeholder="100"
              className="login-input"
              id="price"
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            marginTop: "25px",
          }}
        >
          <p
            style={{
              color: "#7E84A3",
              fontSize: "12px",
              margin: "0 10px 0 0",
              padding: "0",
            }}
          >
            Draft
          </p>
          <Switch
            onChange={() => setIsLive(!isLive)}
            checked={isLive}
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.3)"
            height={19}
            width={35}
            onHandleColor="#eb4034"
            offHandleColor="#F8C28A"
            onColor="#e3e3e3"
            offColor="#e3e3e3"
          />
          <p
            style={{
              color: "#7E84A3",
              fontSize: "12px",
              margin: "0 0 0 10px",
              padding: "0",
            }}
          >
            Live
          </p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: "30px",
          }}
        >
          <AppButton
            onClick={props.onRequestClose}
            outline={true}
            title="Cancel"
            fontSize={"12px"}
            width="120px"
            margin="0 7px 0 7px"
            padding="7px 0 7px 0"
          />
          <AppButton
            onClick={() => {
              if (isEditing) {
                editLessonGroup();
              } else {
                createLessonGroup();
              }
            }}
            title="Save"
            loading={saveLoading}
            disabled={deleteLoading}
            fontSize={"12px"}
            width="120px"
            margin="0 7px 0 7px"
            padding="7px 0 7px 0"
          />
        </div>
        {isEditing && (
          <button onClick={archiveGroup}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <FiTrash2
                size={16}
                style={{ color: "#F0142F", marginRight: "5px" }}
              />
              <p style={{ color: "#F0142F", fontSize: "12px" }}>Delete Group</p>
            </div>
          </button>
        )}
      </div>
    </Modal>
  );
}
