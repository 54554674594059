import { CircularProgress } from "@mui/material";
import {addDays, format} from "date-fns";

export function PurchaseHistory(props) {
  const { purchases, loading } = props;

  return !loading ? (
    <div>
      {purchases.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            boxShadow: "0px 3px 10px #15223224",
            borderRadius: "12px",
            background: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              background: "#F5F6FA",
              padding: "10px 5vw",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            <div style={{ width: "20%" }}>
              <p className="table-header">Lesson Type</p>
            </div>
            <div style={{ width: "20%" }}>
              <p className="table-header">Date</p>
            </div>
            <div style={{ width: "20%" }}>
              <p className="table-header">Time</p>
            </div>
            <div style={{ width: "20%" }}>
              <p className="table-header">Status</p>
            </div>
            <div style={{ width: "20%" }}>
              <p className="table-header">Expires On</p>
            </div>
          </div>
          {purchases.map((purchase, index) => (
            <div key={index}>
              <PurchaseHistoryItem purchase={purchase} />
            </div>
          ))}
        </div>
      )}
      {!purchases ||
        (purchases.length < 1 && (
          <div>
            <p>You haven't made any purchases yet</p>
          </div>
        ))}
    </div>
  ) : (
    <div style={{ textAlign: "center", margin: "50px 0" }}>
      <CircularProgress size={26} style={{ color: "#004367" }} />
    </div>
  );
}

function PurchaseHistoryItem(props) {
  const purchase = props.purchase;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        padding: "15px 5vw",
        background: "white",
        borderRadius: "12px",
      }}
      id={purchase.id}
    >
      <div style={{ width: "20%" }}>
        <p>
          {purchase.numLessons}{" "}
          {purchase.numLessons !== 1 ? "Lessons" : "Lesson"}
        </p>
      </div>
      <div style={{ width: "20%" }}>
        <p>{format(new Date(purchase.createdAt), "M/dd/yy")}</p>
      </div>
      <div style={{ width: "20%" }}>
        <p>{format(new Date(purchase.createdAt), "h:mm a")}</p>
      </div>
      <div style={{ width: "20%" }}>
        <p style={{ color: "#21D59B" }}>Completed</p>
      </div>
      <div style={{ width: "20%" }}>
        {purchase.expireDays > 0 &&
          <p style={{color: 'red'}}>{format(addDays(new Date(purchase.createdAt), purchase.expireDays), "M/dd/yy")}</p>
        }
        {purchase.expireDate &&
          <p style={{color: 'red'}}>{format(new Date(purchase.expireDate), "M/dd/yy")}</p>
        }
      </div>
    </div>
  );
}
