import {useEffect, useState} from "react";
import {reqHeaders} from "../../utils";
import DatePicker from "react-datepicker";
import {CircularProgress, Grid} from "@mui/material";
import ExpiringParentDetails from "./ExpiringParentDetails";
import {addDays, isAfter, isBefore} from "date-fns";
import {ExpiringParentModal} from "./ExpiringParentModal";

export default function ExpiringLessonReport() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(new Date(), 365));
  const [parents, setParents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedParent, setSelectedParent] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [totalBalance, setTotalBalance] = useState(0);
  const [totalUsersWithBalance, setTotalUsersWithBalance] = useState(0);

  useEffect( () => {
    if (startDate === null || endDate === null) {
      return;
    }

    async function fetchParents() {
      setLoading(true);
      try {
        const req = await fetch(
          `${
            process.env.REACT_APP_API_URL
          }/reports/expiringLessons?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
          {
            method: "GET",
            headers: reqHeaders,
          }
        );
        const res = await req.json();
        setParents(res.data);
        setTotalBalance(res.totalBalance);
        setTotalUsersWithBalance(res.totalUsersWithBalance);
      } catch (err) {
        console.debug("Error: ", err);
      }
      setLoading(false);
    }

    fetchParents();
  }, [startDate, endDate]);

  function totalLessonsExpiring() {
    let total = 0;
    for (const p of parents) {
      for (const l of p.expiringLessonBalances) {
        if (isAfter(new Date(l.expiresAt), startDate) && isBefore(new Date(l.expiresAt), endDate) && l.balance > 0) {
          total = total + l.balance;
        }
      }
    }
    return total;
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "center",
          padding: "30px 60px 0 60px",
          gap: "10px"
        }}
      >
        <h1 className="page-title">Expiring Lessons Report</h1>

        <div style={{flexGrow: 1}}></div>

        <div>
          <h2>Start:</h2>
        </div>
        <div>
          <DatePicker
            selected={startDate}
            onChange={(d) => setStartDate(d)}
          />
        </div>

        <div>
          <h2>End:</h2>
        </div>
        <div>
          <DatePicker
            selected={endDate}
            onChange={(d) => setEndDate(d)}
          />
        </div>
      </div>

      {loading && (
        <div style={{ textAlign: "center", margin: "50px 0" }}>
          <CircularProgress size={26} style={{ color: "#004367" }} />
        </div>
      )}

      {!loading && (
        <div style={{
          padding: "30px 60px 0 60px",
        }}>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
            gap: "30px",
            marginBottom: "20px"
          }}>
            <div><h3>Total Users Found: <b>{parents.length}</b></h3></div>
            <div><h3>Total Lessons Expiring: <b>{totalLessonsExpiring()}</b></h3></div>
            <div><h3>Total Balances ({totalUsersWithBalance} users): <b>{totalBalance}</b></h3></div>
          </div>

          <Grid
            container
            style={{ justifyContent: "start" }}
            columnSpacing={"30px"}
          >
            {parents.map((p) => (
              <Grid item key={p._id} style={{cursor: 'pointer'}} onClick={() => {
                setSelectedParent(p);
                setModalIsOpen(true);
              }}>
                <div style={{
                  background: "white",
                  padding: "15px 25px",
                  boxShadow: "0px 8px 20px #00000014",
                  borderRadius: "10px",
                  width: "20vw",
                  minWidth: "150px",
                  margin: "10px 0",
                }}>
                  <p>{p.lastName}, {p.firstName}</p>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      )}

      <ExpiringParentModal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
      >
        <ExpiringParentDetails parent={selectedParent} />
      </ExpiringParentModal>

    </div>
  );
}