import Modal from "react-modal";
import { AdminCalendar } from "../../pages/AdminCalendar";

export function InstructorCalendarModal(props) {
  const { isOpen, onRequestClose, instructorId } = props;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "20px",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      backdropFilter: "blur(2px)",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      ariaHideApp={false}
    >
      <div style={{ width: "70vw", minWidth: "400px", padding: "20px" }}>
        <AdminCalendar instructorId={instructorId} />
      </div>
    </Modal>
  );
}
