import { format } from "date-fns";
import { useState } from "react";
import { BiPencil } from "react-icons/bi";
import { BsPersonCircle } from "react-icons/bs";
import { getAge, studentInformationComplete } from "../utils";
import { CreateStudentModal } from "./modals/CreateStudentModal";
import { StudentInformationModal } from "./modals/StudentInformationModal";
import { WaiverModal } from "./modals/WaiverModal";
import { Tag } from "./Tag";

export function MyStudentItem(props) {
  const student = props.student;
  // new Date(student?.birthdate)
  const birthdate = new Date(student?.birthdate);

  const [editStudentModalIsOpen, setEditStudentModalOpen] = useState(false);
  const [studentInfoModalIsOpen, setStudentInfoModalOpen] = useState(false);
  const [waiverModalIsOpen, setWaiverModalOpen] = useState(false);

  return (
    <>
      <div
        style={{
          width: "25vw",
          minWidth: "420px",
          maxWidth: "600px",
          padding: "10px",
          boxShadow: "0px 8px 20px #0000000D",
          background: "white",
          borderRadius: 21,
          marginBottom: 20,
        }}
      >
        <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
          <BiPencil
            onClick={() => {
              setEditStudentModalOpen(true);
            }}
            size={28}
            style={{
              color: "#D7DBEC",
              cursor: "pointer",
              zIndex: 0,
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "-30px",
            padding: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            {!student?.profilePhoto ? (
              <BsPersonCircle
                size={80}
                style={{ color: "#DEDEDE", marginRight: "30px" }}
              />
            ) : (
              <img
                src={student.profilePhoto}
                style={{
                  width: "80px",
                  height: "80px",
                  objectFit: "cover",
                  borderRadius: "100px",
                  marginRight: "30px",
                }}
                alt=""
              />
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignContent: "start",
                alignItems: "start",
              }}
            >
              <p
                style={{
                  fontSize: "22px",
                  fontWeight: "600",
                  margin: "0 0 5px 0",
                  padding: "0",
                }}
              >
                {`${student?.firstName ?? ""} ${student?.lastName[0] ?? ""}`}
                {". "}
                <span style={{ fontSize: "12px", fontWeight: "400" }}>
                  (Age: {getAge(birthdate)})
                </span>
              </p>
              <p
                style={{
                  color: "#7E84A3",
                  fontSize: "12px",
                  margin: "0 0 5px 0",
                }}
              >
                <span style={{ fontWeight: "600" }}>Birthday:</span>{" "}
                {format(birthdate, "MMMM dd, yyyy")}
              </p>
              <p style={{ color: "#7E84A3", fontSize: "12px", margin: "0" }}>
                <span style={{ fontWeight: "600" }}>
                  {student?.lessonsTaken ?? "0"}
                </span>{" "}
                Lesson
                {student?.lessonsTaken !== 1 ? "s" : ""} Taken
              </p>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              background: "#E3E3E3",
              height: "1px",
              margin: "25px 0",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            <p
              style={{
                textAlign: "start",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              Paperwork
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "0 20px",
              }}
            >
              <p style={{ fontSize: "14px", color: "#7E84A3" }}>
                Permission Liability Waiver
              </p>
              <button onClick={() => setWaiverModalOpen(true)}>
                {student?.waiverSignedBy ? (
                  <Tag
                    label="Complete"
                    fontSize="12px"
                    width="150px"
                    background="#21D59B"
                  />
                ) : (
                  <Tag label="Incomplete" fontSize="12px" width="150px" />
                )}
              </button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "10px",
                padding: "0 20px",
              }}
            >
              <p style={{ fontSize: "14px", color: "#7E84A3" }}>
                Student Information
              </p>
              <button onClick={() => setStudentInfoModalOpen(true)}>
                {studentInformationComplete(student) ? (
                  <Tag
                    label="Complete"
                    fontSize="12px"
                    width="150px"
                    background="#21D59B"
                  />
                ) : (
                  <Tag label="Incomplete" fontSize="12px" width="150px" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <CreateStudentModal
        isOpen={editStudentModalIsOpen}
        student={student}
        isEditing={true}
        onRequestClose={() => {
          setEditStudentModalOpen(false);
        }}
        onStudentUpdated={props.onStudentUpdated}
      />
      <StudentInformationModal
        isOpen={studentInfoModalIsOpen}
        student={student}
        onRequestClose={() => {
          setStudentInfoModalOpen(false);
        }}
        onStudentUpdated={props.onStudentUpdated}
      />
      <WaiverModal
        isOpen={waiverModalIsOpen}
        student={student}
        onRequestClose={() => {
          setWaiverModalOpen(false);
        }}
        onStudentUpdated={props.onStudentUpdated}
      />
    </>
  );
}
