import {useContext} from "react";
import {AppContext} from "../pages/AppContainer";
import {isAfter} from "date-fns";
import {getUtcDate} from "../utils";

export default function TotalLessonsRemaining(props) {
  let { currentUser } = useContext(AppContext);

  if (props.user) {
    currentUser = props.user
  }

  function getTotalLessonsRemaining() {
    let total = currentUser?.lessonBalance ?? 0;

    let nonExpiredBalance = currentUser?.expiringLessonBalances
      .filter(expBal => isAfter(getUtcDate(expBal.expiresAt), new Date()))
      .reduce((acc, val) => acc + val.balance, 0) ?? 0;

    return total + nonExpiredBalance;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
        background: "#45CDCF",
        borderRadius: "45px",
        height: "45px",
        boxShadow: "0px 8px 20px #0000001A",
        marginRight: 10,
      }}
    >
      <div
        style={{
          borderRadius: "45px",
          background: "white",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          height: "45px",
          width: "45px",
        }}
      >
        <p
          style={{
            padding: "0",
            margin: "0",
            color: "#45CDCF",
            fontWeight: "600",
            fontSize: "24px",
            marginTop: "3px",
          }}
        >
          {getTotalLessonsRemaining()}
        </p>
      </div>
      <p
        style={{
          padding: "0 20px 0 10px",
          color: "white",
          fontWeight: "500",
        }}
      >
        {'Total '} {currentUser.lessonBalance !== 1 ? "Lessons" : "Lesson"}{" remaining"}
      </p>
    </div>
  );
}