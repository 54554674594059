import { FiPlusCircle, FiSettings } from "react-icons/fi";
import { MdOutlineAttachMoney } from "react-icons/md";
import { BiGroup } from "react-icons/bi";
import { RecentAnnouncements } from "../components/RecentAnnouncements";
import { DashboardButton } from "./AdminDashboard";
import { useWindowSize } from "../hooks/windowSize";
import TotalLessonsRemaining from "../components/TotalLessonsRemaining";
import ExpiringLessonsRemaining from "../components/ExpiringLessonsRemaining";

export default function Dashboard(props) {
  const size = useWindowSize();

  return (
    <div
      className="row"
      style={{
        width: "100%",
        height: size?.height ? size.height - 85 : "50vh",
        overflow: "auto",
      }}
    >
      <div
        style={{
          dislay: "flex",
          flexDirection: "column",
          paddingLeft: "50px",
          paddingRight: 50,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "30px 0px 0 0px",
          }}
        >
          <h1 className="page-title">Dashboard</h1>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "end",
            }}
          >
            <TotalLessonsRemaining />
            <div style={{marginLeft: "10px"}}>
              <ExpiringLessonsRemaining />
            </div>
          </div>
        </div>
        <div style={{ paddingBottom: 60 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "30px",
              width: "100%",
            }}
          >
            <DashboardButton
              text="Book a Lesson"
              icon={<FiPlusCircle size={"4vw"} style={{ color: "#F8C28A" }} />}
              onPress={() => {
                props.changeTab(0);
              }}
            />
            <DashboardButton
              text="My Purchases"
              onPress={() => {
                props.changeTab(5);
              }}
              icon={
                <MdOutlineAttachMoney
                  size={"4vw"}
                  style={{ color: "#F8C28A" }}
                />
              }
            />
            <DashboardButton
              text="View Students"
              icon={<BiGroup size={"4vw"} style={{ color: "#F8C28A" }} />}
              onPress={() => {
                props.changeTab(4);
              }}
            />
            <DashboardButton
              text="Account Settings"
              icon={<FiSettings size={"4vw"} style={{ color: "#F8C28A" }} />}
              onPress={() => {
                props.changeTab(6);
              }}
            />
          </div>
          <RecentAnnouncements onReadMoreClicked={() => props.changeTab(3)} />
        </div>
      </div>
    </div>
  );
}
