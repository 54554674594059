import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useWindowSize } from "../hooks/windowSize";
import { InstructorItem } from "../components/InstructorItem";
import PaginationControl from "../components/PaginationControl";
import { AppButton } from "../components/AppButton";
import { CreateInstructorModal } from "../components/modals/CreateInstructorModal";

export function Instructors(props) {
  const [loading, setLoading] = useState(false);
  const [instructors, setInstructors] = useState([]);
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchInstructors();
    // eslint-disable-next-line
  }, []);

  async function fetchInstructors(newCurrentPage) {
    setLoading(true);
    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/users/instructors?page=${newCurrentPage ?? 0}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );
    const res = await req.json();
    setTotalPages(res.pageCount);
    // Order by last name
    if (res.users) {
      res.users.sort(function (a, b) {
        if (a.lastName?.toLowerCase() < b.lastName?.toLowerCase()) return -1;
        if (a.lastName?.toLowerCase() > b.lastName?.toLowerCase()) return 1;
        return 0;
      });
      fixSchedule(res.users);
      setInstructors(res.users);
    }
    setLoading(false);
  }

  function fixSchedule(users) {
    for (const user of users) {
      // copy old schedule if it still exists and new one isn't set yet
      if (user.scheduleV2.length === 0 && !!user.schedule) {
        let newShifts = [];
        newShifts.push(...user.schedule.sunShifts.map(s => ({dayOfWeek: 0, startMinute: s.startMinute, endMinute: s.endMinute})));
        newShifts.push(...user.schedule.monShifts.map(s => ({dayOfWeek: 1, startMinute: s.startMinute, endMinute: s.endMinute})));
        newShifts.push(...user.schedule.tueShifts.map(s => ({dayOfWeek: 2, startMinute: s.startMinute, endMinute: s.endMinute})));
        newShifts.push(...user.schedule.wedShifts.map(s => ({dayOfWeek: 3, startMinute: s.startMinute, endMinute: s.endMinute})));
        newShifts.push(...user.schedule.thuShifts.map(s => ({dayOfWeek: 4, startMinute: s.startMinute, endMinute: s.endMinute})));
        newShifts.push(...user.schedule.friShifts.map(s => ({dayOfWeek: 5, startMinute: s.startMinute, endMinute: s.endMinute})));
        newShifts.push(...user.schedule.satShifts.map(s => ({dayOfWeek: 6, startMinute: s.startMinute, endMinute: s.endMinute})));
        if (newShifts.length > 0) {
          user.scheduleV2.push({
            startDate: user.schedule.startDate,
            endDate: user.schedule.endDate,
            daysOff: [],
            shifts: newShifts
          });
        }
      }

      for (const schedule of user.scheduleV2) {
        schedule.startDate = new Date(schedule.startDate);
        schedule.endDate = new Date(schedule.endDate);
        schedule.daysOff = schedule.daysOff.map(day => new Date(day));
      }
    }
  }

  function previousPressed() {
    const newCurrentPage = currentPage - 1;
    setCurrentPage(newCurrentPage);
    fetchInstructors(newCurrentPage);
  }

  function nextPressed() {
    const newCurrentPage = currentPage + 1;
    setCurrentPage(newCurrentPage);
    fetchInstructors(newCurrentPage);
  }

  const size = useWindowSize();

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "30px 60px 0 60px",
        }}
      >
        <h1 className="page-title">Instructors</h1>
        <AppButton
          onClick={() => setCreateModalIsOpen(true)}
          title="New Instructor"
          showPlus={true}
          fontSize={"14px"}
          plusSize={"18px"}
          padding="0px 14px"
        />
      </div>

      <div
        style={{
          height: size?.height ? size.height - 200 : "50vh",
          overflow: "auto",
          padding: "30px 60px 30px 60px",
        }}
      >
        {instructors?.length > 0 ? (
          <Grid
            container
            style={{
              justifyContent: "start",
            }}
            columnSpacing={"30px"}
          >
            {!loading &&
              instructors &&
              instructors.map((instructor) => (
                <Grid item key={instructor._id}>
                  <InstructorItem
                    instructor={instructor}
                    onInstructorUpdated={fetchInstructors}
                    onInstructorDisabled={fetchInstructors}
                    onViewCalendarPressed={props.onViewCalendarPressed}
                  />
                </Grid>
              ))}
          </Grid>
        ) : (
          <p>No instructors</p>
        )}
        <PaginationControl
          totalPages={totalPages}
          currentPage={currentPage}
          onPreviousPressed={previousPressed}
          onNextPressed={nextPressed}
          addTopMargin={true}
        />
      </div>
      <CreateInstructorModal
        isOpen={createModalIsOpen}
        onRequestClose={() => {
          setCreateModalIsOpen(false);
        }}
        onInstructorCreated={() => {
          fetchInstructors();
        }}
        onInstructorUpdated={() => {
          fetchInstructors();
        }}
        onAfterClose={() => {}}
      />
    </div>
  );
}
