/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useWindowSize } from "../hooks/windowSize";
import { reqHeaders } from "../utils";
import { AppButton } from "../components/AppButton";
import { AppContext } from "./AppContainer";
import { MyStudentItem } from "../components/MyStudentItem";
import { CreateStudentModal } from "../components/modals/CreateStudentModal";
import TotalLessonsRemaining from "../components/TotalLessonsRemaining";
import ExpiringLessonsRemaining from "../components/ExpiringLessonsRemaining";

export function MyStudents(props) {
  const { currentUser } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);

  useEffect(() => {
    fetchMyStudents();
  }, []);

  async function fetchMyStudents() {
    setLoading(true);
    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/students/by-parent/${currentUser._id}`,
      {
        method: "GET",
        headers: reqHeaders,
      }
    );
    const res = await req.json();
    // Order by last name
    if (res.data) {
      res.data.sort(function (a, b) {
        if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) return -1;
        if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) return 1;
        return 0;
      });
      setStudents(res.data);
    }
    setLoading(false);
  }

  const size = useWindowSize();

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "30px 60px 0 60px",
        }}
      >
        <h1 className="page-title">Students</h1>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TotalLessonsRemaining />
          <div style={{marginLeft: "10px", marginRight: "20px"}}>
            <ExpiringLessonsRemaining />
          </div>

          <AppButton
            onClick={() => setCreateModalIsOpen(true)}
            title="New Student"
            showPlus={true}
            fontSize={"14px"}
            plusSize={"18px"}
            padding="21px 14px"
            margin={"0 10px 0 0"}
          />
        </div>
      </div>

      <div
        style={{
          height: size?.height ? size.height - 200 : "50vh",
          overflow: "auto",
          padding: "30px 60px 30px 60px",
        }}
      >
        {students?.length > 0 ? (
          <Grid
            container
            style={{
              justifyContent: "start",
            }}
            columnSpacing={"30px"}
          >
            {!loading &&
              students &&
              students.map((student) => (
                <Grid item key={student._id}>
                  <MyStudentItem
                    student={student}
                    onStudentUpdated={fetchMyStudents}
                  />
                </Grid>
              ))}
          </Grid>
        ) : (
          <p>You don't have any students</p>
        )}
      </div>
      <CreateStudentModal
        isOpen={createModalIsOpen}
        onRequestClose={() => {
          setCreateModalIsOpen(false);
        }}
        onStudentCreated={() => {
          fetchMyStudents();
        }}
        onAfterClose={() => {}}
      />
    </div>
  );
}
