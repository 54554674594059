export default function PaginationControl(props) {
  const previousButtonDisabled = (totalPages, currentPage) => {
    if (!totalPages || totalPages < 2 || currentPage === 0) {
      return true;
    }
    return false;
  };

  const nextButtonDisabled = (totalPages, currentPage) => {
    if (!totalPages || currentPage + 1 >= totalPages) {
      return true;
    }
    return false;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "end",
        alignContent: "center",
        alignItems: "center",
        margin: props.addTopMargin ? "40px 0px 0px 0px" : "0px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <button
          disabled={previousButtonDisabled(props.totalPages, props.currentPage)}
          onClick={props.onPreviousPressed}
          style={{
            background: "white",
            padding: "10px 20px",
            border: "1px solid #D8DBEB",
            borderRadius: "7px 0px 0px 7px",
          }}
        >
          <p
            style={{
              color: previousButtonDisabled(props.totalPages, props.currentPage)
                ? "#b1b1b1"
                : "black",
            }}
          >
            Previous
          </p>
        </button>
        <button
          onClick={props.onNextPressed}
          disabled={nextButtonDisabled(props.totalPages, props.currentPage)}
          style={{
            background: "white",
            padding: "10px 20px",
            border: "1px solid #D8DBEB",
            borderLeft: "none",
            borderRadius: "0px 7px 7px 0px",
          }}
        >
          <p
            style={{
              color: nextButtonDisabled(props.totalPages, props.currentPage)
                ? "#b1b1b1"
                : "black",
            }}
          >
            Next
          </p>
        </button>
      </div>
    </div>
  );
}
