import {CiEdit, TiDeleteOutline} from "react-icons/all";
import {format} from "date-fns";
import {getTimeStringFromSeconds, getVeryShortDayString} from "../utils";
import {Popover} from "@idui/react-popover";

export default function ScheduleItem({schedule, removeSchedule, editSchedule}) {
  const days = [0, 1, 2, 3, 4, 5, 6];

  const haveShiftsForDay = (day) => {
    return schedule.shifts.filter(shift => shift.dayOfWeek === day).length > 0
  }

  const displayShiftDays = () => {
    return days.map(day =>
      <ScheduleItemDayItem key={day} day={day} enabled={haveShiftsForDay(day)} popoverContent={shiftPopoverContent(day)} />
    )
  }

  const daysOffPopoverContent = () => {
    return schedule.daysOff.map(day => format(day, "MM/dd/yy")).join(", ");
  }

  const shiftPopoverContent = (day) => {
    let shifts = schedule.shifts.filter(shift => shift.dayOfWeek === day)
    return shifts.map(shift => `${getTimeStringFromSeconds(shift.startMinute)} - ${getTimeStringFromSeconds(shift.endMinute)}`).join(", ")
  }

  return (
    <div className='row' style={{ justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'center', marginTop: '10px' }}>
      <div style={{fontWeight: 'bold'}}>{format(schedule.startDate, "MM/dd/yy")} - {format(schedule.endDate, "MM/dd/yy")}</div>

      {schedule.daysOff.length > 0 ?
        <Popover content={daysOffPopoverContent()}>
          <div><span style={{fontWeight: 'bold'}}>Off: </span>{schedule.daysOff.length}</div>
        </Popover>
        :
        <div><span style={{fontWeight: 'bold'}}>Off: </span>{schedule.daysOff.length}</div>
      }

      <div className='row'>{displayShiftDays()}</div>
      <div>
        <button onClick={() => editSchedule(schedule)}>
          <CiEdit size={24} color='black' />
        </button>
        <button onClick={() => removeSchedule(schedule)}>
          <TiDeleteOutline size={24} color='red' />
        </button>
      </div>
    </div>
  );
}
function ScheduleItemDayItem({enabled, day, popoverContent}) {
  const content = (
    <div
      className="scheduler-weekday-sm"
      style={{
        background: enabled ? "#F8C28A" : "transparent",
        fontSize: '8px',
        width: '25px',
        height: '25px',
        margin: '1px',
        fontWeight: 'bold'
      }}
      title={popoverContent}
    >
      <p>{getVeryShortDayString(day)}</p>
    </div>
  );

  if (enabled) {
    return (
      <Popover content={popoverContent} >
        {content}
      </Popover>
    )
  }

  return content;
}