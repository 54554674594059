import { useSnackbar } from "notistack";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { AppButton } from "../components/AppButton";
import { reqHeaders } from "../utils";

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const displayMessage = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
    });
  };

  async function sendResetLink() {
    if (!email) {
      return displayMessage("Must provide email", "error");
    }
    setLoading(true);
    await fetch(`${process.env.REACT_APP_API_URL}/forgot-password`, {
      method: "POST",
      headers: reqHeaders,
      body: JSON.stringify({
        email: email,
      }),
    });
    displayMessage(
      "Check your email for a link to reset your password. It may take a few minutes to appear in your inbox."
    );
    setLoading(false);
  }

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div
        style={{
          width: "60vw",
          alignItems: "center",
          alignContent: "center",
          textAlign: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
            marginTop: "20px",
            padding: "0px 50px",
          }}
        >
          <img
            src="/images/logo.png"
            style={{ width: "80px" }}
            alt="MWS Logo"
          />
          <h2
            style={{
              color: "#454545",
              letterSpacing: "0.1px",
              fontSize: "14px",
              cursor: "pointer",
            }}
            onClick={() => history.push("/")}
          >
            Home
          </h2>
        </div>
        <div style={{ marginTop: "20vh" }}>
          <div className="formContainer">
            <h3
              style={{
                textAlign: "start",
                fontSize: "36px",
                marginBottom: "20px",
              }}
            >
              Forgot Password
            </h3>
            <p className="inputLabel">Email</p>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Email"
              className="login-input"
              id="email"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "30px",
              }}
            >
              <AppButton
                loading={loading}
                onClick={sendResetLink}
                title="Send Reset Link"
                width="50%"
                fontSize="14px"
                margin="0 0 0 10px"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "40vw",
          height: "100vh",
          backgroundImage: "url('/images/pool.png')",
        }}
      ></div>
    </div>
  );
}
