import { BiPencil} from "react-icons/bi";
import { HiChevronRight } from "react-icons/hi";
import { format } from "date-fns";
import { useContext } from "react";
import { AppContext } from "../pages/AppContainer";
import {GrAttachment} from "react-icons/all";

export function AnnouncementItem(props) {
  const { currentUser } = useContext(AppContext);
  const updatedAt = new Date(props.announcement.updatedAt);

  return (
    <div
      style={{
        background: "white",
        padding: "20px",
        boxShadow: "0px 8px 20px #00000014",
        borderRadius: "23px",
        width: "23vw",
        minWidth: "300px",
        height: "250px",
        margin: "10px 0",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
        }}
      >
        {currentUser?._id === props.announcement?.createdBy ? (
          <button onClick={props.onClickEdit}>
            <BiPencil size={20} style={{ color: "#D7DBEC" }} />
          </button>
        ) : (
          <div style={{ height: "20px" }}></div>
        )}
      </div>
      <p
        style={{
          fontSize: "16px",
          fontWeight: "700",
          padding: "0",
          margin: "0",
          marginBottom: "5px",
          marginTop: "-10px",
        }}
      >
        {props.announcement.title}
        {props.announcement.attachments.length > 0 &&
          <span>{'    '} <GrAttachment size={15} /></span>
        }
      </p>
      <p
        style={{
          color: "#45CDCF",
          fontSize: "13px",
          padding: "0",
          marginBottom: "20px",
        }}
      >
        {format(updatedAt, "MMM dd, yyyy")}
      </p>
      <div style={{ height: "100px", overflow: "hidden" }}>
        <p
          style={{
            marginTop: "20px",
            marginBottom: "10px",
            padding: "0",
            margin: "0",
            wordBreak: "break-word",
          }}
        >
          {props.announcement.content}
        </p>
      </div>
      <button
        style={{ padding: "0", margin: "0" }}
        onClick={props.onClickReadMore}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <p
            style={{
              padding: "0",
              fontSize: "12px",
              color: "#406C8C",
              margin: "0",
            }}
          >
            Read More
          </p>
          <HiChevronRight size={14} style={{ color: "#406C8C" }} />
        </div>
      </button>
    </div>
  );
}
