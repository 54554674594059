/* eslint-disable react-hooks/exhaustive-deps */
import Modal from "react-modal";
import { ImCross } from "react-icons/im";
import { useContext, useEffect, useState } from "react";
import { AppButton } from "../AppButton";
import { useSnackbar } from "notistack";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ChooseParentPanel } from "./ChooseParentPanel";
import SlidingPane from "react-sliding-pane";
import {
  deformatPhoneNumber,
  formatPhoneNumber,
  reqHeaders,
} from "../../utils";
import { AppContext } from "../../pages/AppContainer";
import { Tag } from "../Tag";
import "semantic-ui-css/semantic.min.css";
import { Button } from "@mui/material";
import { FiCheck } from "react-icons/fi";

export function WaiverModal(props) {
  const { currentUser } = useContext(AppContext);
  const { fetchParents } = useContext(AppContext);
  const student = props.student ?? null;

  const [saveLoading, setSaveLoading] = useState(false);
  const [chooseParentPanelOpen, setChooseParentPanelOpen] = useState(false);
  const [selectedParent, setSelectedParent] = useState();
  const [name, setName] = useState(getInitialName());
  const [parentPhone, setParentPhone] = useState(getInitialParentPhone());
  const [parentName, setParentName] = useState("");
  const [studentInformationSaveDate, setStudentInformationSaveDate] = useState(
    getInitialDate()
  );
  const [pictureAndNameGranted, setPhotoAndNameGranted] = useState(
    student?.pictureAndNameGranted
  );
  const [pictureNoNameGranted, setPhotoNoNameGranted] = useState(
    student?.pictureNoNameGranted
  );
  const [noPermissionGranted, setNoPermissionGranted] = useState(
    student?.noPermissionGranted
  );

  const { enqueueSnackbar } = useSnackbar();

  const currentUserIsParent = student?.parentUserId === currentUser._id;

  const noPermissionBoxesChecked =
    !noPermissionGranted && !pictureAndNameGranted && !pictureNoNameGranted;

  useEffect(() => {
    if (!currentUserIsParent) {
      fetchAndSetParent(student?.parentUserId);
    }
  }, []);

  function getInitialName() {
    if (student?.firstName && student?.lastName) {
      return `${student?.firstName} ${student?.lastName}`;
    }
    return null;
  }

  function getInitialDate() {
    if (student?.studentInformationSaveDate) {
      return new Date(student?.studentInformationSaveDate);
    }
    return new Date();
  }

  function getInitialParentPhone() {
    if (student?.parentPhone) {
      return formatPhoneNumber(
        deformatPhoneNumber(student.parentPhone?.substring(2) ?? "")
      );
    }
    return "";
  }

  async function updateStudent() {
    if (
      !noPermissionGranted &&
      !pictureNoNameGranted &&
      !pictureAndNameGranted
    ) {
      setSaveLoading(false);
      displayMessage("Please check one of the permission boxes", "error");
      return;
    }

    if (currentUser.role < 2 && (!parentName || parentName.length < 1) && !student.waiverSignedBy) {
      setSaveLoading(false);
      displayMessage("Please sign the form below", "error");
      return;
    }

    try {
      const updatedStudent = {
        updatedStudent: {
          id: student._id,
          pictureAndNameGranted: pictureAndNameGranted,
          pictureNoNameGranted: pictureNoNameGranted,
          noPermissionGranted: noPermissionGranted,
          parentPhone: deformatPhoneNumber(parentPhone),
          waiverSignedBy: currentUserIsParent
            ? currentUser._id
            : selectedParent._id,
        },
      };
      const req = await fetch(`${process.env.REACT_APP_API_URL}/students`, {
        method: "PUT",
        headers: reqHeaders,
        body: JSON.stringify(updatedStudent),
      });

      if (req.status === 200) {
        props.onStudentUpdated();
        displayMessage("The student has been updated", "success");
        props.onRequestClose();
      } else {
        displayMessage("Something went wrong. Please try again.", "error");
      }
    } catch (err) {
      console.debug(err);
      displayMessage("Something went wrong. Please try again.", "error");
    }
    setSaveLoading(false);
  }

  const displayMessage = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
    });
  };

  function handleCheck(val) {
    if (val === 0) {
      setPhotoAndNameGranted(true);
      setPhotoNoNameGranted(false);
      setNoPermissionGranted(false);
    } else if (val === 1) {
      setPhotoAndNameGranted(false);
      setPhotoNoNameGranted(true);
      setNoPermissionGranted(false);
    }
    if (val === 2) {
      setPhotoAndNameGranted(false);
      setPhotoNoNameGranted(false);
      setNoPermissionGranted(true);
    }
  }

  async function fetchAndSetParent(parentId) {
    if (parentId) {
      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/users/parent?userId=${parentId}`,
        {
          method: "GET",
          headers: reqHeaders,
        }
      );
      const res = await req.json();
      setSelectedParent(res.user);
    }
  }

  const modalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "20px",
      maxHeight: "500px",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      backdropFilter: "blur(2px)",
    },
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      style={modalStyles}
      ariaHideApp={false}
      onAfterClose={props.onAfterClose}
    >
      <div style={{ width: "50vw", minWidth: "400px", padding: "10px" }}>
        <div style={{ textAlign: "end" }}>
          <button onClick={props.onRequestClose}>
            <ImCross size={12} style={{ color: "#B2AEAE" }} />
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <p style={{ fontSize: "20px", fontWeight: "600" }}>
            Permission Liability Waiver
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ margin: "0 10px 0 0", padding: "0", fontSize: "16px" }}>
              Status:{" "}
            </p>
            {!student.waiverSignedBy ? (
              <Tag label="Incomplete" fontSize="12px" width="140px" />
            ) : (
              <Tag
                label="Complete"
                fontSize="12px"
                width="140px"
                background="#21D59B"
              />
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            // padding: "20px 15% 40px 15%",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: "73%" }}>
              <p className="edit-label" style={{ marginTop: "15px" }}>
                Student's Name
              </p>
              <div style={{}}>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  placeholder="Type Name"
                  className="login-input"
                  style={{ width: "100%" }}
                  id="first-name"
                  disabled={props.isInstructor}
                />
              </div>
            </div>
            <div style={{ width: "2%" }}></div>
            <div style={{ width: "25%" }}>
              <p className="edit-label" style={{ marginTop: "15px" }}>
                Today's Date
              </p>
              <DatePicker
                selected={studentInformationSaveDate}
                placeholderText="xx/xx/xxxx"
                className="calendar-grey"
                onChange={(newDate) => setStudentInformationSaveDate(newDate)}
                style={{
                  border: "2px solid red",
                  width: "inherit",
                  padding: "9px 30px",
                }}
                disabled={props.isInstructor}
              />
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <p className="edit-label" style={{ marginTop: "15px" }}>
              Parent Phone Number
            </p>
            <div style={{}}>
              <input
                value={parentPhone}
                onChange={(e) => {
                  const formattedPhoneNumber = formatPhoneNumber(
                    e.target.value
                  );
                  setParentPhone(formattedPhoneNumber);
                }}
                type="phone"
                placeholder="Phone"
                className="login-input"
                id="phone"
                style={{ color: "#406c8c", width: "300px" }}
              />
            </div>
          </div>

          {!currentUserIsParent && currentUser.role > 1 && (
            <>
              <p className="edit-label" style={{ marginTop: "15px" }}>
                Parent
              </p>
              {!selectedParent ? (
                <button
                  onClick={() => {
                    if (!props.isInstructor) setChooseParentPanelOpen(true);
                  }}
                  style={{ textAlign: "start" }}
                >
                  <p style={{ marginTop: "0px", color: "#406C8C" }}>
                    Select a parent
                  </p>
                </button>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignContent: "center",
                  }}
                >
                  <p style={{ margin: "0", padding: "0" }}>
                    {selectedParent.firstName} {selectedParent.lastName}{" "}
                    <span style={{ fontSize: "12px" }}>
                      ({selectedParent.email}
                      {selectedParent.phone
                        ? ` - ${selectedParent.phone})`
                        : ")"}
                    </span>
                  </p>
                  <button
                    onClick={() => setChooseParentPanelOpen(true)}
                    style={{ textAlign: "start", marginLeft: "20px" }}
                  >
                    <p
                      style={{
                        marginTop: "0px",
                        color: "#406C8C",
                        fontSize: "12px",
                      }}
                    >
                      Select a different parent
                    </p>
                  </button>
                </div>
              )}
            </>
          )}
        </div>
        <div style={{ marginTop: "20px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <p className="waiver-text">
              Notice of Risk / Acknowledgement of Possibility of Injury
            </p>
            <p className="waiver-text waiver-par">
              We, the staff of, owners and representatives of Mountain Wave
              Swimming recognize our obligation to make our participants and
              their parents aware of the risks and hazards associated with the
              following activities: Swim Lessons. Children/Minors may suffer
              injuries, possibly minor, serious, catastrophic, or fatal in
              nature. Parents should make their children aware of the
              possibility of injury and encourage their children to follow all
              the safety rules and instructions. Mountain Wave Swimming and its
              staff members will not accept responsibility for injuries
              sustained by any child/minor during the course of activities at
              Mountain Wave Swimming facilities. By signing this form, I
              acknowledge these risks of injury, and I agree that Mountain Wave
              Swimming, its staff, owners, and representatives will not be
              responsible for any such injuries.
            </p>
            <p className="waiver-text">Release and Waiver of Liability</p>
            <p className="waiver-text waiver-par">
              With the above in mind and being fully aware of the risks and
              possibility of injury involved, I consent to have my minor/child
              participate in the programs offered by Mountain Wave Swimming. I,
              my executors, or other representatives, waive and release all
              rights and claims for injuries or damages of any kind that I or my
              minor/child may have against Mountain Wave Swimming and/or its
              staff, owners or representatives arising out of participation in
              activities at Mountain Wave Swimming.
            </p>
            <p className="waiver-text">Posting of Pictures/Videos of Minors</p>
            <p className="waiver-text waiver-par">
              In order to protect the privacy of youth, permission must also be
              obtained, in writing, from the parent or guardian before
              sharing/posting pictures or videos of minors. (Please check which
              one applies)
            </p>
            {/* Check here */}
            <div style={{ marginBottom: "20px", paddingLeft: "30px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "start",
                  marginBottom: "15px",
                }}
              >
                <input
                  type="checkbox"
                  checked={pictureAndNameGranted}
                  className="check"
                  onChange={() => handleCheck(0)}
                  disabled={props.isInstructor}
                />
                <Button
                  style={{
                    color: "#454545",
                    textTransform: "none",
                    fontWeight: "200",
                    padding: "0",
                  }}
                  onClick={() => {
                    if (!props.isInstructor) handleCheck(0);
                  }}
                >
                  <p className="waiver-permission-text">
                    I give permission for my child's picture, with name, to be
                    posted on a website or social network page associated with
                    Mountain Wave Swimming.
                  </p>
                </Button>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "start",
                  marginBottom: "15px",
                }}
              >
                <input
                  type="checkbox"
                  checked={pictureNoNameGranted}
                  className="check"
                  onChange={() => handleCheck(1)}
                  disabled={props.isInstructor}
                />
                <Button
                  style={{
                    color: "#454545",
                    textTransform: "none",
                    fontWeight: "200",
                    padding: "0",
                  }}
                  onClick={() => {
                    if (!props.isInstructor) handleCheck(1);
                  }}
                >
                  <p className="waiver-permission-text">
                    I give permission for my child's picture, without name, to
                    be posted on a website or social network page associated
                    with Mountain Wave Swimming.
                  </p>
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "start",
                  marginBottom: "15px",
                }}
              >
                <input
                  type="checkbox"
                  checked={noPermissionGranted}
                  className="check"
                  onChange={() => handleCheck(2)}
                  disabled={props.isInstructor}
                />
                <Button
                  style={{
                    color: "#454545",
                    textTransform: "none",
                    fontWeight: "200",
                    padding: "0",
                  }}
                  onClick={() => {
                    if (!props.isInstructor) handleCheck(2);
                  }}
                >
                  <p className="waiver-permission-text">
                    I do not give permission for my child's picture to be posted
                    on a website or social media network page.
                  </p>
                </Button>
              </div>
            </div>
            <p className="waiver-text">
              Medical Emergencies - Permission to Treat
            </p>
            <p className="waiver-text waiver-par">
              I also affirm that I now have and will continue to provide proper
              hospitalization, health, and accident insurance coverage, which I
              consider adequate for both my child’s protection and my own
              protection. I fully understand that Mountain Wave Swimming’s
              staff, owners and representatives are not physicians or medical
              practitioners of any kind. With the above in mind, I hereby grant
              consent and permission to Mountain Wave Swimming’s staff, owners
              and representatives to render temporary first aid to my
              minor/child in the event of any injury or illness, and if deemed
              necessary by Mountain Wave Swimming staff to call our doctor and
              to seek medical help, including transportation by a Mountain Wave
              Swimming staff member and/or its representatives, to any health
              care facility or hospital, or the calling of an ambulance for said
              child should the Mountain Wave Swimming staff deem this to be
              necessary.
            </p>
            <p className="waiver-text waiver-par">
              By signing below, I acknowledge I have read the above and agree to
              all of these terms.
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
          }}
        >
          <div style={{ width: "73%" }}>
            <p className="edit-label">Parent/Guardian Signature</p>
            {!student.waiverSignedBy ? (
              <input
                value={parentName}
                onChange={(e) => setParentName(e.target.value)}
                type="text"
                placeholder="Type Name"
                className="login-input"
                style={{ width: "100%" }}
                id="first-name"
                disabled={props.isInstructor}
              />
            ) : (
              <>
                {selectedParent && !currentUserIsParent && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <FiCheck size={20} style={{ color: "#45CDCF" }} />
                    <p style={{ color: "#454545", marginLeft: 5 }}>
                      {`Waiver signed (Parent/Guardian: ${selectedParent?.firstName} ${selectedParent?.lastName})`}
                    </p>
                  </div>
                )}
                {currentUserIsParent && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <FiCheck size={20} style={{ color: "#45CDCF" }} />
                    <p style={{ color: "#454545", marginLeft: 5 }}>
                      {`Waiver Signing Complete`}
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
          <div style={{ width: "2%" }}></div>
          <div style={{ width: "25%" }}>
            <p className="edit-label">Today's Date</p>
            <DatePicker
              selected={studentInformationSaveDate}
              placeholderText="xx/xx/xxxx"
              className="calendar-grey"
              onChange={(newDate) => setStudentInformationSaveDate(newDate)}
              style={{
                border: "2px solid red",
                width: "inherit",
                padding: "9px 30px",
              }}
              disabled={props.isInstructor}
            />
          </div>
        </div>
        {noPermissionBoxesChecked && (
          <p style={{ textAlign: "center", color: "red" }}>
            *You must check one of the permission boxes before saving
          </p>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <AppButton
            onClick={props.onRequestClose}
            outline={true}
            title="Cancel"
            fontSize={"14px"}
            width="20%"
            margin="0 10px 0 10px"
          />

          {!props.isInstructor && (
            <AppButton
              disabled={noPermissionBoxesChecked}
              onClick={updateStudent}
              title="Save"
              loading={saveLoading}
              fontSize={"14px"}
              width="20%"
              margin="0 10px 0 10px"
            />
          )}
        </div>
      </div>
      <SlidingPane
        isOpen={chooseParentPanelOpen}
        from="right"
        width="400px"
        onRequestClose={() => {
          setChooseParentPanelOpen(false);
          fetchParents(0);
        }}
      >
        <ChooseParentPanel
          onRequestClose={() => {
            setChooseParentPanelOpen(false);
            fetchParents(0);
          }}
          onParentSelected={(parentId) => {
            fetchAndSetParent(parentId);
          }}
        />
      </SlidingPane>
    </Modal>
  );
}
