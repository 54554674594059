import { useContext } from "react";
import { useWindowSize } from "../../hooks/windowSize";
import { AppContext } from "../../pages/AppContainer";
import { Tag } from "../Tag";
import ChangePassword from "./ChangePassword";
import NotificationPreferences from "./NotificationPreferences";
import PersonalInformation from "./PersonalInformation";
import { UserSchedule } from "./UserSchedule";

export function AdminSettings(props) {
  const { currentUser } = useContext(AppContext);
  const size = useWindowSize();

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "center",
          padding: "30px 60px 0 60px",
        }}
      >
        <h1 className="page-title">Account Settings</h1>
        {
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <p
              style={{
                fontSize: "18px",
                fontWeight: "600",
                margin: "0",
                padding: "0",
              }}
            >
              Persmission Status:{" "}
            </p>
            <Tag
              label={
                currentUser.role === 1
                  ? "Instructor"
                  : currentUser.role === 2
                  ? "Administrator"
                  : currentUser.role === 3
                  ? "Admin/Instructor"
                  : "N/A"
              }
              marginLeft="20px"
              background="#45CDCF"
            />
          </div>
        }
      </div>
      <div
        style={{
          height: size?.height ? size.height - 170 : "50vh",
          overflow: "auto",
          padding: "30px 60px 30px 60px",
        }}
      >
        <div className="row">
          <div className="col" style={{ width: "80%", marginRight: 20 }}>
            <PersonalInformation />

            {(currentUser.role === 1 || currentUser.role === 3) && (
              <UserSchedule />
            )}
          </div>
          <div className="col" style={{ width: "40%" }}>
            <ChangePassword />
            <NotificationPreferences />
          </div>
        </div>
      </div>
    </div>
  );
}
