import { Typography } from "@material-ui/core";

export function HorizontalSpace(props) {
  return (
    <div
      style={{
        marginLeft: props.amount,
      }}
    >
      <Typography />
    </div>
  );
}

export function VerticalSpace(props) {
  return (
    <div
      style={{
        paddingBottom: props.amount,
      }}
    >
      {" "}
    </div>
  );
}
