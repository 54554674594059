export function Tag(props) {
  return (
    <div
      style={{
        background: props.background ?? "#F0142F",
        borderRadius: "100px",
        padding: props.padding ?? "10px 14px",
        marginRight: props.marginRight ?? "0px",
        marginLeft: props.marginLeft ?? "0px",
        marginBottom: props.marginBottom ?? "0px",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        width: props.width ?? null,
      }}
    >
      <p
        style={{
          fontSize: props.fontSize ?? "10px",
          color: props.color ?? "white",
          fontWeight: props.fontWeight ?? "600",
          margin: "0",
          padding: "0",
        }}
      >
        {props.label}
      </p>
    </div>
  );
}
