/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, Grid } from "@mui/material";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import {useContext, useEffect, useState} from "react";
import { BsPersonCircle } from "react-icons/bs";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { AppButton } from "../components/AppButton";
import {
  getFullDayString,
  getTimeStringFromSeconds, getUtcDate,
  reqHeaders,
} from "../utils";
import {AppContext} from "./AppContainer";

export function Calendar() {
  const [loading, setLoading] = useState(false);
  const [participantsLoading, setParticipantsLoading] = useState(false);
  const [upcomingLessonsLoading, setUpcomingLessonsLoading] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [lessons, setLessons] = useState([]);
  const [upcomingLessons, setUpcomingLessons] = useState([]);
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState();

  const [studentData, setStudentData] = useState();
  const [instructorData, setInstructorData] = useState();

  const { enqueueSnackbar } = useSnackbar();
  const { getCurrentUser } =  useContext(AppContext);

  useEffect(() => {
    fetchUpcomingLessons();
  }, []);

  useEffect(() => {
    getDaysInMonth(selectedDate);
    fetchMonthCalendar();
  }, [selectedDate]);

  useEffect(() => {
    const searchDelay = setTimeout(() => {
      fetchMonthCalendar(0);
    }, 500);
    return () => clearTimeout(searchDelay);
  }, [searchText]);

  useEffect(() => {
    if (selectedLesson?.studentId && selectedLesson?.instructorId) {
      fetchLessonParticipants(selectedLesson);
    }
  }, [selectedLesson]);

  function getDaysInMonth(date) {
    let daysArray = [];
    var lastDay = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();
    for (let i = 0; i < lastDay; i++) {
      daysArray.push(i + 1);
    }
    var firstDayOfWeek = new Date(
      date.getFullYear(),
      date.getMonth(),
      1
    ).getDay();
    var lastDayPreviousMonth = new Date(
      date.getFullYear(),
      date.getMonth(),
      0
    ).getDate();
    for (let i = 0; i < firstDayOfWeek; i++) {
      if (daysArray.length < 42) {
        daysArray.unshift(lastDayPreviousMonth - i);
      }
    }
    const leftOver = 42 - daysArray.length;
    for (let i = 0; i < leftOver; i++) {
      daysArray.push(i + 1);
    }
    if (daysArray[daysArray.length - 1] > 6) {
      for (let i = 0; i < 7; i++) {
        daysArray.pop();
      }
    }
    setDaysInMonth(daysArray);
  }

  async function fetchMonthCalendar() {
    setLoading(true);
    const month = selectedDate.getMonth();
    const year = selectedDate.getFullYear();
    const text = searchText.trim();

    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/calendar/month?month=${
        month + 1
      }&year=${year}${text && text.length > 0 ? `&searchText=${text}` : ""}`,
      {
        method: "GET",
        headers: reqHeaders,
      }
    );
    const res = await req.json();
    if (res.data) {
      setLessons(JSON.parse(res.data));
    }
    setLoading(false);
  }

  async function fetchUpcomingLessons() {
    setUpcomingLessonsLoading(true);
    try {
      const req = await fetch(`${process.env.REACT_APP_API_URL}/lessons`, {
        method: "GET",
        headers: reqHeaders,
      });
      const res = await req.json();
      if (req.status === 200) {
        setUpcomingLessons(res.data);
      }
    } catch (err) {
      setUpcomingLessonsLoading(false);
    }
    setUpcomingLessonsLoading(false);
  }

  async function fetchLessonParticipants(lesson) {
    setParticipantsLoading(true);
    try {
      const instructorReq = await fetch(
        `${process.env.REACT_APP_API_URL}/users/instructors/${lesson.instructorId}`,
        {
          method: "GET",
          headers: reqHeaders,
        }
      );
      const studentReq = await fetch(
        `${process.env.REACT_APP_API_URL}/students/student/${lesson.studentId}`,
        {
          method: "GET",
          headers: reqHeaders,
        }
      );
      if (instructorReq.status === 200) {
        const instructor = await instructorReq.json();
        setInstructorData(instructor.user);
      }
      if (studentReq.status === 200) {
        const student = await studentReq.json();
        setStudentData(student.data);
      }
    } catch (err) {
      console.debug(err);
      displayMessage("Failed to load user data", "error");
      setParticipantsLoading(false);
    }
    setParticipantsLoading(false);
  }

  async function cancelLesson() {
    try {
      setParticipantsLoading(true);
      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/lessons/cancel`,
        {
          method: "PUT",
          headers: reqHeaders,
          body: JSON.stringify({
            lessonId: selectedLesson._id,
          }),
        }
      );
      if (req.status === 200) {
        setSelectedLesson(null);
        setInstructorData(null);
        setStudentData(null);
        getCurrentUser();
        fetchMonthCalendar();
        fetchUpcomingLessons();
        displayMessage(
          "The lesson has been canceled and a lesson has been added back to your account balance.",
          "success"
        );
      } else if (req.status === 400) {
        const data = await req.json();
        setParticipantsLoading(false);
        return displayMessage(data.message, "error");
      }
    } catch (err) {
      console.debug(err);
      displayMessage("Failed to cancel lesson", "error");
      setParticipantsLoading(false);
    }
    setParticipantsLoading(false);
  }

  const displayMessage = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
    });
  };

  function previousPressed() {
    const newDate = new Date(selectedDate);
    newDate.setMonth(selectedDate.getMonth() - 1);
    setSelectedDate(newDate);
  }

  function nextPressed() {
    const newDate = new Date(selectedDate);
    newDate.setMonth(selectedDate.getMonth() + 1);
    setSelectedDate(newDate);
  }

  function getStringFromStatus(status, lessonDate) {
    // [0: nothing, 1: is first lesson, 2: missed, 3: canceled, 4: completed]
    const today = new Date();
    const isInPast = lessonDate.getDate() < today.getDate();

    switch (status) {
      case 0: {
        if (!isInPast) return "Upcoming";
        else return "Lesson";
      }
      // case 2: {
      //   return "Missed";
      // }
      // case 4: {
      //   return "Completed";
      // }
      default: {
        if (!isInPast) return "Upcoming";
        else return "";
      }
    }
  }

  function getColorFromStatus(status, lessonDate) {
    // [0: nothing, 1: is first lesson, 2: missed, 3: canceled, 4: completed]
    const today = new Date();
    const isInPast = lessonDate.getDate() < today.getDate();
    switch (status) {
      case 0: {
        if (!isInPast) return "#F8C28A";
        else return "black";
      }
      // case 2: {
      //   return "#F0142F";
      // }
      case 4: {
        return "#21D59B";
      }
      default: {
        if (!isInPast) return "#F8C28A";
        else return "black";
      }
    }
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "30px 60px 0 60px",
        }}
      >
        <h1 className="page-title">Calendar</h1>
      </div>
      <div
        className="row"
        style={{
          justifyContent: "start",
          alignItems: "start",
          marginTop: "30px",
        }}
      >
        <div
          style={{
            padding: "0px 15px 30px 60px",
          }}
        >
          {/* Calendar white container */}
          <div
            style={{
              background: "white",
              padding: "15px",
              boxShadow: "0px 3px 10px #15223224",
              borderRadius: "23px",
              height: "100%",
              overflow: "auto",
              overflowX: "hidden",
              minWidth: 550,
              maxWidth: 1200,
            }}
          >
            {/* Top calendar info */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "22px",
                    fontWeight: "600",
                    margin: "0",
                    marginLeft: "50px",
                  }}
                >
                  {format(selectedDate, "MMMM yyyy")}
                </p>

                <HiChevronLeft
                  onClick={previousPressed}
                  size={30}
                  style={{
                    marginLeft: "40px",
                    marginRight: "25px",
                    color: "#707070",
                    cursor: "pointer",
                  }}
                />

                <HiChevronRight
                  onClick={nextPressed}
                  size={30}
                  style={{ color: "#707070", cursor: "pointer" }}
                />
                {loading && (
                  <CircularProgress
                    size={20}
                    style={{ color: "black", marginLeft: "40px" }}
                  />
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  placeContent: "center",
                }}
              >
                <input
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  type="text"
                  placeholder="Search students..."
                  className="login-input"
                  id="search"
                  style={{ marginRight: 30, paddingRight: 20 }}
                />
              </div>
            </div>
            {/* Table data */}

            <Grid container style={{ marginTop: 20 }}>
              {[0, 1, 2, 3, 4, 5, 6].map((day) => (
                <Grid
                  key={getFullDayString(day)}
                  item
                  xs={1.71428671}
                  lg={1.71428671}
                  xl={1.71428671}
                  style={{
                    background: "#E7E9F3",
                    borderBottom: "1px solid #D8DBEB",
                  }}
                >
                  <p
                    style={{
                      fontSize: 13,
                      padding: "12px 0",
                      color: "#989898",
                      textAlign: "center",
                    }}
                  >
                    {getFullDayString(day)}
                  </p>
                </Grid>
              ))}

              {daysInMonth &&
                daysInMonth.map((day, index) => (
                  <Grid
                    key={`${day}${index}`}
                    item
                    xs={1.71428671}
                    lg={1.71428671}
                    xl={1.71428671}
                  >
                    <CalendarDayItem
                      day={day}
                      index={index}
                      selectedDate={selectedDate}
                      lessons={lessons}
                      onLessonPressed={(less) => {
                        setSelectedLesson(less);
                      }}
                      selectedLesson={selectedLesson}
                    />
                  </Grid>
                ))}
            </Grid>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "45px",
              paddingTop: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  background: "#F8C28A",
                  borderRadius: "100%",
                  height: "10px",
                  width: "10px",
                  marginRight: "7px",
                }}
              ></div>
              <p
                style={{
                  margin: "0",
                  padding: "0",
                  color: "#5A607F",
                  marginRight: "25px",
                }}
              >
                Upcoming
              </p>
              {/* <div
                style={{
                  background: "#21D59B",
                  borderRadius: "100%",
                  height: "10px",
                  width: "10px",
                  marginRight: "7px",
                }}
              ></div>
              <p
                style={{
                  margin: "0",
                  padding: "0",
                  color: "#5A607F",
                  marginRight: "25px",
                }}
              >
                Completed
              </p> */}
              {/* <div
                style={{
                  background: "#F0142F",
                  borderRadius: "100%",
                  height: "10px",
                  width: "10px",
                  marginRight: "7px",
                }}
              ></div>
              <p style={{ margin: "0", padding: "0", color: "#5A607F" }}>
                Missed Appointment
              </p> */}
            </div>
          </div>
        </div>
        <div className="col" style={{ width: "30%", marginRight: 60 }}>
          {/* Lesson detail box */}
          <div
            style={{
              background: "white",
              padding: "20px 30px",
              boxShadow: "0px 3px 10px #15223224",
              borderRadius: "23px",
              marginRight: "60px",
              width: "100%",
            }}
          >
            <div
              className="col"
              style={{
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              {!selectedLesson && !participantsLoading && (
                <>
                  <p>No lesson selected</p>
                  <p style={{ color: "#5A607F", marginTop: 20 }}>
                    Click a lesson to view the details
                  </p>
                </>
              )}
              {selectedLesson && (
                <>
                  <div
                    className="row"
                    style={{ justifyContent: "space-between", width: "100%" }}
                  >
                    <div className="col">
                      <p style={{ margin: 0, fontWeight: 600, fontSize: 14 }}>
                        {format(
                          new Date(selectedLesson.date).setDate(
                            new Date(selectedLesson.date).getDate() + 1
                          ),
                          "MMMM d, yyyy"
                        )}
                      </p>
                      <p style={{ margin: 0, fontSize: 16, color: "#45CDCF" }}>
                        {getTimeStringFromSeconds(
                          selectedLesson.startMinuteOfDay
                        )}
                      </p>
                    </div>
                    <p
                      style={{
                        color: getColorFromStatus(
                          selectedLesson.status,
                          new Date(selectedLesson.date)
                        ),
                        textTransform: "uppercase",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      {getStringFromStatus(
                        selectedLesson.status,
                        new Date(selectedLesson.date)
                      )}
                    </p>
                  </div>

                  {!participantsLoading ? (
                    <div
                      className="row"
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "20px",
                        marginTop: "30px",
                      }}
                    >
                      <div
                        className="col"
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {!studentData?.profilePhoto ? (
                          <BsPersonCircle
                            size={80}
                            style={{
                              color: "#DEDEDE",
                              boxShadow: "0px 3px 6px #00000029",
                              borderRadius: "100%",
                            }}
                          />
                        ) : (
                          <img
                            src={studentData?.profilePhoto ?? ""}
                            alt=""
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "100px",
                              objectFit: "cover",
                              boxShadow: "0px 3px 6px #00000029",
                            }}
                          />
                        )}
                        <p
                          style={{
                            fontWeight: "600",
                            fontSize: "20px",
                            marginTop: "10px",
                          }}
                        >
                          {studentData?.firstName ?? ""}{" "}
                          {`${studentData?.lastName[0]}.`.toUpperCase() ?? ""}
                        </p>
                      </div>
                      <HiChevronRight
                        color="#D8DBEB"
                        size={60}
                        style={{ margin: "0 10px" }}
                      />
                      <div
                        className="col"
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {!instructorData?.profilePhoto ? (
                          <BsPersonCircle
                            size={80}
                            style={{
                              color: "#DEDEDE",
                              boxShadow: "0px 3px 6px #00000029",
                              borderRadius: "100%",
                            }}
                          />
                        ) : (
                          <img
                            src={instructorData?.profilePhoto ?? ""}
                            alt=""
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "100px",
                              objectFit: "cover",
                              boxShadow: "0px 3px 6px #00000029",
                            }}
                          />
                        )}
                        <p
                          style={{
                            fontWeight: "600",
                            fontSize: "20px",
                            marginTop: "10px",
                          }}
                        >
                          {instructorData?.firstName ?? ""}{" "}
                          {`${instructorData?.lastName[0]}.`.toUpperCase() ??
                            ""}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <CircularProgress
                      size={26}
                      style={{ color: "black", margin: "50px 0" }}
                    />
                  )}
                  <AppButton
                    onClick={cancelLesson}
                    title="Cancel Appointment"
                    fontSize={"12px"}
                    margin="15px 7px 15px 7px"
                    padding="5px 15px 5px 15px"
                    color="#DD3539"
                  />
                </>
              )}
            </div>
          </div>

          {/* Upcoming lessons */}
          <div
            style={{
              width: "100%",
              background: "white",
              padding: "20px 30px",
              boxShadow: "0px 3px 10px #15223224",
              borderRadius: "23px",
              marginRight: "60px",
              marginTop: 30,
            }}
          >
            <div
              className="col"
              style={{
                height: "100%",
                justifyContent: "start",
              }}
            >
              <p style={{ fontSize: 14, fontWeight: 600, marginBottom: 20 }}>
                Upcoming Lessons
              </p>
              {upcomingLessonsLoading && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress
                    size={26}
                    style={{
                      color: "black",
                      margin: "50px 70px",
                    }}
                  />
                </div>
              )}
              {upcomingLessons?.length < 1 && (
                <p style={{ marginTop: 20 }}>No upcoming lessons</p>
              )}
              {!upcomingLessonsLoading &&
                upcomingLessons.map((lesson) => (
                  <div
                    key={lesson._id}
                    className="row"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px 20px",
                    }}
                  >
                    <div
                      className="row"
                      style={{
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="col"
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: 25,
                        }}
                      >
                        <p
                          style={{
                            margin: 0,

                            color: "#131523",
                            fontSize: 14,
                            fontWeight: 600,
                          }}
                        >
                          {format(getUtcDate(lesson.date), "MMM")}
                        </p>
                        <p
                          style={{
                            margin: 0,
                            color: "#45CDCF",
                            fontSize: 16,
                          }}
                        >
                          {format(getUtcDate(lesson.date), "d")}
                        </p>
                      </div>
                      <p>
                        {lesson.studentName.split(" ")[0]} with{" "}
                        {lesson.instructorName.split(" ")[0]}
                      </p>
                    </div>
                    {/* <BsThreeDots size={20} style={{ color: "#707070" }} /> */}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function CalendarDayItem(props) {
  const { day, index, selectedDate, lessons, selectedLesson, onLessonPressed } =
    props;

  const isDifferentMonth = (day > 7 && index < 7) || (day < 7 && index > 11);
  const getLessonsToday = () => {
    const lessonsArr = [];
    const currentDate = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      day - 1
    );
    for (const lesson of lessons) {
      const lessonDate = new Date(lesson.date);
      if (
        lessonDate.getMonth() === currentDate.getMonth() &&
        lessonDate.getFullYear() === currentDate.getFullYear() &&
        lessonDate.getDate() === currentDate.getDate()
      ) {
        lessonsArr.push(lesson);
      }
    }
    return lessonsArr.sort((a, b) => a.startMinuteOfDay - b.startMinuteOfDay);
  };

  function getColorFromStatus(status, lessonDate, isSelected) {
    // [0: nothing, 1: is first lesson, 2: missed, 3: canceled, 4: completed]
    const today = new Date();
    const isInPast = lessonDate.getDate() < today.getDate();
    if (isSelected) return "white";
    switch (status) {
      case 0: {
        if (!isInPast) return "#F8C28A";
        else return "transparent";
      }
      case 2: {
        return "#F0142F";
      }
      case 4: {
        return "#21D59B";
      }
      default: {
        if (!isInPast) return "#F8C28A";
        else return "transparent";
      }
    }
  }

  function getBackgroundColorFromStatus(status, lessonDate, isSelected) {
    // [0: nothing, 1: is first lesson, 2: missed, 3: canceled, 4: completed]
    const today = new Date();
    const isInPast = lessonDate.getDate() < today.getDate();

    if (!isSelected) return "transparent";
    switch (status) {
      case 0: {
        if (!isInPast) return "#F8C28A";
        else return "#45CDCF";
      }
      case 2: {
        return "#F0142F";
      }
      case 4: {
        return "#21D59B";
      }
      default: {
        if (!isInPast) return "#F8C28A";
        else return "#45CDCF";
      }
    }
  }

  function getMonthAfterSelected(date) {
    const newDate = new Date(date);
    newDate.setMonth(date.getMonth() + 1);
    return format(newDate, "MMM");
  }

  const lessonsToday = getLessonsToday();

  const handleClick = (currentVal) => {
    onLessonPressed(currentVal);
  };
  const isToday =
    index < 31 &&
    new Date().getDate() === day &&
    new Date().getMonth() === selectedDate.getMonth() &&
    new Date().getFullYear() === selectedDate.getFullYear();
  return (
    <div
      style={{
        borderBottom: "1px solid #D8DBEB",
        borderRight: "1px solid #D8DBEB",
        borderLeft:
          index === 0 ||
          index === 7 ||
          index === 14 ||
          index === 21 ||
          index === 28 ||
          index === 35
            ? "1px solid #D8DBEB"
            : "none",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        className="row"
        style={{
          alignItems: "center",
          justifyContent: "center",
          alignContent: "start",
        }}
      >
        {day === 1 ? (
          <p
            style={{
              fontSize: 14,
              fontWeight: 600,
              margin: 0,
              color: !isDifferentMonth ? "black" : "#D8DBEB",
            }}
          >
            {index < 7
              ? `${format(selectedDate, "MMM")} `
              : `${getMonthAfterSelected(selectedDate)} `}
          </p>
        ) : (
          <></>
        )}
        <div
          className="col"
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: 3,
            marginBottom: 3,
            background: isToday ? "#6ECACD" : "transparent",
            width: 22,
            height: 22,
            borderRadius: "100%",
          }}
        >
          <p
            style={{
              fontSize: 14,
              fontWeight: 600,
              color: !isDifferentMonth
                ? !isToday
                  ? "black"
                  : "white"
                : "#D8DBEB",
              textAlign: "center",
            }}
          >
            {day}
          </p>
        </div>
      </div>

      <div
        className="col"
        style={{
          overflow: "scroll",
          overflowX: "hidden",
          overflowY: "auto",
          width: "90%",
        }}
      >
        {!isDifferentMonth &&
          lessonsToday.map((lesson) => (
            <div
              className="row"
              style={{
                alignItems: "center",
                padding: "5px 2px",
                marginRight: "5px",
                background: getBackgroundColorFromStatus(
                  lesson.status,
                  new Date(lesson.date),
                  selectedLesson?._id === lesson._id
                ),
                borderRadius: "30px",
                paddingLeft: "7px",
                cursor: "pointer",
              }}
              key={lesson._id}
              onClick={() => handleClick(lesson)}
            >
              <div
                style={{
                  background: getColorFromStatus(
                    lesson.status,
                    new Date(lesson.date),
                    selectedLesson?._id === lesson._id
                  ),
                  borderRadius: "100%",
                  maxHeight: "10px",
                  minHeight: "10px",
                  minWidth: "10px",
                  maxWidth: "10px",
                  marginRight: "7px",
                }}
              ></div>
              <p
                style={{
                  fontSize: 10,
                  color: "#5A607F",
                }}
              >
                {getTimeStringFromSeconds(lesson.startMinuteOfDay)}
                {" - "}
                {lesson?.studentName.split(" ")[0] ?? "Not found"}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
}
