/* eslint-disable react-hooks/exhaustive-deps */
import {useContext, useEffect, useState, useCallback} from "react";
import {BiCalendarAlt} from "react-icons/bi";
import {useWindowSize} from "../hooks/windowSize";
import {HiChevronLeft, HiChevronRight} from "react-icons/hi";
import {getTimeStringFromSeconds, instructorIsAvailableV2, reqHeaders, timeArray,} from "../utils";
import {AiFillPlusCircle} from "react-icons/ai";
import {BookLessonModal} from "../components/modals/BookLessonModal";
import {BookLessonModalAdmin} from "../components/modals/BookLessonModalAdmin";
import {AppContext} from "./AppContainer";
import {CircularProgress, debounce} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {format} from "date-fns";

export function BookLesson(props) {
  const { currentUser } = useContext(AppContext);
  const userIsAdmin = currentUser.role > 1;
  const [loading, setLoading] = useState(false);
  const [calendar, setCalendar] = useState({});
  const [searchText, setSearchText] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      0,
      0,
      0
    )
  );
  const [selectedLessonDate, setSelectedLessonDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState();
  const [selectedInstructor, setSelectedInstructor] = useState();
  const [selectedMinimumChildAge, setSelectedMinimumChildAge] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [bookModalIsOpen, setBookModalIsOpen] = useState(false);
  const [calendarType, setCalendarType] = useState(null);
  const size = useWindowSize();

  useEffect(() => {
    fetchAdminCalendar();
  }, [selectedDate, searchText]);

  const changeHandler = event => {
    setCurrentPage(0);
    setSearchText(event.target.value);
  }
  const debouncedChangeHandler = useCallback(debounce(changeHandler, 500), []);

  async function fetchAdminCalendar(newCurrentPage) {
    setLoading(true);
    const month = selectedDate.getMonth();
    const day = selectedDate.getDate();
    const year = selectedDate.getFullYear();
    const text = searchText.trim();

    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/calendar/day?month=${
        month + 1
      }&day=${day}&year=${year}&itemsPerPage=${5}&page=${newCurrentPage ?? 0}${
        text && text.length > 0 ? `&searchText=${text}` : ""
      }`,
      {
        method: "GET",
        headers: reqHeaders,
      }
    );
    const res = await req.json();
    setTotalPages(res.pageCount);
    setCalendarType(res.calendarType);
    if (res.data) {
      setCalendar(res.data);
    }
    setLoading(false);
  }

  const previousButtonDisabled = () => {
    if (!totalPages || totalPages < 2 || currentPage === 0) {
      return true;
    }
    return false;
  };

  const nextButtonDisabled = () => {
    if (!totalPages || currentPage + 1 >= totalPages) {
      return true;
    }
    return false;
  };

  function previousPressed() {
    const newCurrentPage = currentPage - 1;
    setCurrentPage(newCurrentPage);
    fetchAdminCalendar(newCurrentPage);
  }

  function nextPressed() {
    const newCurrentPage = currentPage + 1;
    setCurrentPage(newCurrentPage);
    fetchAdminCalendar(newCurrentPage);
  }

  // Get a lesson for a specific time and instructor
  function getLesson(instructorId, time) {
    const lesson = calendar[instructorId].lessons.find(
      (l) => l.startMinuteOfDay === time
    );
    return lesson ?? null;
  }

  function getWeeklyLesson(date, time) {
    const lessons = calendar.days.find(d => d.date === date).lessons;
    return lessons.find(l => l.startMinuteOfDay === time)
  }

  function buildDailyCalendar() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "start",
        }}
      >
        <table style={{ width: "95%", marginTop: "20px" }}>
          <tbody>
          <tr style={{ background: "#E6E9F4" }}>
            <td style={{ width: "12%", padding: "5px 10px" }}>
              <p
                style={{ padding: "0", margin: "0", color: "#004367" }}
              >
                Time Slot
              </p>
            </td>
            {calendar &&
              Object.keys(calendar).length > 0 &&
              Object.keys(calendar).map((val, i) => (
                <td key={val}>
                  <div>
                    <p
                      style={{
                        padding: "0",
                        margin: "0",
                        color: "#004367",
                      }}
                    >
                      {calendar[val].instructorName}
                      {!!calendar[val].minimumChildAge ? ` (Ages >= ${calendar[val].minimumChildAge})` : ''}
                    </p>
                  </div>
                </td>
              ))}
          </tr>
          {timeArray.map((time, i) => (
            <tr
              key={time}
              style={{ background: i % 2 !== 0 ? "#F5F7FF" : "white" }}
            >
              <td style={{ width: "12%", padding: "5px 10px" }}>
                <p
                  style={{
                    margin: "0",
                    padding: "0",
                    fontWeight: "600",
                    color: "#004367",
                  }}
                >
                  {getTimeStringFromSeconds(time)}
                </p>
              </td>
              {calendar &&
                Object.keys(calendar).length > 0 &&
                Object.keys(calendar).map((instructorId, i) => {
                  const instructorAvailable = instructorIsAvailableV2(
                    calendar[instructorId].schedule,
                    time,
                    getUtcDate(selectedDate)
                  );
                  return (
                    <td key={instructorId}>
                      <div style={{}}>
                        <AdminCalendarItem
                          lesson={getLesson(instructorId, time)}
                          instructorAvailable={instructorAvailable}
                          onBookPressed={() => {
                            setSelectedTime(time);
                            setSelectedLessonDate(selectedDate);
                            setSelectedInstructor(instructorId);
                            setSelectedMinimumChildAge(calendar[instructorId].minimumChildAge);
                            setBookModalIsOpen(true);
                          }}
                        />
                      </div>
                    </td>
                  );
                })}
            </tr>
          ))}
          </tbody>
        </table>
        {totalPages > 1 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              marginTop: "30px",
              width: "5%",
            }}
          >
            <button
              style={{ padding: "0", margin: "0" }}
              disabled={previousButtonDisabled()}
              onClick={previousPressed}
            >
              <HiChevronLeft
                size={20}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  color: previousButtonDisabled() ? "#b1b1b1" : "black",
                }}
              />
            </button>
            <button
              style={{ padding: "0", margin: "0" }}
              disabled={nextButtonDisabled()}
              onClick={nextPressed}
            >
              <HiChevronRight
                size={20}
                style={{
                  color: nextButtonDisabled() ? "#b1b1b1" : "black",
                }}
              />
            </button>
          </div>
        )}
      </div>
    );
  }

  function getUtcDate(dateStr) {
    const date= new Date(dateStr);
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      0,
      0,
      0,
    );
  }

  function buildWeeklyCalendar() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <div style={{width: '100%', textAlign: 'center', marginTop: '10px' }}><h3>Weekly Schedule for: {calendar.instructorName}</h3></div>
        <table style={{ width: "95%", marginTop: "20px" }}>
          <tbody>
          <tr style={{ background: "#E6E9F4" }}>
            <td style={{ width: "12%", padding: "5px 10px" }}>
              <p
                style={{ padding: "0", margin: "0", color: "#004367" }}
              >
                Time Slot
              </p>
            </td>
            {calendar &&
              calendar.days.map((day, i) => (
                <td key={`header_day_${i}`}>
                  <div>
                    <p
                      style={{
                        padding: "0",
                        margin: "0",
                        color: "#004367",
                      }}
                    >
                      {format(getUtcDate(day.date), 'MM/dd')}
                    </p>
                  </div>
                </td>
              ))}
          </tr>
          {timeArray.map((time, i) => (
            <tr
              key={time}
              style={{ background: i % 2 !== 0 ? "#F5F7FF" : "white" }}
            >
              <td style={{ width: "12%", padding: "5px 10px" }}>
                <p
                  style={{
                    margin: "0",
                    padding: "0",
                    fontWeight: "600",
                    color: "#004367",
                  }}
                >
                  {getTimeStringFromSeconds(time)}
                </p>
              </td>
              {calendar &&
                calendar.days.map((day, i) => {
                  const instructorAvailable = instructorIsAvailableV2(
                    calendar.instructorSchedule,
                    time,
                    getUtcDate(day.date)
                  );
                  return (
                    <td key={`data_day_${i}`}>
                      <div style={{}}>
                        <AdminCalendarItem
                          lesson={getWeeklyLesson(day.date, time)}
                          instructorAvailable={instructorAvailable}
                          onBookPressed={() => {
                            setSelectedTime(time);
                            setSelectedLessonDate(getUtcDate(day.date));
                            setSelectedInstructor(calendar.instructorId);
                            setBookModalIsOpen(true);
                          }}
                        />
                      </div>
                    </td>
                  );
                })}
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "30px 60px 0 60px",
        }}
      >
        <h1 className="page-title">Book a Lesson</h1>
      </div>

      <div
        style={{
          height: size?.height ? size.height - 200 : "50vh",
          overflow: "auto",
          padding: "30px 60px 30px 60px",
        }}
      >
        {/* Calendar white container */}
        <div
          style={{
            background: "white",
            padding: "15px",
            boxShadow: "0px 3px 10px #15223224",
            borderRadius: "12px",
            height: "100%",
            overflow: "scroll",
            overflowX: "hidden",
          }}
        >
          {/* Top calendar info */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <div
                style={{
                  background: "#45CDCF",
                  borderRadius: "100%",
                  minWidth: 36,
                  minHeight: 36,
                  maxWidth: 36,
                  maxHeight: 36,
                  padding: "0",
                  margin: "0",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  marginRight: "15px",
                  boxShadow: "0px 3px 6px #00000029",
                }}
              >
                <BiCalendarAlt
                  size={22}
                  style={{
                    color: "white",
                    padding: "0",
                    marginTop: "-2px",
                  }}
                />
              </div>
              <DatePicker
                selected={selectedDate}
                placeholderText="xx/xx/xxxx"
                id="book-lesson-date-picker"
                onChange={(newDate) => {
                  setSelectedDate(
                    new Date(
                      newDate.getFullYear(),
                      newDate.getMonth(),
                      newDate.getDate(),
                      0,
                      0,
                      0
                    )
                  );
                }}
                dateFormat="MMMM d, yyyy"
                minDate={
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate(),
                    0,
                    0,
                    0
                  )
                }
              />
              <button
                style={{ padding: "0", margin: "0" }}
                onClick={() => {
                  const newDate = new Date(
                    selectedDate.getFullYear(),
                    selectedDate.getMonth(),
                    selectedDate.getDate() - 1,
                    0,
                    0,
                    0
                  );
                  const today = new Date();
                  if (newDate < today) {
                    return;
                  }
                  setSelectedDate(
                    new Date(
                      newDate.getFullYear(),
                      newDate.getMonth(),
                      newDate.getDate(),
                      0,
                      0,
                      0
                    )
                  );
                }}
              >
                <HiChevronLeft
                  size={30}
                  style={{
                    marginLeft: "40px",
                    marginRight: "25px",
                    color: "#707070",
                  }}
                />
              </button>
              <button
                style={{ padding: "0", margin: "0" }}
                onClick={() => {
                  const newDate = new Date(
                    selectedDate.getFullYear(),
                    selectedDate.getMonth(),
                    selectedDate.getDate() + 1,
                    0,
                    0,
                    0
                  );

                  setSelectedDate(newDate);
                }}
              >
                <HiChevronRight size={30} style={{ color: "#707070" }} />
              </button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                placeContent: "center",
              }}
            >
              <input
                onChange={debouncedChangeHandler}
                type="text"
                placeholder="Search instructors..."
                className="login-input"
                id="search"
                style={{ marginRight: "30px", paddingRight: 20 }}
              />
            </div>
          </div>
          {loading && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress
                size={26}
                style={{ color: "black", marginTop: "100px" }}
              />
            </div>
          )}
          {!loading && calendarType === 'daily' && buildDailyCalendar()}
          {!loading && calendarType === 'weekly' && buildWeeklyCalendar()}
        </div>
      </div>
      {!userIsAdmin && (
        <BookLessonModal
          isOpen={bookModalIsOpen}
          selectedDate={selectedLessonDate}
          selectedTime={selectedTime}
          selectedInstructor={selectedInstructor}
          selectedMinimumChildAge={selectedMinimumChildAge}
          onRequestClose={() => {
            setBookModalIsOpen(false);
          }}
          onLessonBooked={() => {
            fetchAdminCalendar();
            props.onLessonBooked();
          }}
          onFinishBooking={() => {
            props.changeTab(2);
          }}
        />
      )}

      {userIsAdmin && (
        <BookLessonModalAdmin
          isOpen={bookModalIsOpen}
          selectedDate={selectedLessonDate}
          selectedTime={selectedTime}
          selectedInstructor={selectedInstructor}
          onRequestClose={() => {
            setBookModalIsOpen(false);
          }}
          onLessonBooked={() => {
            fetchAdminCalendar();
          }}
          onAfterClose={() => {}}
        />
      )}
    </div>
  );
}

function AdminCalendarItem(props) {
  const { lesson, instructorAvailable } = props;

  const hasLesson = lesson?.studentName;
  return hasLesson ? (
    <p
      style={{
        color: "#004367",
        padding: "0",
        margin: "0",
        textTransform: lesson?.studentName === "Booked" ? "uppercase" : "none",
      }}
    >
      {lesson?.studentName}
    </p>
  ) : (
    <>
      {instructorAvailable ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={props.onBookPressed}
        >
          <AiFillPlusCircle
            style={{
              color: "#6ECACD",
              cursor: "pointer",
              marginRight: "5px",
            }}
            size={18}
          />
          <p
            style={{
              padding: "0",
              margin: "0",
              fontSize: "14px",
              color: "#6ECACD",
            }}
          >
            Book Appointment
          </p>
        </div>
      ) : (
        <p
          style={{
            color: "#004367",
            padding: "0",
            margin: "0",
          }}
        >
          ---
        </p>
      )}
    </>
  );
}
