import { CircularProgress } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { reqHeaders } from "../utils";

export function RecentAnnouncements(props) {
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  async function fetchAnnouncements(newCurrentPage) {
    setLoading(true);
    try {
      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/announcements/limit/recent`,
        {
          method: "GET",
          headers: reqHeaders,
        }
      );
      const res = await req.json();
      if (res.data) {
        const orderedByDate = res.data
          .sort((a, b) => {
            return (
              new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
            );
          })
          .reverse();
        setAnnouncements(orderedByDate);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  }

  return (
    <div className="col">
      <p
        style={{
          fontWeight: 600,
          fontSize: 24,
          marginTop: 40,
          marginBottom: 10,
        }}
      >
        Announcements
      </p>
      <div
        style={{
          background: "white",
          borderRadius: 23,
          boxShadow: "0px 8px 20px #00000014",
          padding: "30px",
        }}
      >
        {loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress
              size={26}
              style={{ color: "black", margin: "100px 0" }}
            />
          </div>
        )}
        {announcements?.length < 1 && <p>No announcements</p>}
        {announcements &&
          !loading &&
          announcements.map((announcement) => (
            <div
              className="col"
              style={{ marginBottom: 20 }}
              key={announcement._id}
            >
              <p style={{ margin: 0, fontWeight: 600, fontSize: 16 }}>
                {announcement.title}
              </p>
              <p style={{ margin: 0, color: "#45CDCF", marginBottom: 7 }}>
                {format(new Date(announcement.updatedAt), "MMM dd, yyyy")}
              </p>
              <div style={{ height: "56px", overflow: "hidden" }}>
                <p
                  style={{
                    marginTop: "20px",
                    marginBottom: "10px",
                    padding: "0",
                    margin: "0",
                    wordBreak: "break-word",
                    fontSize: 13,
                  }}
                >
                  {announcement.content}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  alignItems: "center",
                  marginTop: 5,
                  cursor: "pointer",
                }}
                onClick={props.onReadMoreClicked}
              >
                <p
                  style={{
                    padding: "0",
                    fontSize: 13,
                    color: "#406C8C",
                    margin: "0",
                  }}
                >
                  Read More
                </p>
                <HiChevronRight size={14} style={{ color: "#406C8C" }} />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
