import { FiPlusCircle, FiSettings } from "react-icons/fi";
import { MdOutlineAttachMoney } from "react-icons/md";
import { BiGroup } from "react-icons/bi";
import { useContext } from "react";
import { AppContext } from "./AppContainer";
import { AdminCalendar } from "./AdminCalendar";
import { useWindowSize } from "../hooks/windowSize";

export default function AdminDashboard(props) {
  const { currentUser } = useContext(AppContext);
  const isSuperAdmin = currentUser?.role === 2 || currentUser?.role === 3;

  const size = useWindowSize();

  return (
    <div
      style={{
        dislay: "flex",
        flexDirection: "column",
        paddingLeft: "50px",
        height: size?.height ? size.height - 85 : "50vh",
        overflow: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "30px 60px 0 0px",
        }}
      >
        <h1 className="page-title">Dashboard</h1>
      </div>
      <p style={{ fontSize: "24px", fontWeight: "600", marginTop: "50px" }}>
        Quicklinks
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: currentUser.role !== 1 ? "space-between" : "start",
        }}
      >
        {isSuperAdmin && (
          <DashboardButton
            text="Book a Lesson"
            icon={<FiPlusCircle size={"100px"} style={{ color: "#F8C28A" }} />}
            onPress={() => props.changeTab(0)}
          />
        )}
        {isSuperAdmin && (
          <DashboardButton
            text="Manage Pricing"
            onPress={() => props.changeTab(6)}
            icon={
              <MdOutlineAttachMoney
                size={"100px"}
                style={{ color: "#F8C28A" }}
              />
            }
          />
        )}
        <DashboardButton
          text="View Students"
          icon={<BiGroup size={"100px"} style={{ color: "#F8C28A" }} />}
          onPress={() => {
            props.changeTab(4);
          }}
        />
        {currentUser.role === 1 && <div style={{ marginRight: 20 }}></div>}
        <DashboardButton
          text="Account Settings"
          icon={<FiSettings size={"100px"} style={{ color: "#F8C28A" }} />}
          onPress={() => {
            props.changeTab(7);
          }}
        />
        <div style={{ width: "20%" }}></div>
      </div>
      <AdminCalendar isDashboard={true} />
    </div>
  );
}

export function DashboardButton(props) {
  return (
    <button onClick={props.onPress}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          background: "white",
          boxShadow: "0px 8px 20px #0000000D",
          width: "11vw",
          height: "11vw",
          minWidth: "11vw",
          minHeight: "11vw",
          borderRadius: "22px",
          cursor: "pointer",
          margin: "0 5px",
        }}
      >
        {props.icon}
        <p className="dashboard-btn-text">{props.text}</p>
      </div>
    </button>
  );
}
