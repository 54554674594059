import Modal from "react-modal";
import { ImCross } from "react-icons/im";
import "react-datepicker/dist/react-datepicker.css";
import { AppButton } from "../AppButton";

export function ConfirmDisableInstructorModal(props) {
  const { instructor, isOpen, onRequestClose, onConfirmPressed } = props;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "20px",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      backdropFilter: "blur(2px)",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      ariaHideApp={false}
    >
      <div style={{ width: "40vw", minWidth: "400px", padding: "20px" }}>
        <div style={{ textAlign: "end" }}>
          <button onClick={onRequestClose}>
            <ImCross size={12} style={{ color: "#B2AEAE" }} />
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            marginRight: "5px",
            padding: "0 50px",
          }}
        >
          <p
            style={{ fontWeight: "600", fontSize: "18px", textAlign: "center" }}
          >
            {`Are you sure you want to delete ${instructor?.firstName} ${instructor?.lastName}?`}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 30,
          }}
        >
          <AppButton
            onClick={onRequestClose}
            outline={true}
            title="Back"
            fontSize={"14px"}
            width="20%"
            margin="0 10px 0 10px"
          />

          <AppButton
            onClick={onConfirmPressed}
            title="Delete Instructor"
            fontSize={"14px"}
            margin="0 10px 0 10px"
            color="#DD3539"
          />
        </div>
      </div>
    </Modal>
  );
}
