import { useContext, useState } from "react";
import { BsFillCameraFill, BsPersonCircle } from "react-icons/bs";
import { formatPhoneNumber } from "../../utils";
import { deformatPhoneNumber } from "../../utils";
import { AppButton } from "../AppButton";
import { useSnackbar } from "notistack";
import { AppContext } from "../../pages/AppContainer";

export default function PersonalInformation(props) {
  const { currentUser, getCurrentUser } = useContext(AppContext);
  const [firstName, setFirstName] = useState(currentUser.firstName);
  const [lastName, setLastName] = useState(currentUser.lastName);
  const [phone, setPhone] = useState(
    formatPhoneNumber(
      deformatPhoneNumber(currentUser.phone?.substring(2) ?? "")
    )
  );
  const [email, setEmail] = useState(currentUser.email);
  const [profilePhoto, setProfilePhoto] = useState(currentUser.profilePhoto);
  const [savePersonalInformationLoading, setSavePersonalInformationLoading] =
    useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const personalInformationEdited =
    firstName !== currentUser.firstName ||
    lastName !== currentUser.lastName ||
    phone !==
      formatPhoneNumber(
        deformatPhoneNumber(currentUser.phone?.substring(2) ?? "")
      ) ||
    profilePhoto !== currentUser.profilePhoto;

  async function savePersonalInformation() {
    if (!firstName || !lastName) {
      displayMessage("You must enter a first and last name", "error");
      return;
    }
    if (!personalInformationEdited) {
      displayMessage(
        "Nothing to save. Edit your profile photo, name, phone number",
        "error"
      );
      return;
    }
    setSavePersonalInformationLoading(true);
    const req = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        user: {
          _id: currentUser._id,
          email: currentUser.email,
          firstName: firstName,
          lastName: lastName,
          phone: deformatPhoneNumber(phone),
          profilePhoto: profilePhoto,
        },
      }),
    });

    if (req.status === 200) {
      getCurrentUser();
      displayMessage("Your information has been updated", "success");
      setSavePersonalInformationLoading(false);
    } else {
      setSavePersonalInformationLoading(false);
      displayMessage("Something went wrong. Please try again.", "error");
    }
  }

  const displayMessage = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
    });
  };

  return (
    <div
      style={{
        background: "white",
        padding: "20px",
        boxShadow: "0px 4px 15px #00000014",
        borderRadius: "23px",
        margin: "10px 0",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            width: "40%",
          }}
        >
          <p
            style={{
              margin: "0",
              padding: "0",
              fontSize: "18px",
              fontWeight: "600",
              marginBottom: "15px",
            }}
          >
            Personal Information
          </p>
          <p
            style={{
              margin: "0",
              padding: "0 20px 0 0",
              fontSize: "14px",
              color: "#7E84A3",
            }}
          >
            To edit your personal information, change any of the information in
            the fields and click “save”.
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <label
            className="modal-image-container"
            style={{ marginBottom: "10px" }}
          >
            <input
              type="file"
              name="student-image"
              accept="image/png, image/gif, image/jpeg"
              onChange={(event) => {
                let files = event.target.files;
                let reader = new FileReader();
                reader.readAsDataURL(files[0]);
                reader.onload = (e) => {
                  setProfilePhoto(e.target.result);
                };
              }}
            />
            <div style={{ position: "relative" }}>
              <div
                style={{
                  width: "30px",
                  height: "30px",
                  background: "white",
                  position: "absolute",
                  right: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  borderRadius: "50px",
                  boxShadow: "0px 1px 4px rgba(0,0,0,0.4)",
                }}
              >
                <BsFillCameraFill
                  size={16}
                  style={{
                    color: "rgb(215, 219, 236)",
                  }}
                />
              </div>

              {!profilePhoto ? (
                <BsPersonCircle size={120} style={{ color: "#DEDEDE" }} />
              ) : (
                <img
                  src={profilePhoto}
                  alt=""
                  style={{
                    width: "120px",
                    height: "120px",
                    borderRadius: "100px",
                    objectFit: "cover",
                  }}
                />
              )}
            </div>
          </label>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "48%",
              }}
            >
              <p className="inputLabel">First Name</p>
              <input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                placeholder="First Name"
                className="login-input"
                id="first-name"
                style={{ color: "#406c8c" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "48%",
              }}
            >
              <p className="inputLabel">Last Name</p>
              <input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                type="text"
                placeholder="Last Name"
                className="login-input"
                id="last-name"
                style={{ color: "#406c8c" }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "48%",
              }}
            >
              <p className="inputLabel">Phone</p>
              <input
                value={phone}
                onChange={(e) => {
                  const formattedPhoneNumber = formatPhoneNumber(
                    e.target.value
                  );
                  setPhone(formattedPhoneNumber);
                }}
                type="phone"
                placeholder="Phone"
                className="login-input"
                id="phone"
                style={{ color: "#406c8c" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "48%",
              }}
            >
              <p className="inputLabel">Email</p>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                placeholder="Email"
                className="login-input"
                id="email"
                disabled
                style={{ color: "#406c8c" }}
              />
            </div>
          </div>
          <div
            style={{
              textAlign: "end",
              marginTop: "20px",
            }}
          >
            <AppButton
              onClick={savePersonalInformation}
              title="Save"
              loading={savePersonalInformationLoading}
              //   disabled={!personalInformationEdited}
              fontSize={"14px"}
              width="120px"
              margin="10px 0 10px 0"
              padding="7px"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
