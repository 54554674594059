import { format } from "date-fns";
import NumberFormat from "react-number-format";
import { BiPencil } from "react-icons/bi";
import { useContext, useState } from "react";
import { EditProductModal } from "./modals/EditProductModal";
import { AppContext } from "../pages/AppContainer";

export function AdminProductItem(props) {
  const product = props.product;
  const [editModalIsOpen, setEditIsOpen] = useState(false);
  const { currentUser } = useContext(AppContext);

  return (
    <>
      <div
        style={{
          background: "white",
          padding: "10px 10px",
          boxShadow: "0px 8px 20px #00000014",
          borderRadius: "15px",
          width: "15vw",
          height: "15vw",
          minWidth: "200px",
          minHeight: "200px",
          margin: "10px 0",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ position: "relative", width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <p
                style={{
                  padding: "0",
                  margin: "0",
                  fontSize: "16px",
                  fontWeight: "600",
                  color: product.live ? "#F0142F" : "#F8C28A",
                }}
              >
                {product.live ? "LIVE" : "DRAFT"}
              </p>
            </div>
            {currentUser?.role > 1 && (
              <div
                style={{
                  position: "absolute",
                  right: "0",
                  top: "0",
                }}
              >
                <button onClick={() => setEditIsOpen(true)}>
                  <BiPencil size={20} style={{ color: "#D7DBEC" }} />
                </button>
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justiftContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                padding: "0",
                margin: "0",
                fontSize: "25px",
                lineHeight: "30px",
                fontWeight: "600",
              }}
            >
              {product.numLessons}{" "}
              {product.numLessons !== 1 ? "Lessons" : "Lesson"}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "start",
                alignContent: "start",
                marginTop: "30px",
              }}
            >
              <p
                style={{
                  padding: "0",
                  margin: "0",
                  fontSize: "30px",
                  lineHeight: "30px",
                  color: "#45CDCF",
                  fontWeight: "600",
                }}
              >
                $
              </p>
              <NumberFormat
                value={product.price * 0.01}
                displayType="text"
                thousandSeparator={true}
                prefix=""
                style={{
                  padding: "0",
                  margin: "0",
                  fontSize: "50px",
                  lineHeight: "40px",
                  color: "#45CDCF",
                  fontWeight: "800",
                }}
              />
            </div>{" "}
            { product.expireDays > 0 &&
              <div style={{marginTop: '25px'}}>
                Expire <span style={{fontWeight: 'bold'}}>{product.expireDays}</span> Days After Purchase
              </div>
            }
            { product.expireDate !== "" &&
              <div style={{marginTop: '25px'}}>
                Expire on <span style={{fontWeight: 'bold'}}>{format(new Date(product.expireDate), "MMM dd, yyyy")}</span>
              </div>
            }

          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <p
              style={{
                padding: "0",
                margin: "0",
                fontSize: "10px",
                color: "#B2B2B2",
              }}
            >
              Last edited{" "}
              {format(
                new Date(0).setUTCSeconds(product.updatedAt),
                "MMM dd, yyyy"
              )}
            </p>
          </div>
        </div>
      </div>
      <EditProductModal
        isOpen={editModalIsOpen}
        product={product}
        onProductUpdated={props.onProductUpdated}
        onRequestClose={() => {
          setEditIsOpen(false);
        }}
        onProductArchived={() => {
          props.onProductUpdated();
          setEditIsOpen(false);
        }}
      />
    </>
  );
}
