import {format} from "date-fns";
import TotalLessonsRemaining from "../../components/TotalLessonsRemaining";
import ExpiringLessonsRemaining from "../../components/ExpiringLessonsRemaining";

export default function ExpiringParentDetails({parent}) {

  const sortedBalances = parent.expiringLessonBalances.sort((a, b) => new Date(a.expiresAt) - new Date(b.expiresAt));

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      gap: "30px",
      alignContent: "center",
      width: "100%"
    }}>
      <div style={{fontSize: 32, fontWeight: "bold"}}>{parent.lastName}, {parent.firstName}</div>

      <div style={{display: "flex", flexDirection: "row", gap: "20px"}}>
        <ExpiringLessonsRemaining user={parent} />
        <TotalLessonsRemaining user={parent} />
      </div>

      <table style={{width: "100%", fontSize: 18}}>
        <tr>
          <th>Balance</th>
          <th>Expires</th>
        </tr>
        {sortedBalances.map((lb) => (
            <tr key={lb._id}>
              <td>{lb.balance}</td>
              <td>{format(new Date(lb.expiresAt), "MMM dd, yyyy")}</td>
            </tr>
          )
        )}
        {parent.lessonBalance > 0 && (
          <tr key={'non_exp'}>
            <td>{parent.lessonBalance}</td>
            <td>Does Not Expire</td>
          </tr>
        )}
      </table>
    </div>
  )
}