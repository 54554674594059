/* eslint-disable react-hooks/exhaustive-deps */
import Modal from "react-modal";
import {ImCross} from "react-icons/im";
import {useContext, useEffect, useState} from "react";
import {AppButton} from "../AppButton";
import {useSnackbar} from "notistack";
import "react-datepicker/dist/react-datepicker.css";
import {BsPersonCircle} from "react-icons/bs";
import {getAge, reqHeaders, studentInformationComplete} from "../../utils";
import {AppContext} from "../../pages/AppContainer";
import ProgressBar from "@ramonak/react-progress-bar";
import {CircularProgress, Grid} from "@mui/material";
import {Tag} from "../Tag";
import TotalLessonsRemaining from "../TotalLessonsRemaining";
import ExpiringLessonsRemaining from "../ExpiringLessonsRemaining";

export function BookLessonModal(props) {
  const { currentUser, getCurrentUser, fetchUpcomingLessons } = useContext(AppContext);
  const [fetchStudentsLoading, setFetchStudentsLoading] = useState(false);
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [students, setStudents] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  async function fetchStudents() {
    setFetchStudentsLoading(true);
    try {
      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/students/by-parent/${currentUser._id}`,
        {
          method: "GET",
          headers: reqHeaders,
        }
      );
      const res = await req.json();
      // Order by last name
      if (res.data) {
        res.data.sort(function (a, b) {
          if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) return -1;
          if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) return 1;
          return 0;
        });
        setStudents(res.data);
      }
    } catch (err) {
      console.debug(err);
      setFetchStudentsLoading(false);
    }
    setFetchStudentsLoading(false);
  }

  useEffect(() => {
    fetchStudents();
  }, []);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "20px",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      backdropFilter: "blur(2px)",
    },
  };

  async function purchaseLesson(student) {
    setPurchaseLoading(true);
    const date = props.selectedDate;
    const time = props.selectedTime;
    const instructorId = props.selectedInstructor;
    const minimumChildAge = props.selectedMinimumChildAge;

    if (!date || !time || !student || !instructorId) {
      setPurchaseLoading(false);
      displayMessage(
        "Something went wrong. Please refresh the page and try again.",
        "error"
      );
      return;
    }

    if (!!minimumChildAge) {
      const birthdate = new Date(student?.birthdate);
      const studentAge = getAge(birthdate);
      if (studentAge < minimumChildAge) {
        setPurchaseLoading(false);
        displayMessage(
          `This instructor only teaches ages >= ${minimumChildAge}`,
          "error"
        );
        return;
      }
    }

    try {
      const req = await fetch(`${process.env.REACT_APP_API_URL}/lessons`, {
        method: "POST",
        headers: reqHeaders,
        body: JSON.stringify({
          studentId: student._id,
          instructorId: instructorId,
          studentName: `${student?.firstName} ${student?.lastName}`.trimEnd(),
          // locationId: 0, // Later feature?
          startMinuteOfDay: time,
          date: date,
        }),
      });
      if (req.status === 200) {
        displayMessage("The lesson has been booked", "success");
        props.onLessonBooked();
        await getCurrentUser();
        setStep(2);
        fetchUpcomingLessons();
      } else {
        const res = await req.json();
        setPurchaseLoading(false);
        if (res.message === "The user's parent doesn't have any lessons left") {
          displayMessage(
            "You don't have any lessons remaining. Purchase more lessons to book another.",
            "error"
          );
          return;
        }
        displayMessage(
          "Something went wrong. Please refresh the page and try again.",
          "error"
        );
      }
    } catch (err) {
      setPurchaseLoading(false);
      console.debug(err);
      displayMessage(
        "Something went wrong. Please refresh the page and try again.",
        "error"
      );
    }
    setPurchaseLoading(false);
  }

  const displayMessage = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
    });
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={() => {
        setStep(1);
        props.onRequestClose();
      }}
      style={customStyles}
      ariaHideApp={false}
      onAfterClose={props.onAfterClose}
    >
      <div
        style={{
          width: "70vw",
          minWidth: "400px",
          height: "55vh",
          padding: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "35%",
              display: "flex",
              flexDirection: "column",
              marginTop: "10px",
            }}
          >
            <ProgressBar
              height="17px"
              isLabelVisible={false}
              baseBgColor="#0000002F"
              bgColor="#F8C28A"
              completed={(step === 1 ? 2 / 3 : 1) * 100}
            />
            <p style={{ color: "#454545", fontSize: "10px", marginTop: "3px" }}>
              Step {step === 1 ? "2" : "3"} of 3
            </p>
          </div>

          <TotalLessonsRemaining />

          <ExpiringLessonsRemaining />

          <AppButton
            onClick={() => {
              setStep(1);
              props.onRequestClose();
            }}
            outline={true}
            title="Buy More Lessons"
            fontSize={"14px"}
          />
          <button
            onClick={() => {
              setStep(1);
              props.onRequestClose();
            }}
          >
            <ImCross size={12} style={{ color: "#B2AEAE" }} />
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "70px",
          }}
        >
          <p style={{ fontWeight: "600", fontSize: "30px" }}>
            {step === 1
              ? "Which student are you booking for?"
              : "Great! Just a few reminders..."}
          </p>
          <div style={{ width: "70%", marginBottom: "50px" }}>
            {step === 1 ? (
              <>
                {!purchaseLoading && !fetchStudentsLoading ? (
                  <Grid container justifyContent="space-around">
                    {students.map((student) => (
                      <div key={student._id}>
                        <StudentItem
                          student={student}
                          onSelectPressed={() => {
                            purchaseLesson(student);
                          }}
                        />
                      </div>
                    ))}
                  </Grid>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress
                      size={26}
                      style={{ color: "black", marginTop: "100px" }}
                    />
                  </div>
                )}
              </>
            ) : (
              <div className="col">
                <div className="row" style={{ marginBottom: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <p className="reminder-num">1.</p>
                  </div>
                  <div style={{ flex: 10 }}>
                    <p className="reminder-text">
                      Please arrive a few minutes early and wait by the pool
                      so your child is ready when the lesson starts and you
                      have the chance to talk to your instructor.
                    </p>
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <p className="reminder-num">2.</p>
                  </div>
                  <div style={{ flex: 10 }}>
                    <p className="reminder-text">
                      If you need to cancel a lesson more than 24 hours in advance,
                      you can do so by selecting it on the "Calendar" tab of your
                      account. Lessons canceled more than 24 hours in advance will
                      be credited back to your account for you to reschedule.
                    </p>
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <p className="reminder-num">3.</p>
                  </div>
                  <div style={{ flex: 10 }}>
                    <p className="reminder-text">
                      We do not credit back lessons that are canceled less than
                      24 hours in advance unless you find another student to fill
                      that slot. You can post last minute cancellations on our
                      parent Facebook page (MWSwimming Parents) to try to find
                      people to take lessons you will not be able to make.
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "30px",
                  }}
                >
                  <AppButton
                    onClick={() => {
                      setStep(1);
                      props.onRequestClose();
                    }}
                    title="Book Another Lesson"
                    outline={true}
                    fontSize="14px"
                    margin="0 10px 0 0"
                  />
                  <AppButton
                    onClick={() => {
                      setStep(1);
                      props.onRequestClose();
                      props.onFinishBooking();
                    }}
                    title="Finish Booking"
                    fontSize="14px"
                    margin="0 0 0 10px"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

function StudentItem(props) {
  const student = props.student;
  const waiverComplete = !!student?.waiverSignedBy;
  const infoComplete = studentInformationComplete(student);

  return (
    <Grid item>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 10px",
          marginBottom: "20px",
        }}
      >
        {!student?.profilePhoto ? (
          <BsPersonCircle size={120} style={{ color: "#DEDEDE" }} />
        ) : (
          <img
            src={student?.profilePhoto ?? ""}
            alt=""
            style={{
              width: "120px",
              height: "120px",
              borderRadius: "100px",
              objectFit: "cover",
            }}
          />
        )}
        <p style={{ fontWeight: "600", fontSize: "15px", marginTop: "10px" }}>
          {student.firstName} {student?.lastName}
        </p>

        {(!waiverComplete || !infoComplete) ? (
            <>
              <Tag label="Incomplete" fontSize="12px" width="150px" />
              <p style={{ textAlign: "center", color: "red", marginTop: '10px', fontSize: '10px', maxWidth: '75%', overflowWrap: 'normal' }}>
                * Student waiver & documentation required
              </p>
            </>
          ) : (
          <AppButton
            onClick={props.onSelectPressed}
            title="Select"
            loading={null}
            fontSize={"12px"}
            padding="5px 40px"
          />
        )}
      </div>
    </Grid>
  );
}
