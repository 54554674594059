import { useSnackbar } from "notistack";
import { useState } from "react";
import { reqHeaders } from "../../utils";
import { AppButton } from "../AppButton";

export default function ChangePassword() {
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const [savePasswordLoading, setSavePasswordLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  async function savePassword() {
    try {
      setSavePasswordLoading(true);
      if (!oldPassword || !newPassword || !confirmPassword) {
        setSavePasswordLoading(false);
        return displayMessage("You must fill in each password field", "error");
      }
      if (newPassword !== confirmPassword) {
        setSavePasswordLoading(false);
        return displayMessage("Passwords don't match", "error");
      }
      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/users/change-password`,
        {
          method: "PUT",
          headers: reqHeaders,
          body: JSON.stringify({
            oldPassword: oldPassword,
            newPassword: newPassword,
          }),
        }
      );

      if (req.status === 200) {
        displayMessage("Your password has been updated", "success");
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setSavePasswordLoading(false);
      } else {
        setSavePasswordLoading(false);
        displayMessage(
          "Something went wrong. Make sure your old password is correct.",
          "error"
        );
      }
    } catch (err) {
      console.debug(err);
    }
  }

  const displayMessage = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
    });
  };

  return (
    <div
      style={{
        background: "white",
        padding: "40px",
        boxShadow: "0px 4px 15px #00000014",
        borderRadius: "23px",
        margin: "10px 0",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
        }}
      >
        <p
          style={{
            margin: "0",
            padding: "0",
            fontSize: "18px",
            fontWeight: "600",
            marginBottom: 0,
          }}
        >
          Change Password
        </p>
        <p className="inputLabel" style={{ marginTop: "15px" }}>
          Old Password
        </p>
        <input
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          type="password"
          placeholder="Type Old Password"
          className="login-input"
          id="old-password"
        />
        <p className="inputLabel" style={{ marginTop: "15px" }}>
          New Password
        </p>
        <input
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          type="password"
          placeholder="Type New Password"
          className="login-input"
          id="new-password"
        />
        <p className="inputLabel" style={{ marginTop: "15px" }}>
          Confirm New Password
        </p>
        <input
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          type="password"
          placeholder="Confirm New Password"
          className="login-input"
          id="confirm-password"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            marginTop: "30px",
          }}
        >
          <AppButton
            loading={savePasswordLoading}
            onClick={savePassword}
            title="Save"
            width="30%"
            fontSize="14px"
            margin="0 0 0 10px"
          />
        </div>
      </div>
    </div>
  );
}
