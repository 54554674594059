import { Button, CircularProgress } from "@mui/material";
import { BsFillPlusCircleFill } from "react-icons/bs";

export function AppButton(props) {
  return (
    <Button
      onClick={props.onClick}
      disabled={props.loading || props.disabled}
      style={{
        width: props.width ?? null,
        background: props.outline
          ? "transparent"
          : props.color
          ? props.color
          : "#45CDCF",
        border: props.outline
          ? props.color
            ? `2px solid ${props.color}`
            : "2px solid #45CDCF"
          : "2px solid transparent",
        textTransform: "none",
        padding: props.padding ?? "12px 10px",
        margin: props.margin ?? "0",
        borderRadius: props.borderRadius ?? "30px",
        height: props.height ?? "40px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.showPlus && (
          <BsFillPlusCircleFill
            size={props.plusSize ? props.plusSize : 24}
            style={{ color: "white", marginRight: "10px" }}
          />
        )}
        {!props.loading ? (
          <p
            style={{
              color: props.outline
                ? props.color
                  ? props.color
                  : "#45CDCF"
                : "white",
              fontSize: props.fontSize ?? "16px",
              fontWeight: props.fontWeight ?? "400",
              paddingTop: "0px",
            }}
          >
            {props.title}
          </p>
        ) : (
          <CircularProgress
            size={props.loadingIndicatorSize ?? 18}
            style={{
              color: props.loadingIndicatorColor ?? "white",
            }}
          />
        )}
      </div>
    </Button>
  );
}
