import Modal from "react-modal";
import { ImCross } from "react-icons/im";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { getTimeStringFromSeconds, reqHeaders } from "../../utils";
import { AppButton } from "../AppButton";
import { useState } from "react";
import { useSnackbar } from "notistack";

export function ConfirmCancelLessonModal(props) {
  const { lesson, onLessonCanceled, isOpen, onRequestClose } = props;
  const [cancelLoading, setCancelLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const displayMessage = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
    });
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "20px",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      backdropFilter: "blur(2px)",
    },
  };

  async function cancelLesson() {
    try {
      setCancelLoading(true);
      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/lessons/cancel`,
        {
          method: "PUT",
          headers: reqHeaders,
          body: JSON.stringify({
            lessonId: lesson.lessonId,
          }),
        }
      );
      if (req.status === 200) {
        displayMessage(
          "The lesson has been canceled and a lesson has been added back to the user's account balance.",
          "success"
        );
        onLessonCanceled();
      } else {
        displayMessage("Failed to cancel lesson", "error");
      }
    } catch (err) {
      console.debug(err);
      displayMessage("Failed to cancel lesson", "error");
      setCancelLoading(false);
    }
    setCancelLoading(false);
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      ariaHideApp={false}
    >
      <div style={{ width: "40vw", minWidth: "400px", padding: "20px" }}>
        <div style={{ textAlign: "end" }}>
          <button onClick={onRequestClose}>
            <ImCross size={12} style={{ color: "#B2AEAE" }} />
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            marginRight: "5px",
            padding: "0 50px",
          }}
        >
          <p
            style={{ fontWeight: "600", fontSize: "18px", textAlign: "center" }}
          >
            {`Are you sure you want to cancel ${
              lesson?.studentName.split(" ")[0] ?? ""
            }'s lesson on ${
              lesson?.date
                ? format(
                    new Date(lesson.date).setDate(
                      new Date(lesson.date).getDate() + 1
                    ),
                    "MMMM d"
                  )
                : "unknown date"
            } at ${getTimeStringFromSeconds(lesson?.startMinuteOfDay)}?`}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 30,
          }}
        >
          <AppButton
            onClick={onRequestClose}
            outline={true}
            title="Back"
            fontSize={"14px"}
            width="20%"
            margin="0 10px 0 10px"
          />

          <AppButton
            onClick={cancelLesson}
            title="Cancel Lesson"
            loading={cancelLoading}
            fontSize={"14px"}
            margin="0 10px 0 10px"
            color="#DD3539"
          />
        </div>
      </div>
    </Modal>
  );
}
