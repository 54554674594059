import { useEffect, useState } from "react";
import { reqHeaders } from "../../utils";
import { AppButton } from "../AppButton";
import { useSnackbar } from "notistack";
import DatePicker from "react-datepicker";
import { CircularProgress } from "@mui/material";

export function BillingInformation(props) {
  const [loading, setLoading] = useState(false);
  const [cardNumber, setCardNumber] = useState();
  const [cvc, setCvc] = useState("");
  const [expDate, setExpDate] = useState(new Date());
  const [last4, setLast4] = useState();

  const [cardInfoLoading, setCardInfoLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchCardInformation();
  }, []);

  async function fetchCardInformation() {
    setCardInfoLoading(true);
    try {
      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/users/payment`,
        {
          method: "GET",
          headers: reqHeaders,
        }
      );
      const res = await req.json();
      console.debug(res);
      if (res.card && res.card.last4) {
        setLast4(res.card.last4);
        const date = new Date();
        date.setMonth(res.card.expMonth - 1);
        date.setFullYear(res.card.expYear);
        setExpDate(date);
      }
      setCardInfoLoading(false);
    } catch (err) {
      console.debug("Fetch card error: ", err);
      setCardInfoLoading(false);
    }
  }

  async function updateCardInformation() {
    try {
      setLoading(true);
      const card = cardNumber.replaceAll(" ", "");
      console.debug(card);
      if (!card || card.length !== 16) {
        displayMessage("Card length must be 16 characters", "error");
        return;
      }
      if (!cvc || cvc.length < 3 || cvc.length > 4) {
        displayMessage("Invalid CVC", "error");
        return;
      }
      if (expDate < new Date()) {
        displayMessage("Card is expired", "error");
        return;
      }
      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/users/payment`,
        {
          method: "POST",
          headers: reqHeaders,
          body: JSON.stringify({
            cardNumber: card,
            cvc: cvc,
            expMonth: expDate.getMonth() + 1, // getMonth is by index
            expYear: expDate.getFullYear(),
          }),
        }
      );
      setLoading(false);
      if (req.status === 200) {
        fetchCardInformation();
        return displayMessage("Your card has been updated", "success");
      }
      return displayMessage(
        "Something went wrong. Please check your card information.",
        "error"
      );
    } catch (err) {
      setLoading(false);
      console.log(err);
      return displayMessage(
        "Something went wrong. Please check your card information.",
        "error"
      );
    }
  }

  const displayMessage = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
    });
  };

  return (
    <div
      style={{
        background: "white",
        padding: "20px",
        boxShadow: "0px 4px 15px #00000014",
        borderRadius: "23px",
        margin: "10px 0",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            width: "40%",
          }}
        >
          <p
            style={{
              margin: "0",
              padding: "0",
              fontSize: "18px",
              fontWeight: "600",
              marginBottom: "15px",
            }}
          >
            Billing Information
          </p>
          {cardInfoLoading && (
            <CircularProgress
              size={14}
              style={{ color: "black", marginBottom: "20px" }}
            />
          )}
          <p
            style={{
              margin: "0",
              padding: "0 20px 0 0",
              fontSize: "14px",
              color: "#7E84A3",
            }}
          >
            To edit your billing information, change any of the information in
            the fields and click “save”.
          </p>
          {last4 && (
            <p
              style={{
                margin: "0",
                padding: "0 20px 0 0",
                fontSize: "12px",
                color: "#7E84A3",
                marginTop: 10,
              }}
            >
              You have a card saved that ends in{" "}
              <span style={{ fontWeight: 600 }}>{last4}</span>
            </p>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "60%",
          }}
        >
          <p className="inputLabel">Card Number</p>
          <input
            value={cardNumber}
            autoComplete="cc-number"
            onChange={(e) =>
              setCardNumber(
                e.target.value
                  .replace(/\W/gi, "")
                  .replace(/(.{4})/g, "$1 ")
                  .trim()
              )
            }
            type="text"
            placeholder="**** **** **** 1234"
            className="login-input no-icon"
            style={{ color: "#406c8c" }}
            inputMode="numeric"
            pattern="[0-9\s]{13,19}"
            maxLength="19"
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "48%",
              }}
            >
              <p className="inputLabel">CVC</p>
              <input
                value={cvc}
                onChange={(e) => {
                  setCvc(e.target.value);
                }}
                type="text"
                placeholder="XXX"
                className="login-input no-icon"
                style={{ color: "#406c8c" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "48%",
              }}
            >
              <p className="inputLabel">Expiration</p>
              <DatePicker
                showMonthYearPicker
                selected={expDate}
                onChange={(newDate) => setExpDate(newDate)}
                placeholderText="04/2028"
                dateFormat={"MM/yyyy"}
                style={{
                  border: "2px solid red",
                }}
                id="card-exp-picker"
              />
            </div>
          </div>
          <div
            style={{
              textAlign: "end",
              marginTop: "20px",
            }}
          >
            <AppButton
              onClick={updateCardInformation}
              title="Save"
              loading={loading}
              fontSize={"14px"}
              width="30%"
              margin="10px 0 10px 0"
              padding="7px"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
