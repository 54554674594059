import { format } from "date-fns";
import { useEffect, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { reqHeaders } from "../../utils";
import PaginationControl from "../PaginationControl";

export function AllNotificationsPanel(props) {
  const [notifications, setNotifications] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchNotifications(0);
  }, []);

  async function fetchNotifications(newCurrentPage) {
    try {
      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/notifications?page=${newCurrentPage}&pageSize=7`,
        {
          method: "GET",
          headers: reqHeaders,
        }
      );
      const res = await req.json();
      setTotalPages(res.pageCount);

      if (res.data) {
        const notis = res.data;
        setNotifications(notis);
      }
    } catch (err) {
      console.debug("Notifications error: ", err);
    }
  }

  function previousPressed() {
    const newCurrentPage = currentPage - 1;
    setCurrentPage(newCurrentPage);
    fetchNotifications(newCurrentPage);
  }

  function nextPressed() {
    const newCurrentPage = currentPage + 1;
    setCurrentPage(newCurrentPage);
    fetchNotifications(newCurrentPage);
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div style={{ display: "flex", justifyContent: "end" }}>
          <button onClick={props.onRequestClose}>
            <GrFormClose size={28} />
          </button>
        </div>
        <p style={{ fontSize: "26px", fontWeight: "600", marginTop: "20px" }}>
          Notifications
        </p>
        {notifications?.length > 0 && (
          <>
            {notifications.map((noti, index) => (
              <div
                key={index}
                style={{
                  width: 250,
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                }}
              >
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  {noti.title}
                </p>
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: 12,
                    color: "#45CDCF",
                    fontWeight: 400,
                  }}
                >
                  {format(new Date(noti.createdAt), "MMM dd, yyyy")}
                </p>
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: 11,
                    fontWeight: 400,
                    marginTop: 10,
                  }}
                >
                  {noti.content}
                </p>
                {index !== notifications.length - 1 && (
                  <div
                    style={{
                      height: 1,
                      width: "100%",
                      margin: "10px 0",
                      background: "#D7DBEC",
                    }}
                  ></div>
                )}
              </div>
            ))}
            <PaginationControl
              totalPages={totalPages}
              currentPage={currentPage}
              onPreviousPressed={previousPressed}
              onNextPressed={nextPressed}
              addTopMargin={true}
            />
          </>
        )}
        {!notifications ||
          (notifications.length < 1 && (
            <p>You don't have any notifications yet</p>
          ))}
      </div>
    </>
  );
}
