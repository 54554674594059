import Modal from "react-modal";
import { ImCross } from "react-icons/im";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { isNil } from "../../utils";
import {GrAttachment} from "react-icons/all";

export function ViewAnnouncementModal(props) {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "20px",
      maxHeight: "80vh"
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      backdropFilter: "blur(2px)",
    },
  };

  const displayDate = isNil(props.announcement.updatedAt)
    ? Date()
    : new Date(props.announcement.updatedAt);

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      style={customStyles}
      ariaHideApp={false}
    >
      <div style={{ width: "50vw", minWidth: "400px", padding: "20px" }}>
        <div style={{ textAlign: "end" }}>
          <button onClick={props.onRequestClose}>
            <ImCross size={12} style={{ color: "#B2AEAE" }} />
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "20px",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "60%",
              marginRight: "5px",
            }}
          >
            <p
              className="edit-label"
              style={{ fontWeight: "600", fontSize: "20px" }}
            >
              {props.announcement?.title ?? ""}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <p className="edit-label">
              {format(new Date(displayDate), "MMM dd, yyyy")}
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <p className="edit-label" style={{whiteSpace: "pre-wrap"}}>{props.announcement?.content ?? ""}</p>
        </div>
        {props.announcement.attachments.length > 0 && (
          <div>
            <p
              className="edit-label"
              style={{ fontWeight: "600", fontSize: "20px" }}
            >
              Attachments <GrAttachment />
            </p>
            <ul>
              {props.announcement.attachments.map((f) => (
                <li key={f.id}><a href={process.env.REACT_APP_API_URL + "/files/" + f.id} target="_blank" rel="noreferrer" download>{f.name}</a></li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Modal>
  );
}
