import DatePicker from "react-datepicker";
import {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {Divider} from "@mui/material";
import {format, isAfter} from "date-fns";
import {TiDeleteOutline} from "react-icons/all";
import {DayItem} from "./DayItem";
import {DayTimeSelector} from "./DayTimeSelector";
import {Tag} from "./Tag";

export default function ScheduleEdit({onSave, schedule} ) {
  const {enqueueSnackbar} = useSnackbar();
  const [startDate, setStartDate] = useState(schedule?.startDate ?? "");
  const [endDate, setEndDate] = useState(schedule?.endDate ?? "");
  const [daysOff, setDaysOff] = useState(schedule?.daysOff ?? []);
  const [shifts, setShifts] = useState(schedule?.shifts ?? []);
  const days = [0, 1, 2, 3, 4, 5, 6];
  const isEditing = !!(schedule?._id);

  useEffect(() => {
    setStartDate(schedule?.startDate ?? "");
    setEndDate(schedule?.endDate ?? "");
    setDaysOff(schedule?.daysOff ?? []);
    setShifts(schedule?.shifts ?? []);
  },[schedule]);

  const displayMessage = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
    });
  };

  const addDayOff = (day) => {
    let epoch = day.getTime()
    if (!daysOff.map(d => d.getTime()).includes(epoch)) {
      setDaysOff([...daysOff, day].sort(sortDates));
    }
  }

  const removeDayOff = (index) => {
    const array = [...daysOff];
    array.splice(index, 1);
    setDaysOff(array);
  }

  const getShiftsForDay = (day) => {
    return shifts.filter(shift => shift.dayOfWeek === day)
  }

  const haveShiftsForDay = (day) => {
    return getShiftsForDay(day).length > 0
  }

  const sortShifts = (a, b) => {
    let daySort = a.dayOfWeek - b.dayOfWeek;
    if (daySort !== 0) {
      return daySort;
    }
    return a.startMinute - b.startMinute;
  }

  const sortDates = (a, b) => {
    return a.getTime() - b.getTime();
  }

  const selectDay = (day) => {
    if (haveShiftsForDay(day)) {
      let filtered = shifts.filter(shift => shift.dayOfWeek !== day);
      setShifts(filtered);
    } else {
      setShifts([...shifts, { dayOfWeek: day, startMinute: 540, endMinute: 1020 }].sort(sortShifts));
    }
  }

  const updateDayShifts = (day, updated) => {
    const otherShifts = shifts.filter(shift => shift.dayOfWeek !== day)
    const sorted = [...otherShifts, ...updated].sort(sortShifts);
    setShifts(sorted);
  }

  const saveSchedule = () => {
    if (startDate === "") {
      displayMessage("Please provide a start date", "error");
      return;
    }

    if (endDate === "") {
      displayMessage("Please provide a end date", "error");
      return;
    }

    if (isAfter(startDate, endDate)) {
      displayMessage("End date must come after start date", "error");
      return;
    }

    if (shifts.length === 0) {
      displayMessage("Provide at least 1 shift", "error");
      return;
    }

    let updatedSchedule = {
      startDate: startDate,
      endDate: endDate,
      daysOff: daysOff,
      shifts: shifts
    }
    onSave(updatedSchedule, schedule?._id);
  }

  return (
    <div style={{backgroundColor: '#eeeeee', padding: '10px', marginTop: '10px'}}>
      <div
        className="row"
        style={{ justifyContent: "start", width: "100%" }}
      >
        <div className="col" style={{ width: "50%" }}>
          <p className="edit-label">Start Date</p>
          <DatePicker
            selected={startDate}
            placeholderText="xx/xx/xxxx"
            id="schedule-date-picker"
            onChange={(newDate) => setStartDate(newDate)}
            style={{ width: "inherit", padding: "9px 30px" }}
          />
        </div>
        <div className="col" style={{ width: "50%" }}>
          <p className="edit-label">End Date</p>
          <DatePicker
            selected={endDate}
            placeholderText="xx/xx/xxxx"
            id="schedule-date-picker"
            onChange={(newDate) => { setEndDate(newDate) }}
            style={{ width: "inherit", padding: "9px 30px" }}
          />
        </div>
      </div>
      <p className="edit-label" style={{ marginTop: "10px" }}>
        Days Off ({daysOff.length})
      </p>
      <Divider />

      <div>
        <DatePicker
          placeholderText="xx/xx/xxxx"
          id="schedule-date-picker"
          onChange={(newDate) => { addDayOff(newDate) }}
          style={{ width: "inherit", padding: "9px 30px" }}
          minDate={startDate !== "" ? startDate : null}
          maxDate={endDate !== "" ? endDate : null}
        />
      </div>

      {daysOff.map((day, idx) => (
        <div className='row' key={`daysoff_${idx}`} style={{ alignItems: 'center' }}>
          <div>
            {format(day, "MM/dd/yyyy")}
          </div>
          <div style={{marginLeft: '10px'}}>
            <button onClick={() => removeDayOff(idx)}>
              <TiDeleteOutline size={24} color='red' />
            </button>
          </div>
        </div>
      ))}

      <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: '10px',
          marginBottom: '10px'
        }}
      >
        {days.map(day =>
          <DayItem key={`daysitem_${day}`} day={day}
                   onDayPressed={() => selectDay(day)}
                   isSelected={haveShiftsForDay(day)}
          />
        )}
      </div>

      <div className='col'>
        {days.map(day =>
        haveShiftsForDay(day) &&
          <DayTimeSelector key={`dayselector_${day}`} day={day}
            shifts={getShiftsForDay(day)}
            onShiftsUpdated={(updatedShifts) => updateDayShifts(day, updatedShifts)}
            onCloseDay={() => selectDay(day)}
          />
        )}
      </div>

      <div style={{width: '100%', textAlign: 'center'}}>
        <button onClick={() => saveSchedule()}>
          <Tag
            label={isEditing ? "Update Schedule" : "Add Schedule"}
            fontSize="12px"
            width="150px"
            background="#21D59B"
          />
        </button>
      </div>

    </div>
  );
}