import NumberFormat from "react-number-format";
import { AppButton } from "./AppButton";
import {format} from "date-fns";

export function ProductItem(props) {
  const product = props.product;

  return (
    <>
      <div
        style={{
          background: "white",
          padding: "10px 10px",
          boxShadow: "0px 8px 20px #00000014",
          borderRadius: "15px",
          width: "15vw",
          height: "15vw",
          minWidth: "200px",
          minHeight: "200px",
          maxWidth: "250px",
          maxHeight: "250px",
          margin: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              padding: "0",
              margin: "0",
              fontSize: "25px",
              lineHeight: "30px",
              fontWeight: "600",
            }}
          >
            {product.numLessons}{" "}
            {product.numLessons !== 1 ? "Lessons" : "Lesson"}
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "start",
              alignContent: "start",
              margin: "30px 0",
            }}
          >
            <p
              style={{
                padding: "0",
                margin: "0",
                fontSize: "30px",
                lineHeight: "30px",
                color: "#45CDCF",
                fontWeight: "600",
              }}
            >
              $
            </p>
            <NumberFormat
              value={product.price * 0.01}
              displayType="text"
              thousandSeparator={true}
              prefix=""
              style={{
                padding: "0",
                margin: "0",
                fontSize: "50px",
                lineHeight: "40px",
                color: "#45CDCF",
                fontWeight: "800",
              }}
            />
          </div>

          { product.expireDays > 0 &&
            <div style={{marginBottom: '15px'}}>
              Expire <span style={{fontWeight: 'bold'}}>{product.expireDays}</span> Days After Purchase
            </div>
          }
          { product.expireDate !== "" &&
            <div style={{marginBottom: '15px'}}>
              Expire on <span style={{fontWeight: 'bold'}}>{format(new Date(product.expireDate), "MMM dd, yyyy")}</span>
            </div>
          }

          <AppButton
            onClick={props.onPurchasePressed}
            title="Buy Lessons"
            loading={null}
            fontSize={"12px"}
            margin="0 10px 0 10px"
            padding="8px 30px"
          />
        </div>
      </div>
    </>
  );
}
