/* eslint-disable react-hooks/exhaustive-deps */
import Modal from "react-modal";
import { ImCross } from "react-icons/im";
import { useContext, useEffect, useState } from "react";
import { AppButton } from "../AppButton";
import { useSnackbar } from "notistack";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ChooseParentPanel } from "./ChooseParentPanel";
import SlidingPane from "react-sliding-pane";
import { studentInformationComplete, reqHeaders, states } from "../../utils";
import { AppContext } from "../../pages/AppContainer";
import { Tag } from "../Tag";
import { Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

export function StudentInformationModal(props) {
  const { currentUser, fetchParents } = useContext(AppContext);
  const student = props.student ?? null;

  const [saveLoading, setSaveLoading] = useState(false);
  const [chooseParentPanelOpen, setChooseParentPanelOpen] = useState(false);
  const [selectedParent, setSelectedParent] = useState();
  const [name, setName] = useState(getInitialName());
  const [studentInformationSaveDate, setStudentInformationSaveDate] = useState(
    getInitialDate()
  );
  const [gender, setGender] = useState(student.gender || 0);
  const [addressLine1, setAddressLine1] = useState(
    student.address?.addressLine1 ?? ""
  );
  const [addressLine2, setAddressLine2] = useState(
    student.address?.addressLine2 ?? ""
  );
  const [city, setCity] = useState(student.address?.city ?? "");
  const [selectedState, setSelectedState] = useState(getInitialState());
  const [zip, setZip] = useState(student.address?.zip ?? "");
  const [experience, setExperience] = useState(
    student.questionnaire?.experience
  );
  const [learningStyle, setLearningStyle] = useState(
    student.questionnaire?.learningStyle
  );
  const [goals, setGoals] = useState(student.questionnaire?.goals);
  const [parentQuestions, setParentQuestions] = useState(
    student.questionnaire?.questions
  );

  const currentUserIsParent = student?.parentUserId === currentUser._id;

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!currentUserIsParent) {
      fetchAndSetParent(student?.parentUserId);
    }
  }, []);

  useEffect(() => {
    setSelectedParent(student?.parentUserId);
  }, []);

  function getInitialName() {
    if (student?.firstName && student?.lastName) {
      return `${student?.firstName} ${student?.lastName}`;
    }
    return null;
  }

  function getInitialDate() {
    if (student?.studentInformationSaveDate) {
      return new Date(student?.studentInformationSaveDate);
    }
    return new Date();
  }

  function getInitialState() {
    if (states.includes(student.address?.state)) {
      return student.address?.state ?? "ID";
    }
    return "ID";
  }

  async function updateStudent() {
    // if (!edited) {
    //   displayMessage("Nothing was edited", "error");
    //   return;
    // }
    if (!name || name.length < 1) {
      setSaveLoading(false);
      displayMessage("Please enter a name", "error");
      return;
    }
    try {
      setSaveLoading(true);
      const names = name.split(" ");
      if (names.length < 2) {
        setSaveLoading(false);
        displayMessage("Please enter a first and last name", "error");
        return;
      }
      const firstName = names[0];
      // Remove first item of array
      const index = names.indexOf(firstName);
      if (index > -1) {
        names.splice(index, 1);
      }
      const lastName = names?.toString().replaceAll(",", " ") ?? "";

      const updatedStudent = {
        updatedStudent: {
          id: student._id,
          firstName: firstName,
          lastName: lastName ?? "",
          parentUserId: selectedParent._id,
          studentInformationSaveDate: studentInformationSaveDate,
          gender: gender ?? 0,
          address: {
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            city: city,
            state: selectedState,
            zip: zip,
          },
          questionnaire: {
            experience: experience,
            learningStyle: learningStyle,
            goals: goals,
            questions: parentQuestions,
          },
        },
      };
      const req = await fetch(`${process.env.REACT_APP_API_URL}/students`, {
        method: "PUT",
        headers: reqHeaders,
        body: JSON.stringify(updatedStudent),
      });

      if (req.status === 200) {
        props.onStudentUpdated();
        displayMessage("The student has been updated", "success");
        props.onRequestClose();
      } else {
        displayMessage("Something went wrong. Please try again.", "error");
      }
    } catch (err) {
      console.debug(err);
      displayMessage("Something went wrong. Please try again.", "error");
    }
    setSaveLoading(false);
  }

  function getGenderFromIndex() {
    switch (gender) {
      case 0: {
        return "Male";
      }
      case 1: {
        return "Female";
      }
      default: {
        return "Male";
      }
    }
  }

  async function fetchAndSetParent(parentId) {
    if (parentId) {
      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/users/parent?userId=${parentId}`,
        {
          method: "GET",
          headers: reqHeaders,
        }
      );
      const res = await req.json();
      setSelectedParent(res.user);
    }
  }

  const displayMessage = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
    });
  };

  //   const edited =
  //     name !== `${student?.firstName} ${student?.lastName}` ||
  //     birthdate.getDate() !== new Date(student?.birthdate).getDate() ||
  //     birthdate.getMonth() !== new Date(student?.birthdate).getMonth() ||
  //     birthdate.getFullYear() !== new Date(student?.birthdate).getFullYear() ||
  //     profileImage !== student?.profilePhoto ||
  //     selectedParent !== student?.selectedParent;

  const modalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "20px",
      maxHeight: "500px",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      backdropFilter: "blur(2px)",
    },
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      style={modalStyles}
      ariaHideApp={false}
      onAfterClose={props.onAfterClose}
    >
      <div style={{ width: "50vw", minWidth: "400px", padding: "10px" }}>
        <div style={{ textAlign: "end" }}>
          <button onClick={props.onRequestClose}>
            <ImCross size={12} style={{ color: "#B2AEAE" }} />
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <p style={{ fontSize: "20px", fontWeight: "600" }}>
            Student Information
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ margin: "0 10px 0 0", padding: "0", fontSize: "16px" }}>
              Status:{" "}
            </p>
            {!studentInformationComplete(student) ? (
              <Tag label="Incomplete" fontSize="12px" width="140px" />
            ) : (
              <Tag
                label="Complete"
                fontSize="12px"
                width="140px"
                background="#21D59B"
              />
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            // padding: "20px 15% 40px 15%",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: "50%" }}>
              <p className="edit-label" style={{ marginTop: "15px" }}>
                Student's Name
              </p>
              <div style={{}}>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  placeholder="Type Name"
                  className="login-input"
                  style={{ width: "100%" }}
                  id="first-name"
                  disabled={props.isInstructor}
                />
              </div>
            </div>
            <div style={{ width: "25%", margin: "0 10px" }}>
              <p className="edit-label" style={{ marginTop: "15px" }}>
                Gender
              </p>
              <div>
                <div
                  style={{
                    border: "2px solid #d7dbec",
                    padding: "8px 30px",
                    fontSize: "14px",
                    borderRadius: "30px",
                  }}
                >
                  <Dropdown
                    text={getGenderFromIndex()}
                    disabled={props.isInstructor}
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item
                        text="Male"
                        value={0}
                        onClick={(val) => setGender(0)}
                      />
                      <Dropdown.Item
                        text="Female"
                        value={1}
                        onClick={(val) => setGender(1)}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div style={{ width: "25%" }}>
              <p className="edit-label" style={{ marginTop: "15px" }}>
                Today's Date
              </p>
              <DatePicker
                selected={studentInformationSaveDate}
                placeholderText="xx/xx/xxxx"
                className="calendar-grey"
                onChange={(newDate) => setStudentInformationSaveDate(newDate)}
                style={{
                  border: "2px solid red",
                  width: "inherit",
                  padding: "9px 30px",
                }}
                disabled={props.isInstructor}
              />
            </div>
          </div>

          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                margin: "0 5px",
              }}
            >
              <p className="edit-label" style={{ marginTop: "15px" }}>
                Address
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "10px",
                }}
              >
                <input
                  value={addressLine1}
                  onChange={(e) => setAddressLine1(e.target.value)}
                  type="text"
                  placeholder="Street 1"
                  className="login-input no-icon"
                  style={{ width: "49%" }}
                  disabled={props.isInstructor}
                />
                <div style={{ width: "2%" }}></div>
                <input
                  value={addressLine2}
                  onChange={(e) => setAddressLine2(e.target.value)}
                  type="text"
                  placeholder="Street 2"
                  className="login-input no-icon"
                  style={{ width: "49%" }}
                  disabled={props.isInstructor}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    border: "2px solid #d7dbec",
                    padding: "8px 30px",
                    fontSize: "14px",
                    borderRadius: "30px",
                    width: "32%",
                  }}
                >
                  <Dropdown
                    text={selectedState}
                    scrolling
                    className="state-dropdown"
                    disabled={props.isInstructor}
                  >
                    <Dropdown.Menu>
                      {states &&
                        states.map((val) => (
                          <Dropdown.Item
                            key={val}
                            text={val}
                            value={val}
                            onClick={() => {
                              setSelectedState(val);
                            }}
                          />
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div style={{ width: "2%" }}></div>
                <input
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  type="text"
                  placeholder="City"
                  className="login-input no-icon"
                  style={{ width: "32%" }}
                  disabled={props.isInstructor}
                />
                <div style={{ width: "2%" }}></div>
                <input
                  value={zip}
                  onChange={(e) => setZip(e.target.value)}
                  type="text"
                  placeholder="Zip"
                  className="login-input no-icon"
                  style={{ width: "32%" }}
                  disabled={props.isInstructor}
                />
              </div>
            </div>
          </div>

          <p className="edit-label" style={{ marginTop: "15px" }}>
            Parent
          </p>
          {!selectedParent ? (
            <button
              onClick={() => {
                if (!props.isInstructor) setChooseParentPanelOpen(true);
              }}
              style={{ textAlign: "start" }}
            >
              <p style={{ marginTop: "0px", color: "#406C8C" }}>
                Select a parent
              </p>
            </button>
          ) : (
            <>
              {!currentUserIsParent && currentUser.role > 1 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignContent: "center",
                  }}
                >
                  <p style={{ margin: "0", padding: "0" }}>
                    {selectedParent.firstName} {selectedParent.lastName}{" "}
                    <span style={{ fontSize: "12px" }}>
                      ({selectedParent.email})
                    </span>
                  </p>
                  <button
                    onClick={() => setChooseParentPanelOpen(true)}
                    style={{ textAlign: "start", marginLeft: "20px" }}
                  >
                    <p
                      style={{
                        marginTop: "0px",
                        color: "#406C8C",
                        fontSize: "12px",
                      }}
                    >
                      Select a different parent
                    </p>
                  </button>
                </div>
              )}
            </>
          )}
        </div>
        <div style={{ marginTop: "20px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <p className="question-label">
              What is you child's previous swim experience? How do they feel
              about the water?
            </p>
            <textarea
              value={experience}
              rows={5}
              onChange={(e) => setExperience(e.target.value)}
              type="text"
              placeholder="Type your answer here..."
              className="edit-input"
              style={{ borderRadius: "10px", lineHeight: "1.4" }}
              disabled={props.isInstructor}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <p className="question-label">
              How would you describe your child's learning style and
              personality?
            </p>
            <textarea
              value={learningStyle}
              rows={5}
              onChange={(e) => setLearningStyle(e.target.value)}
              type="text"
              placeholder="Type your answer here..."
              className="edit-input"
              style={{ borderRadius: "10px", lineHeight: "1.4" }}
              disabled={props.isInstructor}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <p className="question-label">
              What are your goals? What pace do you want your child's instructor
              to take in reaching these goals?
            </p>
            <textarea
              value={goals}
              rows={5}
              onChange={(e) => setGoals(e.target.value)}
              type="text"
              placeholder="Type your answer here..."
              className="edit-input"
              style={{ borderRadius: "10px", lineHeight: "1.4" }}
              disabled={props.isInstructor}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <p className="question-label">Do you have any questions for us?</p>
            <textarea
              value={parentQuestions}
              rows={5}
              onChange={(e) => setParentQuestions(e.target.value)}
              type="text"
              placeholder="Type your answer here..."
              className="edit-input"
              style={{ borderRadius: "10px", lineHeight: "1.4" }}
              disabled={props.isInstructor}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <AppButton
            onClick={props.onRequestClose}
            outline={true}
            title="Cancel"
            fontSize={"14px"}
            width="20%"
            margin="0 10px 0 10px"
          />

          {!props.isInstructor && (
            <AppButton
              onClick={updateStudent}
              title="Save"
              loading={saveLoading}
              fontSize={"14px"}
              width="20%"
              margin="0 10px 0 10px"
            />
          )}
        </div>
      </div>
      <SlidingPane
        isOpen={chooseParentPanelOpen}
        from="right"
        width="400px"
        onRequestClose={() => {
          setChooseParentPanelOpen(false);
          fetchParents(0);
        }}
      >
        <ChooseParentPanel
          onRequestClose={() => {
            setChooseParentPanelOpen(false);
            fetchParents(0);
          }}
          onParentSelected={(parentId) => {
            fetchAndSetParent(parentId);
          }}
        />
      </SlidingPane>
    </Modal>
  );
}
