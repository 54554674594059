import {isSameDay} from "date-fns";

export const isNil = (val) => {
  return val === undefined || val == null;
};

export const formatPhoneNumber = (value) => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, "");

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

export const deformatPhoneNumber = (value) => {
  return value
    .replaceAll("+", "")
    .replaceAll("-", "")
    .replaceAll(" ", "")
    .replaceAll("(", "")
    .replaceAll(")", "");
};

export function base64Encode(file) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    return reader.result;
  };
  reader.onerror = (error) => {
    console.debug(error);
    return null;
  };
}

export function getAge(birthdate) {
  var today = new Date();
  var age = today.getFullYear() - birthdate.getFullYear();
  var m = today.getMonth() - birthdate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
    age--;
  }
  return age;
}

export function getMonthsOld(birthdate) {
  var months;
  const today = new Date();
  months = (today.getFullYear() - birthdate.getFullYear()) * 12;
  months -= birthdate.getMonth();
  months += today.getMonth();
  return months <= 0 ? 0 : months;
}

export const reqHeaders = {
  "Content-Type": "application/json",
  "x-access-token": localStorage.getItem("token"),
};

export const states = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const timeArray = [
  // 360, 380, 400, 420, 440, 460,
  480, 500, 520, 540, 560, 580, 600, 620, 640, 660, 680, 700, 720, 740, 760,
  780, 800, 820, 840, 860, 880, 900, 920, 940, 960, 980, 1000, 1020, 1040, 1060,
  1080, 1100, 1120, 1140,
  // 1160, 1180, 1200, 1220, 1240, 1260, 1280, 1300, 1320, 1340, 1360,
];

export const timeArrayString = [
  // "6:00am",
  // "6:20am",
  // "6:40am",
  // "7:00am",
  // "7:20am",
  // "7:40am",
  "8:00am",
  "8:20am",
  "8:40am",
  "9:00am",
  "9:20am",
  "9:40am",
  "10:00am",
  "10:20am",
  "10:40am",
  "11:00am",
  "11:20am",
  "11:40am",
  "12:00pm",
  "12:20pm",
  "12:40pm",
  "1:00pm",
  "1:20pm",
  "1:40pm",
  "2:00pm",
  "2:20pm",
  "2:40pm",
  "3:00pm",
  "3:20pm",
  "3:40pm",
  "4:00pm",
  "4:20pm",
  "4:40pm",
  "5:00pm",
  "5:20pm",
  "5:40pm",
  "6:00pm",
  "6:20pm",
  "6:40pm",
  "7:00pm",
  // "7:20pm",
  // "7:40pm",
  // "8:00pm",
  // "8:20pm",
  // "8:40pm",
  // "9:00pm",
  // "9:20pm",
  // "9:40pm",
  // "10:00pm",
  // "10:20pm",
  // "10:40pm",
];

export function getTimeStringFromSeconds(seconds) {
  const index = timeArray.indexOf(seconds);
  return timeArrayString[index];
}

export function getSecondsFromTimeString(timeString) {
  const index = timeArrayString.indexOf(timeString);
  return timeArray[index];
}

export function getDateFromMinutes(mins) {
  if (!mins) {
    return null;
  }
  const hours = Math.floor(mins / 60);
  const minutes = mins % 60;
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  return date;
}

export function studentInformationComplete(student) {
  if (student.questionnaire && student.address) {
    if (
      !student.questionnaire?.experience ||
      !student.questionnaire?.learningStyle ||
      !student.questionnaire?.goals ||
      !student.address?.addressLine1 ||
      !student.address?.city ||
      !student.address?.state ||
      !student.address?.zip
    ) {
      return false;
    }
    return true;
  }
  return false;
}

export function getShortDayString(day) {
  switch (day) {
    case 0:
      return "SUN";
    case 1:
      return "MON";
    case 2:
      return "TUE";
    case 3:
      return "WED";
    case 4:
      return "THU";
    case 5:
      return "FRI";
    case 6:
      return "SAT";
    default:
      return "SUN";
  }
}

export function getVeryShortDayString(day) {
  switch (day) {
    case 0:
      return "S";
    case 1:
      return "M";
    case 2:
      return "T";
    case 3:
      return "W";
    case 4:
      return "R";
    case 5:
      return "F";
    case 6:
      return "S";
    default:
      return "S";
  }
}

export function getFullDayString(day) {
  switch (day) {
    case 0:
      return "Sunday";
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    default:
      return "Sunday";
  }
}

export function instructorIsAvailableV2(schedules, lessonStartMinute, date) {
  if (!schedules || schedules.length === 0) return false;

  const schedule = schedules.filter(s => {
    const start = new Date(s.startDate);
    const end = new Date(s.endDate);
    start.setUTCHours(0, 0, 0, 0);
    end.setUTCHours(23, 59, 59, 999);
    return !(
      !isNil(s?.startDate) &&
      !isNil(s?.endDate) &&
      (date < start || date > end)
    )
  })?.[0];

  if (!schedule || !schedule.shifts || schedule.shifts.length === 0) {
    return false;
  }

  if (schedule.daysOff.some(d => isSameDay(getUtcDate(d), date))) {
    return false;
  }

  const timeLiesWithinShift = (shifts, day) => {
    for (const shift of shifts) {
      if (
        day === shift.dayOfWeek &&
        lessonStartMinute >= shift.startMinute &&
        lessonStartMinute < shift.endMinute
      ) {
        return true;
      }
    }
    return false;
  };

  const day = date.getDay();
  return timeLiesWithinShift(schedule.shifts, day);
}

export function getUtcDate(dateStr) {
  const date= new Date(dateStr);
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    0,
    0,
    0,
  );
}
