import { Grid } from "@mui/material";
import { format } from "date-fns";
import { useContext } from "react";
// import { BsThreeDots } from "react-icons/bs";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { AppContext } from "../pages/AppContainer";
import {getUtcDate} from "../utils";

export function UserInfoSidebar(props) {
  const {
    setSidebarSelectedDate,
    sidebarSelectedDate,
    sidebarLessons,
    daysInMonth,
    sidebarUpcomingLessons,
  } = useContext(AppContext);

  function previousPressed() {
    const newDate = new Date(sidebarSelectedDate);
    newDate.setMonth(sidebarSelectedDate.getMonth() - 1);
    setSidebarSelectedDate(newDate);
  }

  function nextPressed() {
    const newDate = new Date(sidebarSelectedDate);
    newDate.setMonth(sidebarSelectedDate.getMonth() + 1);
    setSidebarSelectedDate(newDate);
  }

  const hasLessonsToday = (day) => {
    const lessonsArr = [];
    const currentDate = new Date(
      sidebarSelectedDate.getFullYear(),
      sidebarSelectedDate.getMonth(),
      day - 1
    );
    for (const lesson of sidebarLessons) {
      const lessonDate = new Date(lesson.date);
      if (
        lessonDate.getMonth() === currentDate.getMonth() &&
        lessonDate.getFullYear() === currentDate.getFullYear() &&
        lessonDate.getDate() === currentDate.getDate()
      ) {
        lessonsArr.push(lesson);
      }
    }
    return lessonsArr.length > 0;
  };

  return (
    <div
      style={{
        width: "100%",
        background: "white",
        padding: "0 30px",
        paddingTop: "80px",
        minWidth: 330,
        maxWidth: 400,
        height: "100vh",
      }}
    >
      {/* Calendar */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 30,
        }}
      >
        <p
          style={{
            fontSize: 16,
            fontWeight: "600",
            margin: "0",
          }}
        >
          {format(sidebarSelectedDate, "MMMM")}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <HiChevronLeft
            onClick={previousPressed}
            size={30}
            style={{
              marginLeft: "40px",
              marginRight: "10px",
              color: "#707070",
              cursor: "pointer",
            }}
          />
          <HiChevronRight
            onClick={nextPressed}
            size={30}
            style={{ color: "#707070", cursor: "pointer" }}
          />
        </div>
      </div>

      {/* Calendar */}
      <div style={{ padding: "0 20px" }}>
        <Grid
          container
          style={{
            marginTop: 20,
          }}
        >
          {daysInMonth &&
            daysInMonth.map((day, index) => {
              const isDifferentMonth =
                (day > 7 && index < 7) || (day < 7 && index > 11);
              const isToday =
                index < 31 &&
                new Date().getDate() === day &&
                new Date().getMonth() === sidebarSelectedDate.getMonth() &&
                new Date().getFullYear() === sidebarSelectedDate.getFullYear();
              return (
                <Grid
                  key={`${day}${index}`}
                  item
                  xs={1.71428671}
                  lg={1.71428671}
                  xl={1.71428671}
                >
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      background: !isDifferentMonth
                        ? isToday
                          ? hasLessonsToday(day)
                            ? "#F8C28A"
                            : "#E0E0E0"
                          : hasLessonsToday(day)
                          ? "#F8C28A"
                          : "transparent"
                        : "transparent",
                      borderRadius: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginTop: 5,
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        color: !isDifferentMonth
                          ? isToday && hasLessonsToday(day)
                            ? "white"
                            : "#767676"
                          : "transparent",
                        padding: 0,
                        margin: 0,
                        textAlign: "center",
                      }}
                    >
                      {day}
                    </p>
                  </div>
                </Grid>
              );
            })}
        </Grid>
      </div>
      {/* Upcoming Lessons */}
      <p
        style={{
          fontSize: 16,
          fontWeight: "600",
          margin: "0",
          marginTop: "50px",
          marginBottom: "30px",
        }}
      >
        Upcoming Lessons
      </p>
      {sidebarUpcomingLessons &&
        sidebarUpcomingLessons.map((lesson) => (
          <div
            key={lesson._id}
            className="row"
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              boxShadow: "0px 2px 7px #00000012",
              padding: "10px 20px",
              borderRadius: 23,
            }}
          >
            <div
              className="row"
              style={{
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <div
                className="col"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 25,
                }}
              >
                <p
                  style={{
                    margin: 0,

                    color: "#131523",
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  {format(getUtcDate(lesson.date), "MMM")}
                </p>
                <p
                  style={{
                    margin: 0,
                    color: "#45CDCF",
                    fontSize: 16,
                  }}
                >
                  {format(getUtcDate(lesson.date), "d")}
                </p>
              </div>
              <p>
                {lesson.studentName.split(" ")[0]} with{" "}
                {lesson.instructorName.split(" ")[0]}
              </p>
            </div>
            {/* <BsThreeDots size={20} style={{ color: "#707070" }} /> */}
          </div>
        ))}
      {sidebarUpcomingLessons?.length > 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            marginTop: 20,
            marginLeft: 10,
            cursor: "pointer",
          }}
          onClick={props.onSeeMoreClicked}
        >
          <p
            style={{
              padding: "0",
              fontSize: 13,
              color: "#406C8C",
              margin: "0",
            }}
          >
            See More
          </p>
          <HiChevronRight size={14} style={{ color: "#406C8C" }} />
        </div>
      ) : (
        <p>No upcoming lessons</p>
      )}
    </div>
  );
}
