/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { CreateStudentModal } from "../components/modals/CreateStudentModal";
import { Grid } from "@mui/material";
import { StudentItem } from "../components/StudentItem";
import { useWindowSize } from "../hooks/windowSize";
import { reqHeaders } from "../utils";
import PaginationControl from "../components/PaginationControl";
import { AppButton } from "../components/AppButton";
import { AppContext } from "./AppContainer";

export function Students(props) {
  const { fetchParents, currentUser } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);

  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchStudents();
    fetchParents();
  }, []);

  useEffect(() => {
    const searchDelay = setTimeout(() => {
      setCurrentPage(0);
      fetchStudents(0);
    }, 500);
    return () => clearTimeout(searchDelay);
  }, [searchText]);

  async function fetchStudents(newCurrentPage) {
    setLoading(true);
    const text = searchText.trim();
    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/students${
        text ? `/${encodeURIComponent(text)}` : ""
      }?page=${newCurrentPage ?? 0}`,
      {
        method: "GET",
        headers: reqHeaders,
      }
    );
    const res = await req.json();
    setTotalPages(res.pageCount);
    // Order by last name
    if (res.data) {
      res.data.sort(function (a, b) {
        if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) return -1;
        if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) return 1;
        return 0;
      });
      setStudents(res.data);
    }
    setLoading(false);
  }

  function previousPressed() {
    const newCurrentPage = currentPage - 1;
    setCurrentPage(newCurrentPage);
    fetchStudents(newCurrentPage);
  }

  function nextPressed() {
    const newCurrentPage = currentPage + 1;
    setCurrentPage(newCurrentPage);
    fetchStudents(newCurrentPage);
  }

  const size = useWindowSize();

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "30px 60px 0 60px",
        }}
      >
        <h1 className="page-title">Students</h1>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            placeContent: "center",
          }}
        >
          {currentUser.role !== 1 && (
            <AppButton
              onClick={() => setCreateModalIsOpen(true)}
              title="New Student"
              showPlus={true}
              fontSize={"14px"}
              plusSize={"18px"}
              padding="0px 14px"
              margin={"0 10px 0 0"}
            />
          )}
          <input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            type="text"
            placeholder="Search..."
            className="login-input"
            id="search"
            style={{ marginRight: "10px" }}
          />
        </div>
      </div>

      <div
        style={{
          height: size?.height ? size.height - 200 : "50vh",
          overflow: "auto",
          padding: "30px 60px 30px 60px",
        }}
      >
        {students?.length > 0 ? (
          <Grid
            container
            style={{
              justifyContent: "start",
            }}
            columnSpacing={"30px"}
          >
            {!loading &&
              students &&
              students.map((student) => (
                <Grid item key={student._id}>
                  <StudentItem
                    student={student}
                    onStudentUpdated={fetchStudents}
                  />
                </Grid>
              ))}
          </Grid>
        ) : (
          <p>No students</p>
        )}
        <PaginationControl
          totalPages={totalPages}
          currentPage={currentPage}
          onPreviousPressed={previousPressed}
          onNextPressed={nextPressed}
          addTopMargin={true}
        />
      </div>
      <CreateStudentModal
        isOpen={createModalIsOpen}
        onRequestClose={() => {
          setCreateModalIsOpen(false);
        }}
        onStudentCreated={() => {
          fetchStudents();
        }}
        onAfterClose={() => {}}
      />
    </div>
  );
}
