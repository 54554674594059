import {CircularProgress} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import PaginationControl from "../../components/PaginationControl";
import {useWindowSize} from "../../hooks/windowSize";
import {reqHeaders} from "../../utils";
import {AppButton} from "../../components/AppButton";
import {EditUserModal} from "./EditUserModal";

export default function UserAdminList() {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [fetchVersion, setFetchVersion] = useState(0);
  const searchTextRef = useRef();

  const size = useWindowSize();

  useEffect(() => {
    async function fetchUsers() {
      setLoading(true);
      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/users/users?page=${currentPage}&search=${encodeURIComponent(searchText.trim())}`,
        {
          method: "GET",
          headers: reqHeaders,
        }
      );
      const res = await req.json();
      setTotalPages(res.pageCount);
      setUsers(res.users);
      setLoading(false);
    }

    fetchUsers();
  }, [currentPage, searchText, fetchVersion]);

  function roleString(role) {
    if (role === 0) {
      return 'Parent'
    } else if (role === 1) {
      return 'Instructor'
    } else if (role === 2) {
      return 'Admin'
    } else if (role === 3) {
      return 'Super Admin'
    }
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "center",
          padding: "30px 60px 0 60px",
          gap: "10px"
        }}
      >
        <h1 className="page-title">Users</h1>

        <div style={{flexGrow: 1}}></div>

        <div style={{display: 'flex', gap: '5px'}}>
          <input
            ref={searchTextRef}
            type="text"
            placeholder="Search Users..."
            className="login-input"
            id="search"
          />
          <AppButton
            onClick={() => {
              setSearchText(searchTextRef.current.value);
              setCurrentPage(0);
            }}
            title="Search"
            showPlus={false}
            fontSize={"14px"}
            plusSize={"18px"}
            padding="0px 14px"
          />
          <AppButton
            onClick={() => {
              setSearchText("");
              searchTextRef.current.value = "";
              setCurrentPage(0);
            }}
            title="Clear"
            showPlus={false}
            fontSize={"14px"}
            plusSize={"18px"}
            padding="0px 14px"
          />
          <AppButton
            onClick={() => {
              setSelectedUser(null);
              setEditModalIsOpen(true);
            }}
            title="New User"
            showPlus={true}
            fontSize={"14px"}
            plusSize={"18px"}
            padding="0px 14px"
          />
        </div>
      </div>

      {loading && (
        <div style={{ textAlign: "center", margin: "50px 0" }}>
          <CircularProgress size={26} style={{ color: "#004367" }} />
        </div>
      )}

      <div
        style={{
          height: size?.height ? size.height - 200 : "50vh",
          overflow: "auto",
          padding: "30px 60px 30px 60px",
        }}
      >
        <table width='100%'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {users.length === 0 && (
              <tr>
                <td colSpan={3}>No Users</td>
              </tr>
            )}
            {users.map((user) => (
              <tr key={user._id}>
                <td>
                  <a href='#' onClick={() => {
                    setSelectedUser(user);
                    setEditModalIsOpen(true);
                  }}>
                    {user.firstName} {user.lastName}
                  </a>
                </td>
                <td>{user.email}</td>
                <td>{user.phone}</td>
                <td>{roleString(user.role)}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <PaginationControl
          totalPages={totalPages}
          currentPage={currentPage}
          onPreviousPressed={() => { setCurrentPage((prevState) => prevState - 1)} }
          onNextPressed={() => { setCurrentPage((prevState) => prevState + 1)} }
          addTopMargin={true}
        />
      </div>

      <EditUserModal
        isOpen={editModalIsOpen}
        user={selectedUser}
        onRequestClose={() => {
          setEditModalIsOpen(false);
          setFetchVersion((prev) => prev + 1);
        }}
      />
    </>
  )
}