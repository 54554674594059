import Modal from "react-modal";
import { useState } from "react";
import { customStyles } from "../../pages/AppContainer";
import { BsPersonCircle } from "react-icons/bs";
import { getAge, studentInformationComplete } from "../../utils";
import { format } from "date-fns";
import { BiPencil } from "react-icons/bi";
import { Tag } from "../Tag";
import { StudentInformationModal } from "./StudentInformationModal";
import { WaiverModal } from "./WaiverModal";

export function AdminViewStudent(props) {
  const student = props.student;
  const birthdate = new Date(student?.birthdate);

  const [studentInfoModalIsOpen, setStudentInfoModalOpen] = useState(false);
  const [waiverModalIsOpen, setWaiverModalOpen] = useState(false);

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        style={customStyles}
        ariaHideApp={false}
        onAfterClose={props.onAfterClose}
      >
        <div
          style={{
            width: "40vw",
            minWidth: "200px",
            maxWidth: "600px",
            padding: "10px",
          }}
        >
          <div style={{ textAlign: "end" }}>
            {!props.isInstructor && (
              <button onClick={props.onEditPressed}>
                <BiPencil size={28} style={{ color: "#D7DBEC" }} />
              </button>
            )}

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                {!student?.profilePhoto ? (
                  <BsPersonCircle
                    size={120}
                    style={{ color: "#DEDEDE", marginRight: "30px" }}
                  />
                ) : (
                  <img
                    src={student.profilePhoto}
                    style={{
                      width: "120px",
                      height: "120px",
                      objectFit: "cover",
                      borderRadius: "100px",
                      marginRight: "30px",
                    }}
                    alt=""
                  />
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignContent: "start",
                    alignItems: "start",
                  }}
                >
                  <p
                    style={{
                      fontSize: "30px",
                      fontWeight: "600",
                      margin: "0 0 10px 0",
                      padding: "0",
                    }}
                  >
                    {`${student?.firstName ?? ""} ${
                      student?.lastName[0] ?? ""
                    }`}
                    {". "}
                    <span style={{ fontSize: "16px", fontWeight: "400" }}>
                      (Age: {getAge(birthdate)})
                    </span>
                  </p>
                  <p
                    style={{
                      color: "#7E84A3",
                      fontSize: "16px",
                      margin: "0 0 5px 0",
                    }}
                  >
                    <span style={{ fontWeight: "600" }}>Birthday:</span>{" "}
                    {format(birthdate, "MMMM dd, yyyy")}
                  </p>
                  <p
                    style={{ color: "#7E84A3", fontSize: "16px", margin: "0" }}
                  >
                    <span style={{ fontWeight: "600" }}>
                      {student?.lessonsTaken ?? "0"}
                    </span>{" "}
                    Lesson
                    {student?.lessonsTaken !== 1 ? "s" : ""} Taken
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  background: "#E3E3E3",
                  height: "1px",
                  margin: "25px 0",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                }}
              >
                <p
                  style={{
                    textAlign: "start",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  Paperwork
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ fontSize: "18px", color: "#7E84A3" }}>
                    Permission Liability Waiver
                  </p>
                  <button onClick={() => setWaiverModalOpen(true)}>
                    {student?.waiverSignedBy ? (
                      <Tag
                        label="Complete"
                        fontSize="12px"
                        width="150px"
                        background="#21D59B"
                      />
                    ) : (
                      <Tag label="Incomplete" fontSize="12px" width="150px" />
                    )}
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <p style={{ fontSize: "18px", color: "#7E84A3" }}>
                    Student Information
                  </p>
                  <button onClick={() => setStudentInfoModalOpen(true)}>
                    {studentInformationComplete(student) ? (
                      <Tag
                        label="Complete"
                        fontSize="12px"
                        width="150px"
                        background="#21D59B"
                      />
                    ) : (
                      <Tag label="Incomplete" fontSize="12px" width="150px" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <StudentInformationModal
        isOpen={studentInfoModalIsOpen}
        student={student}
        onRequestClose={() => {
          setStudentInfoModalOpen(false);
        }}
        onStudentUpdated={props.onStudentUpdated}
        isInstructor={props.isInstructor}
      />
      <WaiverModal
        isOpen={waiverModalIsOpen}
        student={student}
        onRequestClose={() => {
          setWaiverModalOpen(false);
        }}
        onStudentUpdated={props.onStudentUpdated}
        isInstructor={props.isInstructor}
      />
    </>
  );
}
