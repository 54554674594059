/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AnnouncementItem } from "../components/AnnouncementItem";
import { AppButton } from "../components/AppButton";
import { EditAnnouncementModal } from "../components/modals/EditAnnouncementModal";
import { ViewAnnouncementModal } from "../components/modals/ViewAnnouncementModal";
import PaginationControl from "../components/PaginationControl";
import { useWindowSize } from "../hooks/windowSize";
import { AppContext } from "./AppContainer";

export function Announcements(props) {
  const { currentUser } = useContext(AppContext);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [announcements, setAnnouncements] = useState();
  const [selectedAnnouncement, setSelectedAnnouncement] = useState();
  const [editModalIsOpen, setEditIsOpen] = useState(false);
  const [viewModalIsOpen, setViewModalIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  useEffect(() => {
    const searchDelay = setTimeout(() => {
      setCurrentPage(0);
      fetchAnnouncements(0);
    }, 500);
    return () => clearTimeout(searchDelay);
  }, [searchText]);

  async function fetchAnnouncements(newCurrentPage) {
    setLoading(true);
    const text = searchText.trim();
    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/announcements${
        text ? `/${encodeURIComponent(text)}` : ""
      }?page=${newCurrentPage ?? 0}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );
    const res = await req.json();
    setTotalPages(res.pageCount);
    if (res.data) {
      const orderedByDate = res.data
        .sort((a, b) => {
          return (
            new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
          );
        })
        .reverse();
      setAnnouncements(orderedByDate);
    }
    setLoading(false);
  }

  const size = useWindowSize();

  let subtitle;

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function previousPressed() {
    const newCurrentPage = currentPage - 1;
    setCurrentPage(newCurrentPage);
    fetchAnnouncements(newCurrentPage);
  }

  function nextPressed() {
    const newCurrentPage = currentPage + 1;
    setCurrentPage(newCurrentPage);
    fetchAnnouncements(newCurrentPage);
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "30px 60px 0 60px",
        }}
      >
        <h1 className="page-title">Announcements</h1>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            placeContent: "center",
          }}
        >
          {currentUser?.role > 1 && (
            <AppButton
              onClick={() => {
                setSelectedAnnouncement(null);
                setEditIsOpen(true);
              }}
              title="New Post"
              showPlus={true}
              fontSize={"14px"}
              plusSize={"18px"}
              padding="8px 24px"
              margin={"0 10px 0 0"}
            />
          )}
          <input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            type="text"
            placeholder="Search..."
            className="login-input"
            id="search"
          />
        </div>
      </div>

      <div
        style={{
          height: size?.height ? size.height - 200 : "50vh",
          overflow: "auto",
          padding: "30px 60px 30px 60px",
        }}
      >
        <Grid
          container
          style={{
            justifyContent: "start",
            marginBottom: "50px",
          }}
          columnSpacing={"30px"}
        >
          {!loading &&
            announcements?.length > 0 &&
            announcements.map((announcement) => (
              <Grid item key={announcement._id}>
                <AnnouncementItem
                  announcement={announcement}
                  onClickEdit={() => {
                    setSelectedAnnouncement(announcement);
                    setEditIsOpen(true);
                  }}
                  onClickReadMore={() => {
                    setSelectedAnnouncement(announcement);
                    setViewModalIsOpen(true);
                  }}
                />
              </Grid>
            ))}
          {!loading && (!announcements || announcements?.length < 1) && (
            <p style={{ marginLeft: "30px" }}>No announcements</p>
          )}
        </Grid>
        <PaginationControl
          totalPages={totalPages}
          currentPage={currentPage}
          onPreviousPressed={previousPressed}
          onNextPressed={nextPressed}
        />
      </div>

      <EditAnnouncementModal
        isOpen={editModalIsOpen}
        onAfterOpen={afterOpenModal}
        announcement={selectedAnnouncement}
        onRequestClose={() => {
          setEditIsOpen(false);
          setSelectedAnnouncement(null);
        }}
        onAnnouncementUpdated={() => {
          fetchAnnouncements();
        }}
        onAfterClose={() => {
          setSelectedAnnouncement(null);
        }}
      />

      {selectedAnnouncement && (
        <ViewAnnouncementModal
          isOpen={viewModalIsOpen}
          announcement={selectedAnnouncement}
          onAfterOpen={afterOpenModal}
          onRequestClose={() => {
            setViewModalIsOpen(false);
          }}
        />
      )}
    </div>
  );
}
