/* eslint-disable react-hooks/exhaustive-deps */
import Modal from "react-modal";
import { ImCross } from "react-icons/im";
import { useContext, useEffect, useState } from "react";
import { AppButton } from "../AppButton";
import { useSnackbar } from "notistack";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsPersonCircle } from "react-icons/bs";
import { BsFillCameraFill } from "react-icons/bs";
import { ChooseParentPanel } from "./ChooseParentPanel";
import SlidingPane from "react-sliding-pane";
import { getMonthsOld, isNil, reqHeaders } from "../../utils";
import { AppContext } from "../../pages/AppContainer";

export function CreateStudentModal(props) {
  const { currentUser, fetchParents } = useContext(AppContext);
  const student = props.student ?? null;

  const [saveLoading, setSaveLoading] = useState(false);
  const [chooseParentPanelOpen, setChooseParentPanelOpen] = useState(false);
  const [selectedParent, setSelectedParent] = useState();
  const [name, setName] = useState(getInitialName());
  const [birthdate, setBirthdate] = useState(getInitialDate());
  const [profileImage, setProfileImage] = useState(student?.profilePhoto);

  const { enqueueSnackbar } = useSnackbar();

  const currentUserIsParent =
    isNil(student?.parentUserId) &&
    isNil(currentUser?._id) &&
    student?.parentUserId === currentUser._id;

  function getInitialName() {
    if (student?.firstName && student?.lastName) {
      return `${student?.firstName} ${student?.lastName}`;
    }
    return null;
  }

  function getInitialDate() {
    if (student?.birthdate) {
      return new Date(student?.birthdate);
    }
    return new Date().setFullYear(new Date().getFullYear() - 12); // Default to 12 years ago;
  }

  useEffect(() => {
    // if (!currentUserIsParent) {
    fetchAndSetParent(student?.parentUserId);
    // }
  }, []);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "20px",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      backdropFilter: "blur(2px)",
    },
  };

  async function createStudent() {
    setSaveLoading(true);

    if (!name || name.length < 1) {
      setSaveLoading(false);
      displayMessage("Please enter a name", "error");
      return;
    }

    if (!birthdate || getMonthsOld(birthdate) < 6) {
      setSaveLoading(false);
      displayMessage("Student must be at least 6 months old", "error");
      return;
    }

    try {
      const names = name.split(" ");
      if (names.length < 2) {
        setSaveLoading(false);
        displayMessage("Please enter a first and last name", "error");
        return;
      }
      const firstName = names[0];
      // Remove first item of array
      const index = names.indexOf(firstName);
      if (index > -1) {
        names.splice(index, 1);
      }
      const lastName = names?.toString().replaceAll(",", " ") ?? "";
      const req = await fetch(`${process.env.REACT_APP_API_URL}/students`, {
        method: "POST",
        headers: reqHeaders,
        body: JSON.stringify({
          firstName: firstName,
          lastName: lastName ?? "",
          birthdate: birthdate,
          profilePhoto: profileImage,
          parentUserId:
            currentUserIsParent || !props.isEditing
              ? currentUser._id
              : selectedParent._id,
        }),
      });

      if (req.status === 200) {
        props.onStudentCreated();
        displayMessage("The student has been created", "success");
        props.onRequestClose();
      } else {
        displayMessage("Something went wrong. Please try again.", "error");
      }
    } catch (err) {
      console.debug(err);
      displayMessage("Something went wrong. Please try again.", "error");
    }
    setSaveLoading(false);
  }

  async function updateStudent() {
    if (!edited) {
      displayMessage("Nothing was edited", "error");
      return;
    }
    if (!name || name.length < 1) {
      setSaveLoading(false);
      displayMessage("Please enter a name", "error");
      return;
    }
    if (!birthdate || getMonthsOld(birthdate) < 6) {
      setSaveLoading(false);
      displayMessage("Student must be at least 6 months old", "error");
      return;
    }

    try {
      setSaveLoading(true);
      const names = name.split(" ");
      if (names.length < 2) {
        setSaveLoading(false);
        displayMessage("Please enter a first and last name", "error");
        return;
      }
      const firstName = names[0];
      // Remove first item of array
      const index = names.indexOf(firstName);
      if (index > -1) {
        names.splice(index, 1);
      }
      const lastName = names?.toString().replaceAll(",", " ") ?? "";
      const updatedStudent = {
        updatedStudent: {
          id: student._id,
          firstName: firstName,
          lastName: lastName ?? "",
          birthdate: birthdate,
          profilePhoto: profileImage,
          parentUserId: currentUserIsParent
            ? currentUser._id
            : selectedParent._id,
        },
      };
      const req = await fetch(`${process.env.REACT_APP_API_URL}/students`, {
        method: "PUT",
        headers: reqHeaders,
        body: JSON.stringify(updatedStudent),
      });

      if (req.status === 200) {
        props.onStudentUpdated();
        displayMessage("The student has been updated", "success");
        props.onRequestClose();
      } else {
        displayMessage("Something went wrong. Please try again.", "error");
      }
    } catch (err) {
      console.debug(err);
      displayMessage("Something went wrong. Please try again.", "error");
    }
    setSaveLoading(false);
  }

  async function fetchAndSetParent(parentId) {
    if (parentId) {
      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/users/parent?userId=${parentId}`,
        {
          method: "GET",
          headers: reqHeaders,
        }
      );
      const res = await req.json();
      setSelectedParent(res.user);
    }
  }

  const displayMessage = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
    });
  };

  const edited =
    birthdate !== null &&
    (name !== `${student?.firstName} ${student?.lastName}` ||
      birthdate.getDate() !== new Date(student?.birthdate).getDate() ||
      birthdate.getMonth() !== new Date(student?.birthdate).getMonth() ||
      birthdate.getFullYear() !== new Date(student?.birthdate).getFullYear() ||
      profileImage !== student?.profilePhoto ||
      selectedParent !== student?.selectedParent);

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      style={customStyles}
      ariaHideApp={false}
      onAfterClose={props.onAfterClose}
    >
      <div style={{ width: "40vw", minWidth: "400px", padding: "10px" }}>
        <div style={{ textAlign: "end" }}>
          <button onClick={props.onRequestClose}>
            <ImCross size={12} style={{ color: "#B2AEAE" }} />
          </button>
        </div>
        <label className="modal-image-container">
          <input
            type="file"
            name="student-image"
            accept="image/png, image/gif, image/jpeg"
            onChange={(event) => {
              let files = event.target.files;
              let reader = new FileReader();
              reader.readAsDataURL(files[0]);
              reader.onload = (e) => {
                setProfileImage(e.target.result);
              };
            }}
          />
          <div style={{ position: "relative" }}>
            <div
              style={{
                width: "30px",
                height: "30px",
                background: "white",
                position: "absolute",
                right: "0px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                borderRadius: "50px",
                boxShadow: "0px 1px 4px rgba(0,0,0,0.4)",
              }}
            >
              <BsFillCameraFill
                size={16}
                style={{
                  color: "rgb(215, 219, 236)",
                }}
              />
            </div>

            {!profileImage ? (
              <BsPersonCircle size={120} style={{ color: "#DEDEDE" }} />
            ) : (
              <img
                src={profileImage}
                alt=""
                style={{
                  width: "120px",
                  height: "120px",
                  borderRadius: "100px",
                  objectFit: "cover",
                }}
              />
            )}
          </div>
        </label>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            padding: "20px 15% 40px 15%",
          }}
        >
          <p className="edit-label">First & Last Name</p>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            placeholder="Type Name"
            className="login-input"
            id="first-name"
          />
          <p className="edit-label" style={{ marginTop: "15px" }}>
            Birth Date
          </p>

          <DatePicker
            selected={birthdate}
            placeholderText="xx/xx/xxxx"
            className="birthdate"
            onChange={(newDate) => setBirthdate(newDate ?? null)}
            style={{
              border: "2px solid red",
            }}
          />
          <>
            {(currentUserIsParent || currentUser.role > 1) && (
              <>
                <p className="edit-label" style={{ marginTop: "15px" }}>
                  Parent
                </p>
                {!selectedParent ? (
                  <button
                    onClick={() => setChooseParentPanelOpen(true)}
                    style={{ textAlign: "start" }}
                  >
                    <p style={{ marginTop: "0px", color: "#406C8C" }}>
                      Select a parent
                    </p>
                  </button>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignContent: "center",
                    }}
                  >
                    <p style={{ margin: "0", padding: "0" }}>
                      {selectedParent.firstName} {selectedParent.lastName}{" "}
                      <span style={{ fontSize: "12px" }}>
                        ({selectedParent.email})
                      </span>
                    </p>
                    <button
                      onClick={() => setChooseParentPanelOpen(true)}
                      style={{ textAlign: "start", marginLeft: "20px" }}
                    >
                      <p
                        style={{
                          marginTop: "0px",
                          color: "#406C8C",
                          fontSize: "12px",
                        }}
                      >
                        Select a different parent
                      </p>
                    </button>
                  </div>
                )}
              </>
            )}
          </>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <AppButton
            onClick={props.onRequestClose}
            outline={true}
            title="Cancel"
            fontSize={"14px"}
            width="20%"
            margin="0 10px 0 10px"
          />

          <AppButton
            onClick={props.isEditing ? updateStudent : createStudent}
            title="Save"
            loading={saveLoading}
            fontSize={"14px"}
            width="20%"
            margin="0 10px 0 10px"
          />
        </div>
      </div>
      <SlidingPane
        isOpen={chooseParentPanelOpen}
        from="right"
        width="400px"
        onRequestClose={() => {
          setChooseParentPanelOpen(false);
          fetchParents(0);
        }}
      >
        <ChooseParentPanel
          onRequestClose={() => {
            setChooseParentPanelOpen(false);
            fetchParents(0);
          }}
          onParentSelected={(parentId) => {
            fetchAndSetParent(parentId);
          }}
        />
      </SlidingPane>
    </Modal>
  );
}
