/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AppButton } from "../components/AppButton";
import { reqHeaders } from "../utils";

export default function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const params = useParams();
  const history = useHistory();

  const { userId, token } = params;

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    verifyLink();
  }, [userId, token]);

  const displayMessage = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
    });
  };

  async function verifyLink() {
    setLoading(true);
    if (!userId && !token) {
      setLoading(false);
      return displayMessage("Must provide email", "error");
    }
    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/forgot-password/${userId}/${token}`,
      {
        method: "GET",
        headers: reqHeaders,
      }
    );
    if (req.status === 200) {
      setVerified(true);
    }
    setLoading(false);
  }

  async function resetPassword() {
    try {
      if (!newPassword || !confirmNewPassword) {
        return displayMessage("Must provide password", "error");
      }
      if (!newPassword || newPassword.length < 6) {
        return displayMessage(
          "Password must be at least 6 characters",
          "error"
        );
      }
      if (newPassword !== confirmNewPassword) {
        return displayMessage("Passwords don't match", "error");
      }
      setLoading(true);
      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/forgot-password/${userId}/${token}`,
        {
          method: "POST",
          headers: reqHeaders,
          body: JSON.stringify({
            password: newPassword,
          }),
        }
      );
      if (req.status === 200) {
        displayMessage("Success! Your password has been reset.", "success");
      }
      history.push("/login");
      setLoading(false);
    } catch (err) {
      console.debug(err);
      displayMessage("Something went wrong. Please try again.", "error");
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div
        style={{
          width: "60vw",
          alignItems: "center",
          alignContent: "center",
          textAlign: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
            marginTop: "20px",
            padding: "0px 50px",
          }}
        >
          <img
            src="/images/logo.png"
            style={{ width: "80px" }}
            alt="MWS Logo"
          />
          <h2
            style={{
              color: "#454545",
              letterSpacing: "0.1px",
              fontSize: "14px",
              cursor: "pointer",
            }}
            onClick={() => history.push("/")}
          >
            Home
          </h2>
        </div>
        <div style={{ marginTop: "20vh" }}>
          <div className="formContainer">
            <h3
              style={{
                textAlign: "start",
                fontSize: "36px",
                marginBottom: "20px",
              }}
            >
              Reset Password
            </h3>
            <p className="inputLabel" style={{ marginTop: "15px" }}>
              New Password
            </p>
            <input
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              type="password"
              placeholder="Type New Password"
              className="login-input"
              id="password"
            />
            <p className="inputLabel" style={{ marginTop: "15px" }}>
              Confirm Password
            </p>
            <input
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              type="password"
              placeholder="Type New Password"
              className="login-input"
              id="password"
            />
            {loading && (
              <div style={{ textAlign: "center" }}>
                <CircularProgress
                  size={26}
                  style={{ color: "black", marginTop: 40 }}
                />
              </div>
            )}
            {!loading && !verified && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: "30px",
                }}
              >
                <p style={{ fontSize: 12 }}>
                  Something went wrong verifying your reset link. Refresh the
                  page. If the error persists,{" "}
                  <button onClick={() => history.push("/forgot-password")}>
                    <span style={{ fontWeight: 600, color: "#6ecacd" }}>
                      go back
                    </span>
                  </button>{" "}
                  and re-enter your email and try again.
                </p>
              </div>
            )}
            {!loading && verified && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: "30px",
                }}
              >
                <AppButton
                  loading={loading}
                  onClick={resetPassword}
                  title="Reset Password"
                  width="50%"
                  fontSize="14px"
                  margin="0 0 0 10px"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          width: "40vw",
          height: "100vh",
          backgroundImage: "url('/images/pool.png')",
        }}
      ></div>
    </div>
  );
}
