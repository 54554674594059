/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useEffect, useState} from "react";
import {BiCalendarAlt} from "react-icons/bi";
import {useWindowSize} from "../hooks/windowSize";
import {HiChevronLeft, HiChevronRight} from "react-icons/hi";
import {getTimeStringFromSeconds, getUtcDate, instructorIsAvailableV2, reqHeaders, timeArray,} from "../utils";
import {CircularProgress, debounce} from "@mui/material";
import {ConfirmCancelLessonModal} from "../components/modals/ConfirmCancelLessonModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {format} from "date-fns";

export function AdminCalendar(props) {
  const [loading, setLoading] = useState(false);
  const [calendar, setCalendar] = useState({});
  const [calendarType, setCalendarType] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const size = useWindowSize();

  useEffect(() => {
    fetchDayCalendar();
  }, [selectedDate, searchText]);

  const changeHandler = event => {
    setCurrentPage(0);
    setSearchText(event.target.value);
  }
  const debouncedChangeHandler = useCallback(debounce(changeHandler, 500), []);

  async function fetchDayCalendar(newCurrentPage) {
    setLoading(true);
    const month = selectedDate.getMonth();
    const day = selectedDate.getDate();
    const year = selectedDate.getFullYear();
    const text = searchText.trim();

    try {
      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/calendar/day?month=${
          month + 1
        }&day=${day}&year=${year}&itemsPerPage=${6}&page=${
          newCurrentPage ?? 0
        }${
          text && text.length > 0 ? `&searchText=${text}` : ""
        }&ignoreStartEndDates=${true}${
          props.instructorId ? `&instructorId=${props.instructorId}` : ""
        }`,
        {
          method: "GET",
          headers: reqHeaders,
        }
      );
      const res = await req.json();
      setTotalPages(res.pageCount);
      setCalendarType(res.calendarType);
      if (res.data) {
        setCalendar(res.data);
      }
      setLoading(false);
    } catch (err) {
      console.debug("Calendar error: ", err);
      setLoading(false);
    }
  }

  const previousButtonDisabled = () => {
    if (!totalPages || totalPages < 2 || currentPage === 0) {
      return true;
    }
    return false;
  };

  const nextButtonDisabled = () => {
    if (!totalPages || currentPage + 1 >= totalPages) {
      return true;
    }
    return false;
  };

  function previousPressed() {
    const newCurrentPage = currentPage - 1;
    setCurrentPage(newCurrentPage);
    fetchDayCalendar(newCurrentPage);
  }

  function nextPressed() {
    const newCurrentPage = currentPage + 1;
    setCurrentPage(newCurrentPage);
    fetchDayCalendar(newCurrentPage);
  }

  // Get a lesson for a specific time and instructor
  function getLesson(instructorId, time) {
    const lesson = calendar[instructorId].lessons.find(
      (l) => l.startMinuteOfDay === time
    );
    return lesson ?? null;
  }

  function getWeeklyLesson(date, time) {
    const lessons = calendar.days.find(d => d.date === date).lessons;
    return lessons.find(l => l.startMinuteOfDay === time)
  }

  function buildDailyCalendar() {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            background: "#E6E9F4",
            padding: "15px 30px",
            marginTop: "20px",
          }}
        >
          <p className="calendar-head-text" style={{ width: "12.5%" }}>
            Time Slots
          </p>
          {calendar &&
            Object.keys(calendar).length > 0 &&
            Object.keys(calendar).map((val, i) => (
              <div key={`daily_header_${val}`} style={{ width: "12.5%" }}>
                <p className="calendar-head-text">
                  {calendar[val].instructorName}
                </p>
              </div>
            ))}
          {totalPages > 1 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                style={{ padding: "0", margin: "0" }}
                disabled={previousButtonDisabled()}
                onClick={previousPressed}
              >
                <HiChevronLeft
                  size={20}
                  style={{
                    marginLeft: "40px",
                    marginRight: "20px",
                    color: previousButtonDisabled() ? "#b1b1b1" : "black",
                  }}
                />
              </button>
              <button
                style={{ padding: "0", margin: "0" }}
                disabled={nextButtonDisabled()}
                onClick={nextPressed}
              >
                <HiChevronRight
                  size={20}
                  style={{
                    color: nextButtonDisabled() ? "#b1b1b1" : "black",
                  }}
                />
              </button>
            </div>
          )}
        </div>
        {/* Table data */}
        {loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress
              size={26}
              style={{ color: "black", marginTop: "100px" }}
            />
          </div>
        )}
        {!loading &&
          timeArray.map((time) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                padding: "0px 30px",
                marginTop: "25px",
              }}
              key={`daily_time_${time}`}
            >
              <p
                style={{
                  width: "12.5%",
                  margin: "0",
                  padding: "0",
                  fontWeight: "600",
                }}
              >
                {getTimeStringFromSeconds(time)}
              </p>
              {calendar &&
                Object.keys(calendar).length > 0 &&
                Object.keys(calendar).map((instructorId, i) => {
                  const instructorAvailable = instructorIsAvailableV2(
                    calendar[instructorId].schedule,
                    time,
                    getUtcDate(selectedDate)
                  );
                  return (
                    <div key={`daily_inst_${instructorId}`} style={{ width: "12.5%" }}>
                      <AdminCalendarItem
                        lesson={getLesson(instructorId, time)}
                        instructorAvailable={instructorAvailable}
                        onLessonCanceled={() => {
                          fetchDayCalendar();
                        }}
                      />
                    </div>
                  );
                })}
              <ChevronFiller />
            </div>
          ))}
      </>
    );
  }

  function buildWeeklyCalendar() {
    return (
      <>
        <div style={{width: '100%', textAlign: 'center', marginTop: '10px' }}><h3>Weekly Schedule for: {calendar.instructorName}</h3></div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            background: "#E6E9F4",
            padding: "15px 30px",
            marginTop: "20px",
          }}
        >
          <p className="calendar-head-text" style={{ width: "12.5%" }}>
            Time Slots
          </p>

          {calendar && calendar.days &&
            calendar.days.map((day, i) => (
              <div key={`day_header_${i}`} style={{ width: "12.5%" }}>
                <p className="calendar-head-text">
                  {format(getUtcDate(day.date), 'MM/dd')}
                </p>
              </div>
            ))}
        </div>

        {/* Table data */}
        {loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress
              size={26}
              style={{ color: "black", marginTop: "100px" }}
            />
          </div>
        )}

        {!loading &&
          timeArray.map((time) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                padding: "0px 30px",
                marginTop: "25px",
              }}
              key={`weekly_time_${time}`}
            >
              <p
                style={{
                  width: "12.5%",
                  margin: "0",
                  padding: "0",
                  fontWeight: "600",
                }}
              >
                {getTimeStringFromSeconds(time)}
              </p>

              {calendar &&
                calendar.days.map((day, i) => {
                  const instructorAvailable = instructorIsAvailableV2(
                    calendar.instructorSchedule,
                    time,
                    getUtcDate(day.date)
                  );
                  return (
                    <div key={`weekly_data_${time}_${i}`} style={{ width: "12.5%" }}>
                      <AdminCalendarItem
                        lesson={getWeeklyLesson(day.date, time)}
                        instructorAvailable={instructorAvailable}
                        onLessonCanceled={() => {
                          fetchDayCalendar();
                        }}
                      />
                    </div>
                  );
                })}
              <ChevronFiller />
            </div>
          ))}
      </>
    );
  }

  return (
    <div>
      {!props.isDashboard && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "30px 60px 0 60px",
          }}
        >
          <h1 className="page-title">Calendar</h1>
        </div>
      )}

      <div
        style={{
          height: !props.instructorId
            ? !props.isDashboard
              ? size?.height
                ? size.height - 200
                : "50vh"
              : "50vh"
            : "60vh",
          overflow: "auto",
          padding: !props.isDashboard
            ? "30px 60px 30px 60px"
            : "30px 60px 30px 10px",
          marginLeft: !props.isDashboard ? 0 : "-10px",
        }}
      >
        {/* Calendar white container */}
        <div
          style={{
            background: "white",
            padding: "15px",
            boxShadow: "0px 3px 10px #15223224",
            borderRadius: "12px",
            height: "90%",
            overflow: "scroll",
            overflowX: "hidden",
          }}
        >
          {/* Top calendar info */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <div
                style={{
                  background: "#45CDCF",
                  borderRadius: "100%",
                  minWidth: 36,
                  minHeight: 36,
                  maxWidth: 36,
                  maxHeight: 36,
                  padding: "0",
                  margin: "0",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  marginRight: "15px",
                  boxShadow: "0px 3px 6px #00000029",
                }}
              >
                <BiCalendarAlt
                  size={22}
                  style={{ color: "white", padding: "0", marginTop: "-2px" }}
                />
              </div>
              <DatePicker
                selected={selectedDate}
                placeholderText="xx/xx/xxxx"
                id="book-lesson-date-picker"
                onChange={(newDate) => setSelectedDate(newDate ?? null)}
                dateFormat="MMMM d, yyyy"
              />
              <button
                style={{ padding: "0", margin: "0" }}
                onClick={() => {
                  const newDate = new Date(selectedDate);
                  newDate.setDate(selectedDate.getDate() - 1);
                  setSelectedDate(newDate);
                }}
              >
                <HiChevronLeft
                  size={30}
                  style={{
                    marginLeft: "40px",
                    marginRight: "25px",
                    color: "#707070",
                  }}
                />
              </button>
              <button
                style={{ padding: "0", margin: "0" }}
                onClick={() => {
                  const newDate = new Date(selectedDate);
                  newDate.setDate(selectedDate.getDate() + 1);
                  setSelectedDate(newDate);
                }}
              >
                <HiChevronRight size={30} style={{ color: "#707070" }} />
              </button>
            </div>
            {!props.instructorId && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  placeContent: "center",
                }}
              >
                <input
                  onChange={debouncedChangeHandler}
                  type="text"
                  placeholder="Search instructors..."
                  className="login-input"
                  id="search"
                  style={{ marginRight: 30, paddingRight: 20 }}
                />
              </div>
            )}
          </div>
          {/* Calendar table header */}
          {calendarType === 'daily' && buildDailyCalendar()}
          {calendarType === 'weekly' && buildWeeklyCalendar()}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: "45px",
            paddingTop: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                background: "#45CDCF",
                borderRadius: "100%",
                height: "10px",
                width: "10px",
                marginRight: "7px",
              }}
            ></div>
            <p
              style={{
                margin: "0",
                padding: "0",
                color: "#5A607F",
                marginRight: "25px",
              }}
            >
              First Lesson
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function AdminCalendarItem(props) {
  const { lesson, instructorAvailable } = props;
  const [showCancel, setShowCancel] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  function getColor() {
    if (showCancel) return "#DD3539";
    switch (lesson.status) {
      case 1: {
        return "#45CDCF";
      }
      case 2: {
        return "#F0142F";
      }
      default: {
        return "black";
      }
    }
  }

  const hasLesson = lesson?.studentName;
  return (
    <>
      {hasLesson ? (
        <p
          onMouseEnter={() => {
            setShowCancel(true);
          }}
          onMouseLeave={() => {
            setShowCancel(false);
          }}
          onClick={() => setConfirmModalOpen(true)}
          style={{
            color: getColor(),
            margin: 0,
            cursor: "pointer",
            fontWeight:
              lesson?.status === 1 || lesson?.status === 2 ? "600" : "400",
            overflow: "hidden",
          }}
        >
          {lesson?.studentName}
        </p>
      ) : instructorAvailable ? (
        <div style={{ width: "50%", height: "1px", background: "black" }}></div>
      ) : (
        <p>---</p>
      )}
      <ConfirmCancelLessonModal
        isOpen={confirmModalOpen}
        lesson={lesson}
        onRequestClose={() => {
          setConfirmModalOpen(false);
        }}
        onLessonCanceled={props.onLessonCanceled}
      />
    </>
  );
}

function ChevronFiller() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <button style={{ padding: "0", margin: "0" }} disabled={true}>
        <HiChevronLeft
          size={20}
          style={{
            marginLeft: "40px",
            marginRight: "20px",
            color: "transparent",
          }}
        />
      </button>
      <button style={{ padding: "0", margin: "0" }} disabled={true}>
        <HiChevronRight
          size={20}
          style={{
            color: "transparent",
          }}
        />
      </button>
    </div>
  );
}
