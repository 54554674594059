import { useState } from "react";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { AppButton } from "../components/AppButton";

function App() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberUser, setRememberUser] = useState(true);

  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const displayMessage = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
    });
  };

  async function loginUser() {
    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/users/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      }
    );

    const data = await response.json();

    if (data.user) {
      localStorage.setItem("token", data.user);
      window.location.href = "/dashboard";
    } else {
      displayMessage("Login failed. Check the email and password.", "error");
    }
    setLoading(false);
  }

  const handleCheck = () => {
    setRememberUser(!rememberUser);
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div
        style={{
          width: "60vw",
          alignItems: "center",
          alignContent: "center",
          textAlign: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
            marginTop: "20px",
            padding: "0px 50px",
          }}
        >
          <img
            src="/images/logo.png"
            style={{ width: "80px" }}
            alt="MWS Logo"
          />
          <h2
            style={{
              color: "#454545",
              letterSpacing: "0.1px",
              fontSize: "14px",
              cursor: "pointer",
            }}
            onClick={() => history.push("/")}
          >
            Home
          </h2>
        </div>
        <div style={{ marginTop: "20vh" }}>
          <div className="formContainer">
            <h3
              style={{
                textAlign: "start",
                fontSize: "36px",
                marginBottom: "20px",
              }}
            >
              Login to your account
            </h3>
            <p className="inputLabel">Email</p>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Email"
              className="login-input"
              id="email"
            />
            <p className="inputLabel" style={{ marginTop: "25px" }}>
              Password
            </p>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Password"
              className="login-input"
              id="password"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "30px",
              }}
            >
              <AppButton
                onClick={() => history.push("/register")}
                title="Register"
                outline={true}
                width="50%"
                fontSize="14px"
                margin="0 10px 0 0"
              />
              <AppButton
                loading={loading}
                onClick={loginUser}
                title="Login"
                width="50%"
                fontSize="14px"
                margin="0 0 0 10px"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "10px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  checked={rememberUser}
                  className="check"
                  onChange={handleCheck}
                />
                <Button
                  style={{
                    color: "#454545",
                    textTransform: "none",
                    fontWeight: "200",
                  }}
                  onClick={() => setRememberUser(!rememberUser)}
                >
                  Remember Me
                </Button>
              </div>

              <Button
                style={{
                  color: "#D7DBEC",
                  textTransform: "none",
                  fontWeight: "200",
                }}
                onClick={() => history.push("/forgot-password")}
              >
                Forgot Password
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "40vw",
          height: "100vh",
          backgroundImage: "url('/images/pool.png')",
        }}
      ></div>
    </div>
  );
}

export default App;
