import {useContext} from "react";
import {AppContext} from "../pages/AppContainer";
import {format, isAfter} from "date-fns";
import {getUtcDate} from "../utils";

export default function ExpiringLessonsRemaining(props) {
  let { currentUser } = useContext(AppContext);
  if (props.user) {
    currentUser = props.user
  }

  const filteredBalances = currentUser.expiringLessonBalances
    ?.filter(expBal => isAfter(getUtcDate(expBal.expiresAt), new Date()) && expBal.balance > 0) ?? [];
  const totalLessonsRemaining = (currentUser?.lessonBalance ?? 0)
    + (filteredBalances.reduce((acc, val) => acc + val.balance, 0) ?? 0);
  const getBalanceNextToExpire = filteredBalances
    .sort((a, b) => getUtcDate(a.expiresAt) - getUtcDate(b.expiresAt))?.[0];

  if (filteredBalances.length === 0) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
        background: "#45CDCF",
        borderRadius: "45px",
        height: "45px",
        boxShadow: "0px 8px 20px #0000001A",
      }}
    >
      <div
        style={{
          borderRadius: "45px",
          background: "white",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          height: "45px",
          width: "90px",
        }}
      >
        <p
          style={{
            padding: "0",
            margin: "0",
            color: "#cf4555",
            fontWeight: "600",
            fontSize: "24px",
            marginTop: "3px",
          }}
        >
          {getBalanceNextToExpire.balance}{'/'}{totalLessonsRemaining}
        </p>
      </div>
      <p
        style={{
          padding: "0 20px 0 10px",
          color: "white",
          fontWeight: "500",
        }}
      >
        {'Lesson(s) Expire '} {format(getUtcDate(getBalanceNextToExpire.expiresAt), "M/dd/yy")}
      </p>
    </div>
  );
}