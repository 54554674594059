import { SideNavItem } from "../components/SideNavItem";
import { RiDashboard2Line } from "react-icons/ri";
import { BiCalendarMinus, BiEnvelope } from "react-icons/bi";
import { MdOutlinePeopleAlt } from "react-icons/md";
import { FaDollarSign } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { BiGift } from "react-icons/bi";
import { useContext } from "react";
import { AppContext } from "../pages/AppContainer";
import {GiTeacher, HiOutlineDocumentReport, HiOutlineUsers} from "react-icons/all";

export function SideMenu(props) {
  const { currentUser } = useContext(AppContext);

  return (
    <div
      style={{
        width: "300px",
        minWidth: 280,
        background: "#004367",
        display: "flex",
        flexDirection: "column",
        paddingTop: "80px",
        height: "100vh",
      }}
    >
      {currentUser?.role === 0 ||
      currentUser?.role === 2 ||
      currentUser?.role === 3 ? (
        <button
          selected={props.currentTab === 0}
          onClick={() => props.onTabChange(0)}
          style={{
            margin: "0 10%",
            borderRadius: "30px",
            padding: "10px 0",
            background: "#406C8C",
            border: "none",
            marginTop: "30px",
            marginBottom: "35px",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BsFillPlusCircleFill
              size={24}
              style={{ color: "white", marginRight: "10px" }}
            />
            <p style={{ color: "white", fontSize: "16px" }}>Book a Lesson</p>
          </div>
        </button>
      ) : (
        <div style={{ marginBottom: 20 }}></div>
      )}

      {currentUser?.role < 1 && (
        // Not an admin
        <>
          <SideNavItem
            title="Dashboard"
            icon={<RiDashboard2Line style={{ color: "white" }} size={24} />}
            selectedIcon={
              <RiDashboard2Line style={{ color: "#F8C28A" }} size={24} />
            }
            selected={props.currentTab === 1}
            onSelect={() => props.onTabChange(1)}
          />
          <SideNavItem
            title="Calendar"
            icon={<BiCalendarMinus style={{ color: "white" }} size={24} />}
            selectedIcon={
              <BiCalendarMinus style={{ color: "#F8C28A" }} size={24} />
            }
            selected={props.currentTab === 2}
            onSelect={() => props.onTabChange(2)}
          />
          <SideNavItem
            title="Announcements"
            icon={<BiEnvelope style={{ color: "white" }} size={24} />}
            selectedIcon={<BiEnvelope style={{ color: "#F8C28A" }} size={24} />}
            selected={props.currentTab === 3}
            onSelect={() => props.onTabChange(3)}
          />
          <SideNavItem
            title="My Students"
            selected={props.currentTab === 4}
            icon={<MdOutlinePeopleAlt style={{ color: "white" }} size={24} />}
            selectedIcon={
              <MdOutlinePeopleAlt style={{ color: "#F8C28A" }} size={24} />
            }
            onSelect={() => props.onTabChange(4)}
          />
          <SideNavItem
            title="Purchase Lessons"
            selected={props.currentTab === 5}
            icon={<FaDollarSign style={{ color: "white" }} size={24} />}
            selectedIcon={
              <FaDollarSign style={{ color: "#F8C28A" }} size={24} />
            }
            onSelect={() => props.onTabChange(5)}
          />
          <SideNavItem
            title="Account Settings"
            selected={props.currentTab === 6}
            icon={<FiSettings style={{ color: "white" }} size={24} />}
            selectedIcon={<FiSettings style={{ color: "#F8C28A" }} size={24} />}
            onSelect={() => props.onTabChange(6)}
          />
        </>
      )}

      {currentUser?.role > 0 && (
        // User is at least an instructor
        <>
          <SideNavItem
            title="Dashboard"
            icon={<RiDashboard2Line style={{ color: "white" }} size={24} />}
            selectedIcon={
              <RiDashboard2Line style={{ color: "#F8C28A" }} size={24} />
            }
            selected={props.currentTab === 1}
            onSelect={() => props.onTabChange(1)}
          />
          <SideNavItem
            title="Calendar"
            icon={<BiCalendarMinus style={{ color: "white" }} size={24} />}
            selectedIcon={
              <BiCalendarMinus style={{ color: "#F8C28A" }} size={24} />
            }
            selected={props.currentTab === 2}
            onSelect={() => props.onTabChange(2)}
          />
          <SideNavItem
            title="Announcements"
            icon={<BiEnvelope style={{ color: "white" }} size={24} />}
            selectedIcon={<BiEnvelope style={{ color: "#F8C28A" }} size={24} />}
            selected={props.currentTab === 3}
            onSelect={() => props.onTabChange(3)}
          />
          <SideNavItem
            title="Students"
            selected={props.currentTab === 4}
            icon={<MdOutlinePeopleAlt style={{ color: "white" }} size={24} />}
            selectedIcon={
              <MdOutlinePeopleAlt style={{ color: "#F8C28A" }} size={24} />
            }
            onSelect={() => props.onTabChange(4)}
          />
          {currentUser?.role > 1 && (
            <SideNavItem
              title="Instructors"
              selected={props.currentTab === 5}
              icon={<GiTeacher style={{ color: "white" }} size={24} />}
              selectedIcon={
                <GiTeacher style={{ color: "#F8C28A" }} size={24} />
              }
              onSelect={() => props.onTabChange(5)}
            />
          )}
          {currentUser?.role > 1 && (
            <SideNavItem
              title="Listed Lessons"
              selected={props.currentTab === 6}
              icon={<FaDollarSign style={{ color: "white" }} size={24} />}
              selectedIcon={
                <FaDollarSign style={{ color: "#F8C28A" }} size={24} />
              }
              onSelect={() => props.onTabChange(6)}
            />
          )}
          {currentUser?.role > 1 && (
            <>
              <SideNavItem
                title="Expiring Lessons Report"
                selected={props.currentTab === 9}
                icon={<HiOutlineDocumentReport style={{ color: "white" }} size={24} />}
                selectedIcon={
                  <HiOutlineDocumentReport style={{ color: "#F8C28A" }} size={24} />
                }
                onSelect={() => props.onTabChange(9)}
              />
              <SideNavItem
                title="User Admin"
                selected={props.currentTab === 10}
                icon={<HiOutlineUsers style={{ color: "white" }} size={24} />}
                selectedIcon={
                  <HiOutlineUsers style={{ color: "#F8C28A" }} size={24} />
                }
                onSelect={() => props.onTabChange(10)}
              />
            </>
          )}
          <SideNavItem
            title="Account Settings"
            selected={props.currentTab === 7}
            icon={<FiSettings style={{ color: "white" }} size={24} />}
            selectedIcon={<FiSettings style={{ color: "#F8C28A" }} size={24} />}
            onSelect={() => props.onTabChange(7)}
          />

          {currentUser?.role > 1 && (
            <button
              selected={props.currentTab === 8}
              onClick={() => props.onTabChange(8)}
              style={{
                margin: "0 10%",
                borderRadius: "30px",
                padding: "10px 0",
                background: "#406C8C",
                border: "none",
                marginTop: "30px",
                marginBottom: "35px",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <BiGift
                  size={24}
                  style={{ color: "white", marginRight: "10px" }}
                />
                <p style={{ color: "white", fontSize: "16px" }}>Gift Lessons</p>
              </div>
            </button>
          )}
        </>
      )}
    </div>
  );
}
