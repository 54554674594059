import { Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { FiChevronDown } from "react-icons/fi";
import { BiBell } from "react-icons/bi";
import { MdLogout } from "react-icons/md";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BsPersonCircle } from "react-icons/bs";
import { AppContext } from "../pages/AppContainer";
import { reqHeaders } from "../utils";
import { format } from "date-fns";
import SlidingPane from "react-sliding-pane";
import { AllNotificationsPanel } from "./modals/AllNotificationsPanel";

export function TopHeader(props) {
  const { currentUser } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const history = useHistory();
  const [unreadCount, setUnreadCount] = useState(0);
  const [notificationsPanelOpen, setNofiticationsPanelOpen] = useState(false);

  useEffect(() => {
    fetchNotifications(0);
  }, []);

  async function logout() {
    localStorage.removeItem("token");
    history.push("/login");
  }

  function getName() {
    const firstName = currentUser?.firstName ?? null;
    const lastName = currentUser?.lastName ?? null;
    if (!firstName && !lastName) {
      return "";
    }
    return `${firstName ? firstName : ""} ${lastName ? lastName : ""}`;
  }

  async function fetchNotifications(newCurrentPage) {
    try {
      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/notifications?page=${newCurrentPage}`,
        {
          method: "GET",
          headers: reqHeaders,
        }
      );
      const res = await req.json();
      if (res.data) {
        const notis = res.data;
        setNotifications(notis);
        const unread = notis.filter((noti) => {
          if (!noti.readAt) {
            return true;
          }
          return false;
        }).length;
        setUnreadCount(unread);
      }
    } catch (err) {
      console.debug("Notifications error: ", err);
    }
  }

  async function setNotificationsRead() {
    const notificationIds = [];
    for (const noti of notifications) {
      notificationIds.push(noti._id);
    }
    if (notifications.length < 1) return;
    try {
      setUnreadCount(0);
      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/notifications`,
        {
          method: "PUT",
          headers: reqHeaders,
          body: JSON.stringify({
            notificationIds: notificationIds,
          }),
        }
      );
      if (req.status === 200) {
        setUnreadCount(0);
      }
    } catch (err) {
      console.debug("Notifications error: ", err);
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
        padding: "0px 100px",
        boxShadow: "0px 1px 4px #15223214",
        position: "fixed",
        width: "100vw",
        background: "white",
        zIndex: "99",
      }}
    >
      <img
        src="/images/logo.png"
        style={{ width: "70px", margin: "10px 0" }}
        alt="MWS Logo"
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <a href="https://mountainwaveswimming.com/" className="top-header-link">
          <p>Home</p>
        </a>
        <a
          href="https://mountainwaveswimming.com/about-us/"
          className="top-header-link"
        >
          <p>About Us</p>
        </a>
        <a
          href="https://mountainwaveswimming.com/services/"
          className="top-header-link"
        >
          <p>Services</p>
        </a>
        <a
          href="https://mountainwaveswimming.com/blog/"
          className="top-header-link"
        >
          <p>Blog</p>
        </a>
        <Dropdown
          floating
          open={notificationsOpen}
          className="notification-dropdown"
          onClose={() => setNotificationsOpen(false)}
        >
          <Dropdown.Menu style={{ overflow: "auto" }}>
            {notifications.map((noti, index) => (
              <Dropdown.Item
                key={noti._id}
                onClick={() => {
                  setNotificationsOpen(false);
                  setNofiticationsPanelOpen(true);
                }}
                content={
                  <div
                    style={{
                      width: 250,
                      whiteSpace: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      {noti.title}
                    </p>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 12,
                        color: "#45CDCF",
                        fontWeight: 400,
                      }}
                    >
                      {format(new Date(noti.createdAt), "MMM dd, yyyy")}
                    </p>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 11,
                        fontWeight: 400,
                        marginTop: 10,
                      }}
                    >
                      {noti.content}
                    </p>
                    {index !== notifications.length - 1 && (
                      <div
                        style={{
                          height: 1,
                          width: "100%",
                          margin: "10px 0",
                          background: "#D7DBEC",
                        }}
                      ></div>
                    )}
                  </div>
                }
              />
            ))}
            {notifications.length > 0 && (
              <Dropdown.Item
                key={"more"}
                content={
                  <div
                    style={{
                      width: 250,
                      marginTop: 10,
                    }}
                    onClick={() => {
                      setNotificationsOpen(false);
                      setNofiticationsPanelOpen(true);
                    }}
                  >
                    All notifications
                  </div>
                }
              />
            )}
            {notifications.length < 1 && (
              <div style={{ width: 250 }}>
                <p style={{ padding: "20px 10px" }}>No notifications</p>
              </div>
            )}
          </Dropdown.Menu>
        </Dropdown>
        <div
          style={{ marginLeft: 50, marginTop: 10, cursor: "pointer" }}
          onClick={() => {
            setNotificationsOpen(!notificationsOpen);
            setNotificationsRead();
          }}
        >
          {unreadCount > 0 && (
            <div
              className="col"
              style={{
                background: "#F0142F",
                width: 15,
                height: 15,
                borderRadius: "100%",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                marginLeft: 12,
                marginTop: "-3px",
              }}
            >
              <p
                style={{
                  color: "white",
                  padding: 0,
                  margin: 0,
                  marginTop: "1px",
                  fontSize: 10,
                }}
              >
                {unreadCount}
              </p>
            </div>
          )}
          <BiBell
            size={24}
            style={{
              color: "black",
              marginRight: "14px",
            }}
          />
        </div>

        <button onClick={() => setOpen(!open)}>
          {/* <img
            src="/images/person.jpeg"
            alt=""
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "100px",
              objectFit: "cover",
              marginTop: "4px",
            }}
          /> */}
          {!currentUser?.profilePhoto ? (
            <BsPersonCircle size={30} style={{ color: "#DEDEDE" }} />
          ) : (
            <img
              src={currentUser?.profilePhoto ?? ""}
              alt=""
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "100px",
                objectFit: "cover",
              }}
            />
          )}
        </button>
        <Dropdown
          text={getName()}
          floating
          open={open}
          onClick={() => setOpen(!open)}
          onClose={() => setOpen(false)}
        >
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={props.onNavToAccount}
              content={
                <p style={{ fontSize: "12px", fontWeight: "bold" }}>
                  Account Settings
                </p>
              }
            />
            {/* <Dropdown.Item
              content={
                <p style={{ fontSize: "12px", fontWeight: "bold" }}>Support</p>
              }
            /> */}
            <Dropdown.Item
              onClick={logout}
              content={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      padding: "0",
                      margin: "0",
                    }}
                  >
                    Log Out
                  </p>
                  <MdLogout
                    size={14}
                    style={{ color: "black", marginLeft: "10px" }}
                  />
                </div>
              }
            />
          </Dropdown.Menu>
        </Dropdown>
        <button onClick={() => setOpen(!open)}>
          <FiChevronDown
            size={24}
            style={{ color: "#7E84A3", paddingTop: "6px" }}
          />
        </button>
      </div>
      <SlidingPane
        isOpen={notificationsPanelOpen}
        from="right"
        width="400px"
        onRequestClose={() => {
          setNofiticationsPanelOpen(false);
          fetchNotifications(0);
        }}
      >
        <AllNotificationsPanel
          onRequestClose={() => {
            setNofiticationsPanelOpen(false);
            fetchNotifications(0);
          }}
          notifications={notifications}
        />
      </SlidingPane>
    </div>
  );
}
