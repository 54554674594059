export function SideNavItem(props) {
  return (
    <button
      onClick={props.onSelect}
      style={{
        background: props.selected ? "#406C8C" : "transparent",
        marginRight: "10px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        boxShadow: "none",
        border: "none",
        padding: "0",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "55px",
            width: "4px",
            background: props.selected ? "#F8C28A" : "transparent",
            borderRadius: "10px",
            marginRight: "6%",
          }}
        ></div>
        {props.selected ? props.selectedIcon : props.icon}
        {/* <img
          src={props.selected ? "icons/inbox.svg" : "icons/inbox.svg"}
          alt=""
          style={{
            width: "20px",
            height: "18px",
            marginRight: "6%",
          }}
        /> */}
        <div style={{ width: "80%", marginLeft: "6%" }}>
          <p
            style={{
              color: props.selected ? "#F8C28A" : "white",
              fontSize: "15px",
              textAlign: "start",
            }}
          >
            {props.title}
          </p>
        </div>
      </div>
    </button>
  );
}
