import { useContext, useEffect, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { useWindowSize } from "../hooks/windowSize";
import { AdminProductItem } from "../components/AdminProductItem";
import { EditProductModal } from "../components/modals/EditProductModal";
import { AppButton } from "../components/AppButton";
import { AppContext } from "./AppContainer";

export function ListedLessons(props) {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const { currentUser } = useContext(AppContext);

  useEffect(() => {
    fetchProducts();
  }, []);

  async function fetchProducts() {
    setLoading(true);
    const req = await fetch(`${process.env.REACT_APP_API_URL}/products`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    const res = await req.json();
    // Order by last name
    if (res.data) {
      res.data.sort(function (a, b) {
        if (a.price < b.price) return -1;
        if (a.price > b.price) return 1;
        return 0;
      });
      setProducts(res.data);
    }
    setLoading(false);
  }

  const size = useWindowSize();

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "30px 60px 0 60px",
        }}
      >
        <h1 className="page-title">Listed Lessons</h1>
        {!loading && currentUser?.role > 1 && (
          <AppButton
            onClick={() => setCreateModalIsOpen(true)}
            title="New Lesson Option"
            showPlus={true}
            fontSize={"14px"}
            plusSize={"18px"}
            padding="0px 14px"
            margin={"0 10px 0 0"}
          />
        )}
      </div>

      <div
        style={{
          height: size?.height ?? 0 - 200,
          overflow: "auto",
          padding: "30px 60px 30px 60px",
        }}
      >
        {loading && (
          <div style={{ textAlign: "center", margin: "50px 0" }}>
            <CircularProgress size={26} style={{ color: "#004367" }} />
          </div>
        )}
        <Grid
          container
          style={{
            justifyContent: "start",
          }}
          columnSpacing={"30px"}
        >
          {!loading &&
            products &&
            products.map((product) => (
              <Grid item key={product.productId}>
                <AdminProductItem
                  product={product}
                  onSelected={() => {}}
                  onProductUpdated={() => fetchProducts()}
                />
              </Grid>
            ))}
        </Grid>
      </div>
      <EditProductModal
        isEditing={false}
        isOpen={createModalIsOpen}
        onRequestClose={() => {
          setCreateModalIsOpen(false);
        }}
        onProductCreated={() => {
          setCreateModalIsOpen(false);
          fetchProducts();
        }}
      />
    </div>
  );
}
