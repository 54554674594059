import { useContext, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { AppContext } from "../../pages/AppContainer";
import PaginationControl from "../PaginationControl";

export function ChooseParentPanel(props) {
  const { parents, fetchParents, totalParentPages } = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(0);

  function previousPressed() {
    const newCurrentPage = currentPage - 1;
    setCurrentPage(newCurrentPage);
    fetchParents(newCurrentPage);
  }

  function nextPressed() {
    const newCurrentPage = currentPage + 1;
    setCurrentPage(newCurrentPage);
    fetchParents(newCurrentPage);
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div style={{ display: "flex", justifyContent: "end" }}>
          <button onClick={props.onRequestClose}>
            <GrFormClose size={28} />
          </button>
        </div>
        <p style={{ fontSize: "26px", fontWeight: "600", marginTop: "20px" }}>
          Select a Parent
        </p>
        {parents?.length > 0 && (
          <>
            {parents.map((parent) => (
              <button
                className="parent-item"
                key={parent._id}
                onClick={() => {
                  props.onParentSelected(parent._id);
                  props.onRequestClose();
                }}
              >
                <div id={parent._id}>
                  <p style={{ margin: "0" }}>
                    {parent.firstName} {parent.lastName}
                  </p>
                  <p style={{ margin: "0", fontSize: "12px" }}>
                    {parent.email}
                  </p>
                </div>
              </button>
            ))}
            <PaginationControl
              totalPages={totalParentPages}
              currentPage={currentPage}
              onPreviousPressed={previousPressed}
              onNextPressed={nextPressed}
              addTopMargin={true}
            />
          </>
        )}
        {!parents ||
          (parents.length < 1 && <p>There are no parents signed up yet</p>)}
      </div>
    </>
  );
}
