/* eslint-disable react-hooks/exhaustive-deps */
import Modal from "react-modal";
import { ImCross } from "react-icons/im";
import { useContext, useEffect, useState } from "react";
import { AppButton } from "../AppButton";
import { useSnackbar } from "notistack";
import "react-datepicker/dist/react-datepicker.css";
import { BsPersonCircle } from "react-icons/bs";
import { getTimeStringFromSeconds, reqHeaders } from "../../utils";
import ProgressBar from "@ramonak/react-progress-bar";
import { CircularProgress } from "@mui/material";
import { HiSearchCircle } from "react-icons/hi";
import { format } from "date-fns";
import { HiChevronRight } from "react-icons/hi";
import { AppContext } from "../../pages/AppContainer";

export function BookLessonModalAdmin(props) {
  const { fetchUpcomingLessons } = useContext(AppContext);
  const [searchText, setSearchText] = useState("");
  const [fetchStudentsLoading, setFetchStudentsLoading] = useState(false);
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState();

  const [studentData, setStudentData] = useState();
  const [instructorData, setInstructorData] = useState();

  const date = props.selectedDate;
  const time = props.selectedTime;

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const searchDelay = setTimeout(() => {
      setSelectedStudent(null);
      fetchStudents();
    }, 500);
    return () => clearTimeout(searchDelay);
  }, [searchText]);

  async function fetchStudents() {
    setFetchStudentsLoading(true);
    const text = searchText.trim();
    if (!text || text.length < 1) {
      setFetchStudentsLoading(false);
      return;
    }
    try {
      const req = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }/students/search/all/${encodeURIComponent(text)}`,
        {
          method: "GET",
          headers: reqHeaders,
        }
      );
      const res = await req.json();
      // Order by last name
      if (res.data) {
        res.data.sort(function (a, b) {
          if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) return -1;
          if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) return 1;
          return 0;
        });
        setStudents(res.data);
      }
      setFetchStudentsLoading(false);
    } catch (err) {
      console.debug("Error: ", err);
      setFetchStudentsLoading(false);
    }
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "20px",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      backdropFilter: "blur(2px)",
    },
  };

  async function bookLesson() {
    setPurchaseLoading(true);
    const instructorId = props.selectedInstructor;

    if (!date || !time || !selectedStudent || !instructorId) {
      setPurchaseLoading(false);
      displayMessage(
        "Something went wrong. Please refresh the page and try again.",
        "error"
      );
      return;
    }
    try {
      const req = await fetch(`${process.env.REACT_APP_API_URL}/lessons`, {
        method: "POST",
        headers: reqHeaders,
        body: JSON.stringify({
          studentId: selectedStudent._id,
          instructorId: instructorId,
          studentName:
            `${selectedStudent?.firstName} ${selectedStudent?.lastName}`.trimEnd(),
          // locationId: 0, // Later feature?
          startMinuteOfDay: time,
          date: date,
        }),
      });
      if (req.status === 200) {
        displayMessage("The lesson has been booked", "success");
        props.onLessonBooked();
        setStep(2);
        await fetchLessonParticipants();
        setSelectedStudent();
        setSearchText("");
        fetchUpcomingLessons();
      } else {
        const res = await req.json();
        if (res.message === "The user's parent doesn't have any lessons left") {
          displayMessage(
            "This student's parent doesn't have any remaining lessons in their balance",
            "error"
          );
          setPurchaseLoading(false);
          return;
        }
        displayMessage(
          "Something went wrong. Please refresh the page and try again.",
          "error"
        );
      }
    } catch (err) {
      console.debug(err);
      displayMessage(
        "Something went wrong. Please refresh the page and try again.",
        "error"
      );
      setPurchaseLoading(false);
    }
    setPurchaseLoading(false);
  }

  async function fetchLessonParticipants() {
    const instructorId = props.selectedInstructor;
    try {
      const instructorReq = await fetch(
        `${process.env.REACT_APP_API_URL}/users/instructors/${instructorId}`,
        {
          method: "GET",
          headers: reqHeaders,
        }
      );
      const studentReq = await fetch(
        `${process.env.REACT_APP_API_URL}/students/student/${selectedStudent._id}`,
        {
          method: "GET",
          headers: reqHeaders,
        }
      );
      if (instructorReq.status === 200) {
        const instructor = await instructorReq.json();
        setInstructorData(instructor.user);
      }
      if (studentReq.status === 200) {
        const student = await studentReq.json();
        setStudentData(student.data);
      }
    } catch (err) {
      console.debug(err);
      displayMessage("Failed to load user data", "error");
      setPurchaseLoading(false);
    }
    setPurchaseLoading(false);
  }

  function studentSelected(student) {
    setStudents([]);
    setSelectedStudent(student);
  }

  const displayMessage = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
    });
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={() => {
        setStep(1);
        props.onRequestClose();
      }}
      style={customStyles}
      ariaHideApp={false}
      onAfterClose={props.onAfterClose}
    >
      <div
        style={{
          width: "70vw",
          minWidth: "400px",
          height: "55vh",
          padding: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "90%",
              display: "flex",
              flexDirection: "column",
              marginTop: "10px",
            }}
          >
            <ProgressBar
              height="17px"
              isLabelVisible={false}
              baseBgColor="#0000002F"
              bgColor="#F8C28A"
              completed={(step === 1 ? 2 / 3 : 1) * 100}
            />
            <p style={{ color: "#454545", fontSize: "10px", marginTop: "3px" }}>
              Step {step === 1 ? "2" : "3"} of 3
            </p>
          </div>
          <button
            onClick={() => {
              setStep(1);
              props.onRequestClose();
            }}
          >
            <ImCross size={12} style={{ color: "#B2AEAE" }} />
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "70px",
          }}
        >
          <p style={{ fontWeight: "600", fontSize: "30px", margin: "0" }}>
            {step === 1
              ? "Which student are you booking for?"
              : "Student Successfully Booked"}
          </p>
          <div style={{ width: "70%", marginBottom: "50px", marginTop: 10 }}>
            {step === 1 ? (
              <>
                {!purchaseLoading && !fetchStudentsLoading ? (
                  <div
                    className="col"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "45px",
                      }}
                    >
                      <div
                        style={{
                          borderRadius: "30px",
                          border: "2px solid #d7dbec",
                          width: "70%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <input
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          type="text"
                          autoFocus
                          placeholder="Search..."
                          className="modal-search"
                          style={{ marginLeft: "30px", width: "50%" }}
                        />
                        <HiSearchCircle color="#45CDCF" size={40} />
                      </div>
                    </div>
                    {selectedStudent && (
                      <div
                        className="row"
                        style={{
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          marginTop: "70px",
                        }}
                      >
                        <div
                          className="row"
                          style={{
                            alignItems: "center",
                          }}
                        >
                          {!selectedStudent?.profilePhoto ? (
                            <BsPersonCircle
                              size={80}
                              style={{ color: "#DEDEDE" }}
                            />
                          ) : (
                            <img
                              src={selectedStudent?.profilePhoto ?? ""}
                              alt=""
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "100px",
                                objectFit: "cover",
                              }}
                            />
                          )}
                          <div className="col" style={{ marginLeft: "15px" }}>
                            <p
                              style={{
                                fontSize: "18px",
                                fontWeight: "600",
                                padding: "0",
                                margin: "0",
                              }}
                            >
                              {selectedStudent.firstName}{" "}
                              {selectedStudent.lastName}
                            </p>
                            <p style={{ fontSize: "16px", color: "#818181" }}>
                              <span
                                style={{ fontWeight: "600", color: "#45CDCF" }}
                              >
                                {selectedStudent.lessonBalance}
                              </span>{" "}
                              {selectedStudent.lessonBalance !== 1
                                ? "Lessons"
                                : "Lesson"}{" "}
                              Remaining
                            </p>
                          </div>
                        </div>
                        <div
                          className="row"
                          style={{
                            justifyContent: "end",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <AppButton
                            onClick={() => {
                              setSelectedStudent();
                              fetchStudents();
                            }}
                            outline={true}
                            title="Cancel"
                            fontSize={"14px"}
                            margin="0px 10px 0 0"
                          />
                          <AppButton
                            onClick={() => {
                              bookLesson();
                            }}
                            title="Continue Booking"
                            fontSize={"14px"}
                            padding="12px 30px"
                          />
                        </div>
                      </div>
                    )}
                    {students.length > 0 && (
                      <div
                        className="col"
                        style={{
                          width: "50%",
                          minHeight: "0px",
                          maxHeight: "200px",
                          marginTop: "-2px",
                          borderLeft: "2px solid #d7dbec",
                          borderRight: "2px solid #d7dbec",
                          borderBottom: "2px solid #d7dbec",
                          overflow: "scroll",
                          overflowX: "hidden",
                        }}
                      >
                        {students.map((student) => (
                          <p
                            onClick={() => studentSelected(student)}
                            className="student-name-result"
                            key={student._id}
                          >
                            {`${student.firstName} ${student.lastName}`}
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress
                      size={26}
                      style={{ color: "black", marginTop: "100px" }}
                    />
                  </div>
                )}
              </>
            ) : (
              <div className="col">
                <div
                  className="row"
                  style={{ marginBottom: "20px", justifyContent: "center" }}
                >
                  <p className="reminder-text" style={{ textAlign: "center" }}>
                    The parent's account will be notified that this lesson has
                    been booked
                  </p>
                </div>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    fontWeight: "600",
                    margin: "0",
                  }}
                >
                  {format(new Date(date), "MMM d, yyyy")}
                </p>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#45CDCF",
                    marginTop: "5px",
                  }}
                >
                  {getTimeStringFromSeconds(time)}
                </p>

                <div
                  className="row"
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    className="col"
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    {!studentData?.profilePhoto ? (
                      <BsPersonCircle
                        size={80}
                        style={{
                          color: "#DEDEDE",
                          boxShadow: "0px 3px 6px #00000029",
                          borderRadius: "100%",
                        }}
                      />
                    ) : (
                      <img
                        src={studentData?.profilePhoto ?? ""}
                        alt=""
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "100px",
                          objectFit: "cover",
                          boxShadow: "0px 3px 6px #00000029",
                        }}
                      />
                    )}
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: "20px",
                        marginTop: "10px",
                      }}
                    >
                      {studentData?.firstName ?? ""}
                    </p>
                  </div>
                  <HiChevronRight
                    color="#D8DBEB"
                    size={60}
                    style={{ margin: "0 10px" }}
                  />
                  <div
                    className="col"
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    {!instructorData?.profilePhoto ? (
                      <BsPersonCircle
                        size={80}
                        style={{
                          color: "#DEDEDE",
                          boxShadow: "0px 3px 6px #00000029",
                          borderRadius: "100%",
                        }}
                      />
                    ) : (
                      <img
                        src={instructorData?.profilePhoto ?? ""}
                        alt=""
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "100px",
                          objectFit: "cover",
                          boxShadow: "0px 3px 6px #00000029",
                        }}
                      />
                    )}
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: "20px",
                        marginTop: "10px",
                      }}
                    >
                      {instructorData?.firstName ?? ""}
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "30px",
                  }}
                >
                  <AppButton
                    onClick={() => {
                      setStep(1);
                      props.onRequestClose();
                    }}
                    title="Book Another Lesson"
                    outline={true}
                    fontSize="14px"
                    margin="0 10px 0 0"
                  />
                  <AppButton
                    onClick={() => {
                      setStep(1);
                      props.onRequestClose();
                    }}
                    title="Finish Booking"
                    fontSize="14px"
                    padding="12px 30px"
                    margin="0 0 0 10px"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
