import Switch from "react-switch";
import { useSnackbar } from "notistack";
import { useContext, useState } from "react";
import { AppContext } from "../../pages/AppContainer";
import { reqHeaders } from "../../utils";
import { AppButton } from "../AppButton";
import { BsExclamationSquareFill, BsBellFill } from "react-icons/bs";

export default function NotificationPreferences() {
  const { currentUser } = useContext(AppContext);
  const [announcementsEmailOn, setAnnouncementsEmailOn] = useState(
    currentUser?.notificationPreferences?.announcementsEmail ?? false
  );
  const [appointmentEmailOn, setAppointmentEmailOn] = useState(
    currentUser?.notificationPreferences?.appointmentRemindersEmail ?? false
  );

  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  async function saveNotificationPreferences() {
    try {
      setLoading(true);
      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/users/notification-preferences`,
        {
          method: "PUT",
          headers: reqHeaders,
          body: JSON.stringify({
            announcementsEmail: announcementsEmailOn,
            appointmentRemindersEmail: appointmentEmailOn,
          }),
        }
      );

      if (req.status === 200) {
        displayMessage(
          "Your notification preferences have been updated",
          "success"
        );
        setLoading(false);
      } else {
        setLoading(false);
        displayMessage("Something went wrong. Please try again.", "error");
      }
    } catch (err) {
      setLoading(false);
      displayMessage("Something went wrong. Please try again.", "error");
      console.debug(err);
    }
  }

  const displayMessage = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
    });
  };

  return (
    <div
      style={{
        background: "white",
        padding: "40px",
        boxShadow: "0px 4px 15px #00000014",
        borderRadius: "23px",
        margin: "10px 0",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p
          style={{
            margin: "0",
            padding: "0",
            fontSize: "18px",
            fontWeight: "600",
            marginBottom: 20,
          }}
        >
          Notifications
        </p>
        <div className="row">
          <BsExclamationSquareFill color="#D7DBEC" size={18} />
          <p
            style={{
              margin: "0",
              padding: "0",
              fontSize: 14,
              fontWeight: "600",
              marginBottom: 0,
              marginLeft: 10,
            }}
          >
            Announcements
          </p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "10px",
            alignItems: "center",
          }}
        >
          <Switch
            onChange={() => setAnnouncementsEmailOn(!announcementsEmailOn)}
            checked={announcementsEmailOn}
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.3)"
            height={19}
            width={35}
            onHandleColor="#45CDCF"
            offHandleColor="#D7DBEC"
            onColor="#e3e3e3"
            offColor="#e3e3e3"
          />
          <p
            style={{
              color: "#7E84A3",
              marginLeft: "10px",
              marginRight: "20px",
            }}
          >
            Email
          </p>
        </div>
        <div className="row" style={{ marginTop: 30 }}>
          <BsBellFill color="#D7DBEC" size={20} />
          <p
            style={{
              margin: "0",
              padding: "0",
              fontSize: 14,
              fontWeight: "600",
              marginBottom: 0,
              marginLeft: 10,
            }}
          >
            Appointment Reminders
          </p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "10px",
            alignItems: "center",
          }}
        >
          <Switch
            onChange={() => setAppointmentEmailOn(!appointmentEmailOn)}
            checked={appointmentEmailOn}
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.3)"
            height={19}
            width={35}
            onHandleColor="#45CDCF"
            offHandleColor="#D7DBEC"
            onColor="#e3e3e3"
            offColor="#e3e3e3"
          />
          <p
            style={{
              color: "#7E84A3",
              marginLeft: "10px",
              marginRight: "20px",
            }}
          >
            Email
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          marginTop: "30px",
        }}
      >
        <AppButton
          loading={loading}
          onClick={saveNotificationPreferences}
          title="Save"
          width="30%"
          fontSize="14px"
          margin="0 0 0 10px"
        />
      </div>
    </div>
  );
}
