import { useWindowSize } from "../../hooks/windowSize";
import { BillingInformation } from "./BillingInformation";
import PersonalInformation from "./PersonalInformation";
import { VerticalSpace } from "../Spacer";
import { useContext } from "react";
import { AppContext } from "../../pages/AppContainer";
import ChangePassword from "./ChangePassword";
import NotificationPreferences from "./NotificationPreferences";
export function UserSettings(props) {
  const size = useWindowSize();

  const { currentUser } = useContext(AppContext);
  const lessonBalance = currentUser?.lessonBalance ?? 0;

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "center",
          padding: "30px 60px 0 60px",
        }}
      >
        <h1 className="page-title">Account Settings</h1>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
            background: "#45CDCF",
            borderRadius: "45px",
            height: "45px",
            boxShadow: "0px 8px 20px #0000001A",
          }}
        >
          <div
            style={{
              borderRadius: "45px",
              background: "white",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "45px",
              width: "45px",
            }}
          >
            <p
              style={{
                padding: "0",
                margin: "0",
                color: "#45CDCF",
                fontWeight: "600",
                fontSize: "24px",
                marginTop: "3px",
              }}
            >
              {lessonBalance}
            </p>
          </div>
          <p
            style={{
              padding: "0 20px 0 10px",
              color: "white",
              fontWeight: "500",
            }}
          >
            {lessonBalance !== 1 ? "Lessons" : "Lesson"} remaining
          </p>
        </div>
      </div>
      <div
        style={{
          height: size?.height ? size.height - 170 : "50vh",
          overflow: "auto",
          padding: "30px 60px 30px 60px",
        }}
      >
        <PersonalInformation />
        <VerticalSpace amount="2px" />
        <BillingInformation />
        <div className="row" style={{ justifyContent: "end", width: "100%" }}>
          <NotificationPreferences />
          <div style={{ marginRight: "20px" }}></div>
          <ChangePassword />
        </div>
      </div>
      {/* // Modals here */}
    </div>
  );
}
