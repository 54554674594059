import { useState } from "react";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { deformatPhoneNumber, formatPhoneNumber } from "../utils";
import { AppButton } from "../components/AppButton";

function App() {
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [rememberUser, setRememberUser] = useState(true);

  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const displayMessage = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
    });
  };

  const validateForm = () => {
    if (!firstName || firstName.length < 1) {
      return "Please enter a first name";
    }
    if (!lastName || lastName.length < 1) {
      return "Please enter a last name";
    }
    if (!email || email.length < 1) {
      return "Please enter a valid email";
    }
    if (!phone || phone.replace(/\D/g, "").length !== 10) {
      return "Please enter a valid phone number";
    }
    if (!password || password.length < 6) {
      return "Password must be at least 6 characters";
    }
    if (password !== confirmPassword) {
      return "Passwords don't match";
    }
  };

  async function registerUser(event) {
    setLoading(true);
    const validateError = validateForm();
    if (validateError) {
      displayMessage(validateError, "error");
      setLoading(false);
      return;
    }
    event.preventDefault();
    const formattedPhone = deformatPhoneNumber(phone);
    const req = await fetch(`${process.env.REACT_APP_API_URL}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName,
        lastName,
        formattedPhone,
        email,
        password,
      }),
    });
    if (req.status === 200) {
      await login();
    } else {
      displayMessage(
        "Failed to create account. Make sure you used valid data.",
        "error"
      );
    }
    setLoading(false);
  }

  async function login() {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/users/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      }
    );

    const data = await response.json();

    if (data.user) {
      localStorage.setItem("token", data.user);
      window.location.href = "/dashboard";
    } else {
      displayMessage("Login failed. Check the email and password.", "error");
    }
  }

  const handleCheck = () => {
    setRememberUser(!rememberUser);
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div
        style={{
          width: "60vw",
          alignItems: "center",
          alignContent: "center",
          textAlign: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
            marginTop: "20px",
            padding: "0px 50px",
          }}
        >
          <img
            src="/images/logo.png"
            style={{ width: "80px" }}
            alt="MWS Logo"
          />
          <h2
            style={{
              color: "#454545",
              letterSpacing: "0.1px",
              fontSize: "14px",
              cursor: "pointer",
            }}
            onClick={() => history.push("/")}
          >
            Home
          </h2>
        </div>
        <div style={{ marginTop: "10vh" }}>
          <div className="formContainer">
            <h3
              style={{
                textAlign: "start",
                fontSize: "36px",
                marginBottom: "20px",
              }}
            >
              Create your account
            </h3>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "48%",
                }}
              >
                <p className="inputLabel">First Name</p>
                <input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  type="text"
                  placeholder="First Name"
                  className="login-input"
                  id="first-name"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "48%",
                }}
              >
                <p className="inputLabel">Last Name</p>
                <input
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  type="text"
                  placeholder="Last Name"
                  className="login-input"
                  id="last-name"
                />
              </div>
            </div>
            <p className="inputLabel" style={{ marginTop: "15px" }}>
              Email
            </p>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Type Email"
              className="login-input"
              id="email"
            />
            <p className="inputLabel" style={{ marginTop: "15px" }}>
              Phone
            </p>
            <input
              value={phone}
              onChange={(e) => {
                const formattedPhoneNumber = formatPhoneNumber(e.target.value);
                setPhone(formattedPhoneNumber);
              }}
              type="text"
              placeholder="Type Phone"
              className="login-input"
              id="phone"
            />
            <p className="inputLabel" style={{ marginTop: "15px" }}>
              Password
            </p>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Type Password"
              className="login-input"
              id="password"
            />
            <p className="inputLabel" style={{ marginTop: "15px" }}>
              Confirm Password
            </p>
            <input
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="password"
              placeholder="Type Password"
              className="login-input"
              id="password"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "30px",
              }}
            >
              <AppButton
                onClick={() => history.push("/login")}
                title="Login"
                outline={true}
                width="50%"
                fontSize="14px"
                margin="0 10px 0 0"
              />
              <AppButton
                loading={loading}
                onClick={registerUser}
                title="Create Account"
                width="50%"
                fontSize="14px"
                margin="0 0 0 10px"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "10px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  checked={rememberUser}
                  className="check"
                  onChange={handleCheck}
                />
                <Button
                  style={{
                    color: "#454545",
                    textTransform: "none",
                    fontWeight: "200",
                  }}
                  onClick={() => setRememberUser(!rememberUser)}
                >
                  Remember Me
                </Button>
              </div>

              <Button
                style={{
                  color: "#D7DBEC",
                  textTransform: "none",
                  fontWeight: "200",
                }}
                onClick={() => history.push("/forgot-password")}
              >
                Forgot Password
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "40vw",
          height: "100vh",
          backgroundImage: "url('/images/pool.png')",
        }}
      ></div>
    </div>
  );
}

export default App;
