import { useEffect, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { useWindowSize } from "../hooks/windowSize";
import { ProductItem } from "../components/ProductItem";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { PurchaseLessonsPanel } from "../components/modals/PurchaseLessonsPanel";
import { PurchaseHistory } from "../components/PurchaseHistory";

export function PurchaseLessons(props) {
  const [lessonsLoading, setLessonsLoading] = useState(false);
  const [purchasesLoading, setPurchasesLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState();
  const [purchaseLessonModalOpen, setPurchaseLessonModalOpen] = useState(false);

  useEffect(() => {
    fetchProducts();
    fetchPurchases();
  }, []);

  async function fetchPurchases() {
    setPurchasesLoading(true);
    try {
      const req = await fetch(`${process.env.REACT_APP_API_URL}/purchases`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      });
      const res = await req.json();
      if (res.data) {
        const orderedByDate = res.data
          .sort((a, b) => {
            return (
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
            );
          })
          .reverse();
        setPurchases(orderedByDate);
      }
    } catch (err) {
      console.debug("Something went wrong loading the products: ", err);
      setPurchasesLoading(false);
    }
    setPurchasesLoading(false);
  }

  async function fetchProducts() {
    setLessonsLoading(true);
    try {
      const req = await fetch(`${process.env.REACT_APP_API_URL}/products`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      });
      const res = await req.json();
      // Order by price
      if (res.data) {
        res.data.sort(function (a, b) {
          if (a.price < b.price) return -1;
          if (a.price > b.price) return 1;
          return 0;
        });
        setProducts(res.data);
      }
    } catch (err) {
      console.debug("Something went wrong loading the products: ", err);
      setLessonsLoading(false);
    }
    setLessonsLoading(false);
  }

  const size = useWindowSize();

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "30px 60px 0 60px",
        }}
      >
        <h1 className="page-title">Purchase Lessons</h1>
      </div>

      <div
        style={{
          height: size?.height ? size.height - 200 : "50vh",
          overflow: "auto",
          padding: "30px 60px 30px 60px",
        }}
      >
        {lessonsLoading && (
          <div style={{ textAlign: "center", margin: "50px 0" }}>
            <CircularProgress size={26} style={{ color: "#004367" }} />
          </div>
        )}
        {!lessonsLoading && products.length > 0 && (
          <Grid
            container
            style={{
              justifyContent: "start",
            }}
            columnSpacing={"30px"}
          >
            {!lessonsLoading &&
              products &&
              products.map((product) => (
                <div key={product.productId}>
                  <Grid item>
                    <ProductItem
                      product={product}
                      onPurchasePressed={() => {
                        setSelectedLesson(product);
                        setPurchaseLessonModalOpen(true);
                      }}
                    />
                  </Grid>
                </div>
              ))}
          </Grid>
        )}
        <p style={{ color: "#F0142F", marginTop: "20px" }}>
          * Please note that{" "}
          <span style={{ fontWeight: "600" }}>ALL SALES ARE FINAL.</span>{" "}
          Refunds are not an option.  Some lesson purchases may expire after a specific amount of days and will no longer usable after that date.
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "50px",
            marginBottom: "20px",
          }}
        >
          <h1 className="page-subtitle">Purchase History</h1>
        </div>
        <PurchaseHistory purchases={purchases} loading={purchasesLoading} />
      </div>
      <SlidingPane
        closeIcon={<div>Some div containing custom close icon.</div>}
        isOpen={purchaseLessonModalOpen}
        from="right"
        width="400px"
        onRequestClose={() => setPurchaseLessonModalOpen(false)}
      >
        <PurchaseLessonsPanel
          onRequestClose={() => setPurchaseLessonModalOpen(false)}
          product={selectedLesson}
          onPurchaseSuccess={() => {
            fetchPurchases();
          }}
        />
      </SlidingPane>
    </div>
  );
}
