import React from "react";
import { Router, Route, Redirect } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import { createBrowserHistory } from "history";
import { SnackbarProvider } from "notistack";
import AppContainer from "./pages/AppContainer";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

const App = () => {
  const history = createBrowserHistory();

  return (
    <SnackbarProvider maxSnack={2} autoHideDuration={2500}>
      <Router history={history}>
        <div>
          <Route exact path="/">
            <Redirect
              to={{
                pathname: "/dashboard",
              }}
            />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/register">
            <Register />
          </Route>
          <Route exact path="/forgot-password">
            <ForgotPassword />
          </Route>
          <Route path="/reset-password/:userId/:token">
            <ResetPassword />
          </Route>
          <Route exact path="/dashboard">
            <AppContainer />
          </Route>
        </div>
      </Router>
    </SnackbarProvider>
  );
};

export default App;
