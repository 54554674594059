import { format } from "date-fns";
import { useContext, useState } from "react";
import { BsPersonCircle, BsThreeDots } from "react-icons/bs";
import { Dropdown } from "semantic-ui-react";
import { AppContext } from "../pages/AppContainer";
import { studentInformationComplete } from "../utils";
import { AdminViewStudent } from "./modals/AdminViewStudentModal";
import { CreateStudentModal } from "./modals/CreateStudentModal";
import { Tag } from "./Tag";

export function StudentItem(props) {
  const { currentUser } = useContext(AppContext);
  const student = props.student;
  const [editModalIsOpen, setEditModalOpen] = useState(false);
  const [viewStudentModelIsOpen, setViewStudentModalOpen] = useState(false);

  return (
    <>
      <div
        style={{
          background: "white",
          padding: "15px 25px",
          boxShadow: "0px 8px 20px #00000014",
          borderRadius: "10px",
          width: "35vw",
          minWidth: "300px",
          margin: "10px 0",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <button onClick={() => setViewStudentModalOpen(true)}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              {!student?.profilePhoto ? (
                <BsPersonCircle size={70} style={{ color: "#DEDEDE" }} />
              ) : (
                <img
                  src={student.profilePhoto}
                  style={{
                    width: "70px",
                    height: "70px",
                    objectFit: "cover",
                    borderRadius: "100px",
                  }}
                  alt=""
                />
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "start",
                  marginLeft: "15px",
                }}
              >
                <p
                  style={{
                    padding: "0",
                    margin: "0 0 2px 0",
                    color: "#171725",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >{`${student?.firstName ?? ""} ${student?.lastName ?? ""}`}</p>
                {student.createdAt && (
                  <p
                    style={{
                      padding: "0",
                      margin: "0",
                      color: "#454545",
                      fontSize: "12px",
                    }}
                  >
                    Student since{" "}
                    {`${format(new Date(student.createdAt), "MMM dd, yyyy")}`}
                  </p>
                )}
              </div>
            </div>
          </button>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <button
              onClick={() =>
                currentUser.role > 1 ? setViewStudentModalOpen(true) : null
              }
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  marginRight: "20px",
                }}
              >
                {!student.waiverSignedBy && (
                  <Tag
                    label="Missing Documents"
                    marginBottom={
                      !studentInformationComplete(student) ? "5px" : "0px"
                    }
                  />
                )}
                {!studentInformationComplete(student) && (
                  <Tag label="Missing Information" />
                )}
              </div>
            </button>
            {currentUser.role > 1 && (
              <Dropdown
                icon={<BsThreeDots size={20} style={{ color: "#707070" }} />}
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    text="View"
                    value={0}
                    onClick={() => {
                      setViewStudentModalOpen(true);
                    }}
                  />
                  <Dropdown.Item
                    text="Edit"
                    value={0}
                    onClick={() => {
                      setEditModalOpen(true);
                    }}
                  />
                  {/* <Dropdown.Item
                  text="Payment History"
                  value={0}
                  onClick={(val) => {}}
                /> */}
                  {/* <Dropdown.Item
                  text="Delete Student"
                  style={{ color: "red" }}
                  value={0}
                  onClick={(val) => {}}
                /> */}
                </Dropdown.Menu>
              </Dropdown>
            )}
            {/* <button
              onClick={() => setEditModalOpen(true)}
              style={{ lineHeight: "0" }}
            >
              <BsThreeDots size={20} style={{ color: "#707070" }} />
            </button> */}
          </div>
        </div>
      </div>
      <CreateStudentModal
        isOpen={editModalIsOpen}
        student={student}
        isEditing={true}
        onRequestClose={() => {
          setEditModalOpen(false);
        }}
        onStudentUpdated={props.onStudentUpdated}
      />
      <AdminViewStudent
        isOpen={viewStudentModelIsOpen}
        student={student}
        onRequestClose={() => {
          setViewStudentModalOpen(false);
        }}
        onEditPressed={() => {
          setViewStudentModalOpen(false);
          setEditModalOpen(true);
        }}
        onStudentUpdated={props.onStudentUpdated}
        isInstructor={currentUser.role === 1}
      />
    </>
  );
}
